import { environment } from '../../../environments/environment';
import Constants from './constants';


export default class Utils {
  static is2ArraysEqual(a1, a2) {
    if (!a1 || !a2) return false;
    if (a1.length !== a2.length) return false;
    for (let i = 0, l = a1.length; i < l; i++) {
      if (a1[i] instanceof Array && a2[i] instanceof Array) {
        if (!a1[i].equals(a2[i])) return false;
      } else if (a1[i] !== a2[i]) return false;
    }
    return true;
  }

  static arrToLuObj(arr) {
    const luObj = {};
    for (const each of arr) luObj[each._id] = each;
    return luObj;
  }

  static luObjToArr(luObj) {
    const arr = [];
    for (const key in luObj) arr.push(luObj[key]);
    return arr;
  }

  static generateUUIDv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static createRandomId() {
    const timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
      return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
  }

  static arrToParamsStr(arr) {
    let str = '';
    for (let i = 0; i < arr.length; i++) {
      const system = arr[i];
      str += system.system_id;
      if (i < arr.length - 1) str += '|';
    }
    return str;
  }
  static doesFileExist(urlToFile) {
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlToFile, false);
    xhr.send();
    if (xhr.status === 200) return true;
    return false;
  }
  static updateObjs(targetObject, obj) {
    Object.keys(obj).forEach(function (key) {
      // delete property if set to undefined or null
      if (undefined === obj[key] || null === obj[key]) {
        delete targetObject[key];
      }
      // property value is object, so recurse
      else if (
        'object' === typeof obj[key]
        && !Array.isArray(obj[key])
      ) {
        // target property not object, overwrite with empty object
        if (
          !('object' === typeof targetObject[key]
            && !Array.isArray(targetObject[key]))
        ) {
          targetObject[key] = {};
        }
        // recurse
        this.updateObjs(targetObject[key], obj[key]); // TODO: to check if works
      }
      // set target property to update property
      else {
        targetObject[key] = obj[key];
      }
    });
  }
  static dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  static handleApiError(error, viewComps) {
    if (error) {
      console.warn('handleApiError error:', error);
      console.warn('handleApiError graphQLErrors:', error.graphQLErrors);
      console.warn('handleApiError networkError:', error.networkError);
    }
    if (error && error.networkError &&
      error.networkError.error &&
      error.networkError.error.errors &&
      error.networkError.error.errors[0] &&
      error.networkError.error.errors[0].extensions &&
      error.networkError.error.errors[0].extensions.code &&
      viewComps.router) {
      if (error.networkError.error.errors[0].extensions.code === 'UNAUTHENTICATED') {
      }
    } else if (viewComps.snackBar && viewComps.translateService) {
      viewComps.snackBar.open(viewComps.translateService.instant('FAILURE_OCCURED'),
        viewComps.translateService.instant('CLOSE'), { panelClass: ['error-snackbar'], duration: 5000 });
    }
  }
  static params2GqlStr(params) {
    const jsonParams = JSON.stringify(params).replace(/\"([^(\")"]+)\":/g, '$1:').slice(0, -1).substr(1);
    return jsonParams;
  }
  static params2JsonStr(params) {
    const jsonStr = JSON.stringify({ tmp: JSON.stringify(params) }).substring(8).slice(0, -2);
    return jsonStr;
  }
  static getIconStrForFile(file) {
    if (file.isDir) return 'folder_open';
    if (file.type === 1) return 'picture_as_pdf';
    if (file.type === 2) return 'video_library';
    if (file.type === 3) return 'photo_library';
    if (file.type === 4) return 'attach_file';
    if (file.type === 5) return 'attach_file';
    if (file.isGoToParentDir) return 'arrow_upward';
    return 'note';
  }

  static getIconStrForFilev2(file) {
    if (file.isDir) return 'assets/icons/folder.svg';
    if (file.type === 1) return 'assets/images/image.png';
    if (file.type === 2) return 'assets/images/video_library.png';
    if (file.type === 3) return 'assets/images/photo_library.png';
    if (file.type === 4) return 'assets/images/attach_file.png';
    if (file.type === 5) return 'assets/images/attach_file.png';
    if (file.isGoToParentDir) return 'assets/images/up.png';
    return 'assets/images/none.png';
  }

  static getFormatFile(file) {
    if (file.isDir) return 'folder';
    if (file.type === 1) return 'image';
    if (file.type === 2) return 'video';
    if (file.type === 3) return 'photo';
    return 'file';
  }


  static formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return (parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]).replace('.', ',');
  }
  static filenameFromPath(path) {
    if (!path) return '';
    return path.replace(/^.*[\\\/]/, '');
  }
  static basenameFromPath(path) {
    if (!path) return '';
    return path.replace(this.filenameFromPath(path), '');
  }
  static baseName(str) {
    let base = new String(str).substring(str.lastIndexOf('/') + 1);
    if (base.lastIndexOf('.') !== -1) base = base.substring(0, base.lastIndexOf('.'));
    return base;
  }
  static isPresentationFile(file) {
    if (file.mimetype === 'application/pdf') return true;
    return false;
  }
  static isVideoFile(file) {
    if (file.mimetype === 'video/mp4') return true;
    return false;
  }
  static getCachePath(file) {
    return '/fs/files/' + this.basenameFromPath(file.path) + '.qmcache';
  }
  static getPreviewImgUri(file, cache = true) {
    let uri = '';
    // todo: check if cached
    if (cache) uri += encodeURI(this.getCachePath(file) + '/' + this.filenameFromPath(file.path) + '.jpg');
    else uri += 'fs/files/' + this.basenameFromPath(file.path) + '/' + this.filenameFromPath(file.path);
    return  uri;
  }

  static getHost = () => {
    return `${window.location.protocol}//${window.location.host}`;
  }

  static getPreviewImgUri2(file) {
    return environment.mediaUrl + file.path;
  }

  static typeCanHavePreviewImg(file) {
    let canHave = false;
    ['application/pdf', 'video/mp4', 'image/jpeg', 'image/png'].map((type) => {
      if (file.mimetype === type) {
        canHave = true;
      }
    });
    return canHave;
  }
  static obj2JsonStrEscaped(obj) {
    return JSON.stringify({ tmp: JSON.stringify(obj) }).substring(8).slice(0, -2);
  }
  static createPortalUrlForItem(item, isPreview = false) {

    const url = Constants.PORTAL_URL + 'page/' + (isPreview ? 'preview/' : '') + item.slug + (isPreview ? '?noPrivacyPopup=1' : '');
    return url;
  }
  static getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }


  static createPortalGroup(group) {
    const url = group.isDefault ? Constants.GROUP_URL.replace('{slug}.', '') : Constants.GROUP_URL.replace('{slug}', group.slug);
    return url;
  }

  static convertStringToSnakeCase(str: string) {
    // input : 'this_IS not.snake-Case' => output: 'THIS_IS_NOT_SNAKE_CASE'
    const regex = /[ \x2D._]/g;
    if (regex.test(str)) {
      str = str.replace(regex, '_');
    }
    return (str + '').toUpperCase();
  }

  static convertSlug(str: string): string {
    str = str.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();

    const from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;';
    const to = 'AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------';
    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    str = str.replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-');
    return str;
  }

  static formatEUCurrency = (num: number, fractionDigit: number): String => {
    return (
      new Intl.NumberFormat('de-DE', {
        maximumFractionDigits: fractionDigit,
        minimumFractionDigits: fractionDigit,
      }).format(num) + '€'
    );
  };

  static formatUSCurrency = (num: number, fractionDigit: number): String => {
    return (
      new Intl.NumberFormat('en-US', {
        maximumFractionDigits: fractionDigit,
        minimumFractionDigits: fractionDigit,
      }).format(num) + '€'
    );
  };

}
