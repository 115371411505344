import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

import { AppComfirmComponent } from './app-confirm.component';

interface confirmData {
  title?: string;
  message?: string;
  btnOk?: string;
  btnCancel?: string;
  noCancelBtn?: boolean;
}

@Injectable()
export class AppConfirmService {
  constructor(private dialog: MatDialog) {}

  public confirm(data: confirmData = {}): Observable<boolean> {
    data.title = data.title || 'Confirm';
    data.message = data.message || 'Are you sure?';
    data.btnOk = data.btnOk || 'OK';
    data.btnCancel = data.btnCancel || 'Cancel';
    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open(AppComfirmComponent, {
      width: '380px',
      disableClose: true,
      data: { title: data.title, message: data.message, btnOk: data.btnOk, btnCancel: data.btnCancel, noCancelBtn: data.noCancelBtn }
    });
    return dialogRef.afterClosed();
  }
}
