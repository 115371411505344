import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from './../../../../../shared/services/MessageService';
import { LeadService } from '../../../lead.service';
import { ParentComponent } from 'app/parent.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LayoutService } from 'app/shared/services/layout.service';
const _ = require('lodash');

@Component({
  selector: 'app-vcard-scan',
  templateUrl: 'vcard-scan.component.html',
  styleUrls: ['./vcard-scan.component.scss']
})
export class VcardScanDialogComponent extends ParentComponent implements OnInit {
  lead: any = null;
  tab = 0;
  constructor(
    public breakpointObserver: BreakpointObserver,
    public layoutService: LayoutService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VcardScanDialogComponent>,
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    private router: Router,
    public messageService: MessageService,
    public route: ActivatedRoute,
    public leadService: LeadService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(breakpointObserver, layoutService, dialog);
    this.lead = data;
  }


  ngOnInit() {
  }

  onClickCancel() {
    this.dialogRef.close({ ok: null });
    localStorage.removeItem('valueScaned');
  }

  onSave = (result) => {
    this.dialogRef.close({
      ok: true, result: {
        ...this.lead,
        ...result,
      }
    });
  }
}
