import { ColumnDirective, NoDataDirective, ThumbnailDirective } from './../list/list.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontSizeDirective } from './font-size.directive';
import { ScrollToDirective } from './scroll-to.directive';
import { AppDropdownDirective } from './dropdown.directive';
import { DropdownAnchorDirective } from './dropdown-anchor.directive';
import { DropdownLinkDirective } from './dropdown-link.directive';
import { MdaSideNavToggleDirective } from './mda-side-nav-toggle.directive';
import { MdaSidenavHelperDirective, MdaSidenavTogglerDirective } from './mda-sidenav-helper/mda-sidenav-helper.directive';
import { MdaHighlightDirective } from './mda-highlight.directive';
import { TrimInputDirective } from './trim-input.directive';


const directives = [
  FontSizeDirective,
  ScrollToDirective,
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  MdaSideNavToggleDirective,
  MdaSidenavHelperDirective,
  MdaSidenavTogglerDirective,
  MdaHighlightDirective,
  ColumnDirective,
  ThumbnailDirective,
  NoDataDirective,
  TrimInputDirective
]

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: directives,
  exports: directives
})
export class SharedDirectivesModule {}