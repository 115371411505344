import { BreakpointObserver } from '@angular/cdk/layout';
import { ParentComponent } from './../../../../../parent.component';
import { LayoutService } from 'app/shared/services/layout.service';
import { EventEmitter, Output } from '@angular/core';
import { SelectProductDialogComponent } from './dialog/select-product-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../../../../shared/services/data.service';
import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
const _ = require('lodash');

@Component({
  selector: 'app-select-product',
  templateUrl: './select-product.component.html',
  styleUrls: ['./select-product.component.scss'],
})
export class SelecProductComponent extends ParentComponent implements OnInit {
  isLda: boolean = environment.groupType === 'lda' ? true : false
  isPtm: boolean = environment.groupType === 'ptm' ? true : false
  @Input('type') type: string = 'lead';
  @Input('consultations') consultations: any = {};
  @Input('setting') setting: any = {};
  @Input('detail') detail: any = {};
  

  @Input('id') idGroup: any = {};
  @Input('status') status: String = '';
  @Input('required') required: boolean = false;
  @Output() onDataChange = new EventEmitter();
  loading: boolean = false;
  constructor(
    public breakpointObserver: BreakpointObserver,
    public layoutService: LayoutService,
    public dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private translateService: TranslateService,
  ) {
    super(breakpointObserver, layoutService, dialog);
  }

  ngOnInit() {
    console.log('setting', this.setting);

    this.initPage();
  }

  initPage() { }

  onBtnDeleteLead() { }

  checkStatus() {
    if (this.type === 'lead') {
      if (this.status === 'freigegeben' || this.status === 'export') {
        return false;
      }
    } else {
      if (this.status === 'export') {
        return false;
      }
    }
    return true
  }
  onBtnShowSelectProduct() {
    if (!this.consultations[0].startTime) {
      this.consultations[0].startTime = new Date();
    }
    this.showModal(SelectProductDialogComponent, {
      type: 'add',
      idGroup: this.idGroup,
      consultationLead: {},
      setting: this.setting,
      detail: this.detail,
      consultationLeads: this.consultations[0].consultationLeads,
    }, { width: '600px' }).subscribe((result) => {
      if (result) {
        if (result.type === 'add') {
          if (result.data.budget) {
            result.data.budget = parseFloat(result.data.budget);
          }
          this.consultations[0].consultationLeads.push(result.data);
          console.log('consultations', this.consultations);
          this.onDataChange.emit(this.consultations);
        }
        this.consultations[0].endTime = new Date().toISOString();
         if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
      }
    })
  }

  onBtnEditSelectProduct(index, consultationLead) {
    this.showModal(SelectProductDialogComponent, {
      type: 'edit',
      idGroup: this.idGroup,
      indexProduct: index,
      consultationLead: consultationLead,
    }, { width: '600px' }).subscribe((result) => {
      if (result != null) {
        if (result.type === 'delete') {
          this.consultations[0].consultationLeads.splice(index, 1);
        }
        this.consultations[0].endTime = new Date().toISOString();

        this.onDataChange.emit(this.consultations);
         if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
      }
    })
  }

  formatCurrency(price: number) {
    if (this.translateService.currentLang === 'en') {
      return this.FormatUSCurrency(price, 2);
    } else {
      return this.FormatEUCurrency(price, 2);
    }
  }

  FormatEUCurrency = (num: number, fractionDigit: number): String => {
    return (
      new Intl.NumberFormat('de-DE', {
        maximumFractionDigits: fractionDigit,
        minimumFractionDigits: fractionDigit,
      }).format(num) + '€'
    );
  };

  FormatUSCurrency = (num: number, fractionDigit: number): String => {
    return (
      new Intl.NumberFormat('en-US', {
        maximumFractionDigits: fractionDigit,
        minimumFractionDigits: fractionDigit,
      }).format(num) + '€'
    );
  };
}
