import { MessageService } from './../../services/MessageService';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { RepresentativeComponent } from '../representative/representative.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LayoutService } from 'app/shared/services/layout.service';
import { MatDialog } from '@angular/material';
import { ParentComponent } from 'app/parent.component';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-representative-control',
  templateUrl: 'representative.component.html',
  styleUrls: ['./representative.component.scss'],
})
export class RepresentativeControlComponent extends ParentComponent implements OnInit {
  @Input('list') list: any = [];//
  @Input('idblock') idblock: string = '';//
  @Input('disabled') disabled: boolean = false;
  @Input('moreparam') moreparam: any = {};//

  
  @Output() onChange = new EventEmitter();
  link: string = environment.mediaUrl + 'fs/files/';

  constructor(
    public breakpointObserver: BreakpointObserver,
    public layoutService: LayoutService,
    public dialog: MatDialog,
    public translateService: TranslateService,
    public snackBar: MatSnackBar,
    public messageService: MessageService,
    private cdr: ChangeDetectorRef,
  ) {
    super(breakpointObserver, layoutService, dialog);
  }

  ngOnInit() {
   
  }
  //FORM: bấm nút Add Representative
  onAddRepresentative() {
    console.log('this.moreparam', this.moreparam);
    
    this.showModal(RepresentativeComponent, { moreparam:this.moreparam, admins: this.list}, null).subscribe((res) => {
      if (res) {
        this.list = res;
        this.onChange.emit(res);
         if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
      }
    })
  }
  
  //Xóa item trên danh sách Representative
  removeUser(userId) {
    console.log('removeUser',userId);
    
    this.list = this.list.filter(
      (item) => item.id !== userId,
    );
    this.onChange.emit(this.list);
     if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
  }
}
