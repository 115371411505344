import { Injectable, Inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';
import { DataService } from '../../shared/services/data.service';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  constructor(private apollo: Apollo, private dataService: DataService) { }

  adConfigs() {
    return this.apollo.mutate({
      mutation: gql`
        query {
          adConfigs  {
            id
            key
            value
            meta
          }
        }
      `,
      variables: {},
    });
  }
  adConfigsUpdate(params) {
    let jsonParams = JSON.stringify(params).replace(/\"([^(\")"]+)\":/g, '$1:');
    return this.apollo.mutate({
      mutation: gql`
        mutation {
          adConfigUpdate(configs: ${jsonParams})
        }
      `
    });
  }



  adRoles() {
    return this.apollo.mutate({
      mutation: gql`
        query {
          adRoles {
            id
            titleDE
            titleEN
            descriptionDE
            descriptionEN
            isStandard
            isPartner
            perms {
              id
              titleDE
              titleEN
              descriptionDE
              descriptionEN
            }
          }
        }
      `,
      variables: {},
    });
  }

  adPerms() {
    return this.apollo.mutate({
      mutation: gql`
        query {
          adPerms {
            id
            catTitle
            titleDE
            titleEN
            descriptionDE
            descriptionEN
          }
        }
      `,
      variables: {},
    });
  }

  

  adRoleCreate(params) {
    return this.apollo.mutate({
      mutation: gql`
        mutation adRoleCreate(
          $titleDE: String!
          $titleEN: String!
          $descriptionDE: String!
          $descriptionEN: String!
          $permIds: [Int!]
          $isStandard: Boolean!
          $isPartner: Boolean!
        ) {
          adRoleCreate(
            titleDE: $titleDE
            titleEN: $titleEN
            descriptionDE: $descriptionDE
            descriptionEN: $descriptionEN
            permIds: $permIds
            isStandard: $isStandard
            isPartner: $isPartner
          ) {
            id
          }
        }
      `,
      variables: params,
    });
  }

  adRoleUpdate(params) {
    return this.apollo.mutate({
      mutation: gql`
        mutation adRoleUpdate(
          $id: Int!
          $titleDE: String
          $titleEN: String
          $descriptionDE: String
          $descriptionEN: String
          $permIds: [Int]
          $isStandard: Boolean
          $isPartner: Boolean
        ) {
          adRoleUpdate(
            id: $id
            titleDE: $titleDE
            titleEN: $titleEN
            descriptionDE: $descriptionDE
            descriptionEN: $descriptionEN
            permIds: $permIds
            isStandard: $isStandard
            isPartner: $isPartner
          ) {
            id
          }
        }
      `,
      variables: params,
    });
  }

  adRoleDelete(params) {
    return this.apollo.mutate({
      mutation: gql`
        mutation adRoleDelete($id: Int!) {
          adRoleDelete(id: $id)
        }
      `,
      variables: params,
    });
  }

  apiGetProductCats(params) {
    return this.apollo.query({
      query: gql`
        query {
          productCats(input: {
            page: ${params.page},
            pageSize: ${params.pageSize},
            orderBy: "${params.orderBy}",
            orderDir: "${params.orderDir}",
            company: "${params.company}",
            searchText: "${params.searchText}",
            filter: "${params.filter}"
          }) {
            entities {
              id
              title
              externalId
              updatedAt
              products {
                id
                title
                externalId
              }
            }
            total
          }
        }
      `,
      variables: {},
      fetchPolicy: 'no-cache',
    });
  }
  apiProductCatsCreate(params) {
    let jsonParams = JSON.stringify(params).replace(/\"([^(\")"]+)\":/g, '$1:');
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        productCatCreate(input: ${jsonParams}) {
          id
        }
      }
    `,
    });
  }
  apiProductCatsUpdate(params) {
    let jsonParams = JSON.stringify(params).replace(/\"([^(\")"]+)\":/g, '$1:');
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        productCatUpdate(input: ${jsonParams}) {
          data
        }
      }
    `,
    });
  }
  apiProductCatsDelete(params) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        productCatDelete(ids: ${JSON.stringify(params.ids)}) {
          data
        }
      }
    `,
    });
  }
  apiProductsCreate(params) {
    let jsonParams = JSON.stringify(params).replace(/\"([^(\")"]+)\":/g, '$1:');
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        productCreate(input: ${jsonParams}) {
          id
        }
      }
    `,
    });
  }
  apiProductsUpdate(params) {
    let jsonParams = JSON.stringify(params).replace(/\"([^(\")"]+)\":/g, '$1:');
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        productUpdate(input: ${jsonParams}) {
          data
        }
      }
    `,
    });
  }
  apiProductsDelete(params) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        productDelete(ids: ${JSON.stringify(params.ids)}) {
          data
        }
      }
    `,
    });
  }

  apiGetAttributes(params) {
    return this.apollo.query({
      query: gql`
        query {
          attributes(input: {
            page: ${params.page},
            pageSize: ${params.pageSize},
            orderBy: "${params.orderBy}",
            orderDir: "${params.orderDir}",
            searchText: "${params.searchText}",
            filter: "${params.filter}"
          }) {
            entities {
              id
              title
              exportTitle
              updatedAt
              tags {
                id
                title
              }
            }
            total
          }
        }
      `,
      variables: {},
      fetchPolicy: 'no-cache',
    });
  }
  apiAttributesCreate(params) {
    let jsonParams = JSON.stringify(params).replace(/\"([^(\")"]+)\":/g, '$1:');
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        attributeCreate(input: ${jsonParams}) {
          id
        }
      }
    `,
    });
  }
  apiAttributesUpdate(params) {
    let jsonParams = JSON.stringify(params).replace(/\"([^(\")"]+)\":/g, '$1:');
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        attributeUpdate(input: ${jsonParams}) {
          data
        }
      }
    `,
    });
  }
  apiAttributesDelete(params) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        attributeDelete(ids: ${JSON.stringify(params.ids)}) {
          data
        }
      }
    `,
    });
  }
  apiTagsCreate(params) {
    let jsonParams = JSON.stringify(params).replace(/\"([^(\")"]+)\":/g, '$1:');
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        tagCreate(input: ${jsonParams}) {
          id
        }
      }
    `,
    });
  }
  apiTagsUpdate(params) {
    let jsonParams = JSON.stringify(params).replace(/\"([^(\")"]+)\":/g, '$1:');
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        tagUpdate(input: ${jsonParams}) {
          data
        }
      }
    `,
    });
  }
  apiTagsDelete(params) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        tagDelete(ids: ${JSON.stringify(params.ids)}) {
          data
        }
      }
    `,
    });
  }
}
