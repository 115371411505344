import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Inject, Output, EventEmitter} from '@angular/core';
import Utils from 'app/shared/utils/utils';

@Component({
  selector: 'app-popup-next-tab',
  templateUrl: './popup-next-tab.component.html',
  styleUrls: ['./popup-next-tab.component.scss']
})
export class PopupNextTab implements OnInit {
  @Output() tabChange = new EventEmitter<number>();
  Utils = Utils;
  constructor(
    public dialogRef: MatDialogRef<PopupNextTab>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }
  ngOnInit() {
    
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  onClickDiscard() {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.dialogRef.close(true);
  }
}
