import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MessageService } from 'app/shared/services/MessageService';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilesService } from '../files.service';
import { CustomValidators } from 'ng2-validation';
import { DataService } from 'app/shared/services/data.service';
import { ValidationService } from 'app/shared/services/validation.service';
import Constants from 'app/shared/utils/constants';
import Utils from 'app/shared/utils/utils';
const _ = require('lodash');

@Component({
  selector: 'app-collection-add-dialog',
  templateUrl: 'collection-add-dialog.component.html',
  styleUrls: ['./collection-add-dialog.component.scss'],
})
export class CollectionAddDialogComponent implements OnInit {
  maxLengthInput: number;
  myForm: FormGroup = null;
  collection: any = {
    title: '',
  };
  parent: any = null;
  loading: any = false;
  Utils = Utils;
  constructor(
    public dialogRef: MatDialogRef<CollectionAddDialogComponent>,
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    public dataService: DataService,
    public filesService: FilesService,
    public messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.parent = data.parent;
    this.collection = data.detail ? data.detail : { title: '' };
  }
  get title() {
    return this.myForm.get('title');
  }

  ngOnInit() {
    const collectionTitle = this.collection.title;
    this.myForm = new FormGroup({
      title: new FormControl(
        collectionTitle.lastIndexOf('.') === -1 ? collectionTitle : collectionTitle.slice(0, collectionTitle.lastIndexOf('.')),
        [
          Validators.required,
          ValidationService.folderValidator,
          this.dataService.removeSpaces,
          CustomValidators.notEqual('_PORTAL_FE_'),
        ],
      ),
    });
    this.maxLengthInput = Constants.MAX_LENGTH_INPUT;
  }

  getErr(err: any) {
    if (err.required) { return 'PLEASE_INPUT_TITLE'; }
    if (err.notEqual) { return 'TITLE__PORTAL_FE__NOT ALLOW'; }
    if (err.invalidFolder) { return 'INVALID_FOLDER_NAME'; }
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  ngAfterViewInit() { }
  
  onChange(e) {
    console.log('onChange',e)
  }

  onSubmit() {
    this.loading = true;
    if (!this.parent) {
      if (!this.collection.id) {
        // Thêm Collection
        const { title } = this.myForm.value;
        this.filesService.apiFileColCreate({ title: title.trim() }).subscribe(
          ({ data }) => {
            const apiRes: any = data;
            console.log('apiFileColCreate', apiRes);
            if (apiRes.fileColCreate.id) {
              this.messageService.showSuccess('COLLECTION_CREATED', 'I_FILE_002');
              this.dialogRef.close(true);
              this.getError(false);
            } else {
              this.getError();
            }
          },
          () => this.getError(),
        );
      } else {
        // Sửa Collection
        const { id } = this.collection;
        const { title } = this.myForm.value;
        this.filesService.fileColUpdate({ id, title: title.trim() }).subscribe(
          ({ data }) => {
            const apiRes: any = data;
            if (apiRes.fileColUpdate.data) {
              this.messageService.showSuccess('COLLECTION_UPDATED', 'I_FILE_003');
              this.dialogRef.close(true);
              this.getError(false);
            } else {
              this.getError();
            }
          },
          () => this.getError(),
        );
      }
    } else {
      if (!this.collection.id) {
        // Thêm Folder
        this.filesService
          .apiFileCreate( { ...this.myForm.value, isDir: true, parentId: this.parent.id }).subscribe(
            ({ data }) => {
              const apiRes: any = data;
              if (apiRes.fileCreate.id) {
                this.messageService.showSuccess('FOLDER_CREATED', 'I_FILE_004');
                this.dialogRef.close(true);
                this.getError(false);
              } else { this.getError(); }
            },
            () => this.getError(),
          );
      } else {
        // Sửa Folder
        const { id, isDir } = this.collection;
        const { title } = this.myForm.value;
        const newTitle = title + '.' +  this.collection.title.split('.').pop();
        this.filesService.fileRename({ fileId: id, title: newTitle }).subscribe(
          ({ data }) => {
            const apiRes: any = data;
            if (apiRes.fileRename.data) {
              this.messageService.showSuccess(
                isDir ? 'FOLDER_UPDATED' : 'File updated',
                isDir ? 'I_FILE_005' : 'I_FILE_006',
              );
              this.dialogRef.close(true);
              this.getError(false);
            } else {
              this.getError();
            }
          },
          () => this.getError(),
        );
      }
    }
  }

  getError(type: boolean = true) {
    this.loading = false;
     if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
    if (type) { this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002'); }
  }
}
