import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-reset-template',
  templateUrl: 'reset-template.component.html',
  styleUrls: ['./reset-template.component.scss']
})
export class ResetTemplateDialogComponent implements OnInit {
 
  constructor(
    public dialogRef: MatDialogRef<ResetTemplateDialogComponent>,
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    private translateService: TranslateService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    
  }
  ngOnInit() {

   
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  onSubmit()
  {
    this.dialogRef.close(true);
  }
}
