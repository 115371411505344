import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy
  } from '@angular/core';
  import {MatCalendar} from '@angular/material/datepicker';
  import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
  import {Subject} from 'rxjs';
  import {takeUntil} from 'rxjs/operators';
  
  /** @title Datepicker with custom calendar header */
//   @Component({
//     selector: 'datepicker-custom-header-example',
//     templateUrl: 'datepicker-custom-header-example.html',
//     changeDetection: ChangeDetectionStrategy.OnPush,
//   })
//   export class DatepickerCustomHeader {
//     headerCalender = HeaderCalender;
//   }
  
  /** Custom header component for datepicker. */
  @Component({
    selector: 'header-calendar',
    styles: [`
      .header-calendar {
        display: flex;
        align-items: center;
        padding: 0.5em;
      }
  
      .header-calendar-label {
        flex: 1;
        height: 1em;
        font-weight: 500;
        text-align: center;
      }
  
      .example-double-arrow .mat-icon {
        margin: -22%;
      }
    `],
    template: `
      <div class="header-calendar">
        <button mat-icon-button class="example-double-arrow" (click)="previousClicked('year')">
          <mat-icon>keyboard_arrow_left</mat-icon>
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button mat-icon-button (click)="previousClicked('month')">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <span class="header-calendar-label">{{periodLabel}}</span>
        <button mat-icon-button (click)="nextClicked('month')">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <button mat-icon-button class="example-double-arrow" (click)="nextClicked('year')">
          <mat-icon>keyboard_arrow_right</mat-icon>
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
  })
  export class HeaderCalender<D> implements OnDestroy {
    private _destroyed = new Subject<void>();
  
    constructor(
        private _calendar: MatCalendar<D>, private _dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
      _calendar.stateChanges
          .pipe(takeUntil(this._destroyed))
          .subscribe(() => cdr.markForCheck());
    }
  
    ngOnDestroy() {
      this._destroyed.next();
      this._destroyed.complete();
    }
  
    get periodLabel() {
      return this._dateAdapter
          .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
          .toLocaleUpperCase();
    }
  
    previousClicked(mode: 'month' | 'year') {
      this._calendar.activeDate = mode === 'month' ?
          this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1) :
          this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
    }
  
    nextClicked(mode: 'month' | 'year') {
      this._calendar.activeDate = mode === 'month' ?
          this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1) :
          this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
    }
  }
  