import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
const _ = require('lodash')

@Component({
  selector: 'app-customer-number',
  templateUrl: 'customer-number.component.html',
  styleUrls: ['./customer-number.component.scss']
})
export class CustomerNumberDialogComponent implements OnInit {
  form: FormGroup = null;
  // number: any = { title: ''}
  loading: any = false
  disabled: boolean = true
  constructor(
    public dialogRef: MatDialogRef<CustomerNumberDialogComponent>,
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.form = new FormGroup({
      crmTdgCustomerId: new FormControl(data ? data.crmTdgCustomerId : "", Validators.required),
    });
  }
  ngOnInit() {
  }

  onClickCancel() {
    this.dialogRef.close();
  }


  ngAfterViewInit() {

  }


  onSubmit() {
    this.dialogRef.close(this.form.value);
  }

}
