import { environment } from 'environments/environment';
import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../../services/data.service';
@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
})
export class SubHeaderComponent implements OnInit {
  @Input() notificPanel;
  public availableLangs = {
    en: 'English',
    de: 'Deutsch'
  }
  labelLang = this.availableLangs.en;
  codeLang = environment.language

  public mdaThemes;
  public layoutConf: any;
  constructor(
    private themeService: ThemeService,
    public layout: LayoutService,
    public translate: TranslateService,
    public dataService: DataService,
  ) { }

  ngOnInit() {
    this.mdaThemes = this.themeService.mdaThemes;
    this.layoutConf = this.layout.layoutConf;
    setTimeout(() => {
      //this.translate.use(this.codeLang);
    }, 500)
  }

  setLang() {

    if (this.codeLang === 'en') {
      this.codeLang = 'de'
    } else {
      this.codeLang = 'en'
    }
    this.labelLang = this.availableLangs[this.codeLang];
    this.translate.use(this.codeLang);
  }
}
