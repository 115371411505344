import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { RoutePartsService } from './shared/services/route-parts.service';
import { ThemeService } from './shared/services/theme.service';
import { NavigationService } from './shared/services/navigation.service';
import jwt_decode from 'jwt-decode';
import { filter } from 'rxjs/operators';
import { LayoutService } from './shared/services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from './shared/services/data.service';
import { TooltipComponent } from '@angular/material';
import { AuthService } from './shared/services/auth.service';
import { CookieService } from 'ng2-cookies';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'TCAdmin';
  pageTitle = '';

  constructor(
    public title: Title,
    private router: Router,
    public cookieService: CookieService,
    public authService: AuthService,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private themeService: ThemeService,
    private navigationService: NavigationService,
    private layout: LayoutService,
    private renderer: Renderer2,
    private translateService: TranslateService,
    private dataService: DataService
  ) { }

  ngOnInit() {
    this.getMySelf();
    this.changePageTitle();
    this.navigationService.setupForUserRole({
      user: this.dataService.sData.user,
      role: this.dataService.sData.role,
      company: this.dataService.sData.company
    });
    Object.defineProperty(TooltipComponent.prototype, 'message', {
      set(v: any) {
        const el = document.querySelectorAll('.mat-tooltip');

        if (el) {
          el[el.length - 1].innerHTML = v;
        }
      },
    });

  }
  ngAfterViewInit() {
    this.layout.applyMatTheme(this.renderer);
  }
  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(routeChange => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }
      // Extract title from parts;
      this.pageTitle = routeParts
        .reverse()
        .map(part => part.title)
        .reduce((partA, partI) => {
          return `${partA} > ${partI}`;
        });
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }


  getMySelf() {
    try {
      const data: any = this.dataService.retrieveSData();
      const token = this.cookieService.get('token');
      if (token) {
        const decode: any = jwt_decode(token);
        if (!data || data.user.userId !== decode.internal_id) {
          this.authService.me().subscribe(({ data }: { data: any }) => {
            if (data && data.myself && data.myself.data) {
              const info = data.myself.data;
              this.dataService.setUserFromApiResData(info);
              this.dataService.setGroupAndRole({}, info.permRoles || [], '', {}, info.orgs, info.groups);
              this.dataService.storeSData();
              window.location.reload();
            } else {
              // this.getMySelf();
            }
          }, (e) => {
            // this.getMySelf();
          });
        }
      }
    } catch (e) {
      window.location.href = environment.mktUrl + 'auth/sign-in';
    }
  }
}
