import { MessageService } from './../../services/MessageService';
import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
  ElementRef,
  ComponentFactoryResolver,
  SimpleChanges,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../../services/data.service';
import { environment } from '../../../../environments/environment';
import Utils from 'app/shared/utils/utils';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html',
  styleUrls: ["./header-side.component.scss"]
})
export class HeaderSideComponent implements OnInit {
  @ViewChild('container', { read: ViewContainerRef, static: true,}) container: ViewContainerRef;

  @Input() notificPanel;
  public availableLangs = [
    {
      name: 'EN',
      code: 'en',
      flag: 'flag-icon-us',
    },
    {
      name: 'DE',
      code: 'de',
      flag: 'flag-icon-de',
    },
  ];
  currentLang = this.availableLangs[1];
  environment = environment;
  public mdaThemes;
  public layoutConf: any;
  constructor(
    private themeService: ThemeService,
    public layout: LayoutService,
    public translate: TranslateService,
    public dataService: DataService,
    public messageService: MessageService,
    public cdr: ChangeDetectorRef,
    private factory: ComponentFactoryResolver,
  ) {
    this.messageService.configObservable.subscribe((value) => {
      this.createComp(value);
    });
    this.messageService.dataObservable.subscribe((value) => {
        if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
    });
  }
  Utils = Utils;

  createComp(component: any) {
    if (component) {
      this.container.clear();
      let _compFactory = this.factory.resolveComponentFactory(component);
      this.container.createComponent(_compFactory);
    } else {
      this.container.clear();
    }
  }

  onSelect(): void {
    this.messageService.updateActive();
  }

  close() {
    this.onSelect();
    if (this.messageService.actions) this.messageService.actions.close();
  }

  closeUserDetail() {
  }

  ngOnInit() {
    this.mdaThemes = this.themeService.mdaThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang.code);
  }

  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }
  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed',
    });
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange(
        {
          sidebarStyle: 'full',
          sidebarCompactToggle: false,
        },
        { transitionClass: true },
      );
    }

    // * --> compact
    this.layout.publishLayoutChange(
      {
        sidebarStyle: 'compact',
        sidebarCompactToggle: true,
      },
      { transitionClass: true },
    );
  }

  onSearch(e) {
    //   console.log(e)
  }
}
