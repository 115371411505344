import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm',
  template: `
    <h1 matDialogTitle>{{ data.title }}</h1>
    <div mat-dialog-content>{{ data.message }}</div>
    <div mat-dialog-actions>
      <span fxFlex></span>
      <button *ngIf="!data.noCancelBtn" type="button" color="accent" mat-button (click)="dialogRef.close(false)" data-testid="btn_cancel_confirm" >
        {{ data.btnCancel }}
      </button>
      &nbsp;&nbsp;

      <button type="button" mat-button color="primary" (click)="dialogRef.close(true)" data-testid="btn_click_confirm" >
        {{ data.btnOk }}
      </button>
      <br /><br />
    </div>
  `
})
export class AppComfirmComponent {
  constructor(public dialogRef: MatDialogRef<AppComfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
}
