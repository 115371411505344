import { DataService } from 'app/shared/services/data.service';
import {
  Component,
  OnInit,
} from '@angular/core';
import { MessageService } from 'app/shared/services/MessageService';


@Component({
  selector: 'lead-headbar',
  templateUrl: './lead-headerbar.template.html',
  styleUrls: ['./lead-headbar.component.scss'],
})
export class LeadHeaderbar implements OnInit {

  constructor(public dataService: DataService, public messageService: MessageService) {


  }
  ngOnInit(): void {
  }


  myClass() {
    return 'btn  btn-light d-flex justify-content-start ms-2 ' + (this.messageService.selectActive == true ? 'button-action' : 'button-disable')
  }

  run()
  {
    if(this.messageService.actions && this.messageService.selectActive)
    {
      this.messageService.actions.save();
    }
    
  }
}
