import { getMainDefinition } from 'apollo-utilities';
import { WebSocketLink } from 'apollo-link-ws';
import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { ApolloLink, split } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
// import { AuthService } from '../../shared/services/auth.service';
import { CookieService } from 'ng2-cookies';
import { environment } from 'environments/environment';

const uri = '/graphql';


export function createApollo(httpLink: HttpLink, router: Router, cookieService: CookieService) {
  const wsURI = `${window.location.protocol.replace('http', 'ws')}//${window.location.host}` + uri;
  const http = httpLink.create({ uri: uri });
  let token = cookieService.get('token');
  const now = Date.now() / 1000;
  const authLink = new ApolloLink((operation, forward) => {
    const definition: any = operation.query.definitions[0];
    const apiname = definition.selectionSet.selections[0].name.value;
    token = cookieService.get('token');
    const refreshToken = cookieService.get('refreshToken');
    operation.setContext({
      headers: {
        Authorization: apiname !== 'refreshToken' ? `Bearer ${token}` : `Refresh ${refreshToken}`,
        'X-App': 'backoffice',
      }
    });
    return forward(operation);
  });

  const ws = new WebSocketLink({
    uri: wsURI,
    options: {
      reconnect: true,
      connectionParams: { authorization: `Bearer ${token}`, 'x-app': 'backoffice' }
    },

  });


  const link = split(
    ({ query }) => {
      const data = getMainDefinition(query);
      return (
        data.kind === 'OperationDefinition' && data.operation === 'subscription'
      );
    },
    ws,
    authLink.concat(http)
  );

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    const network: any = networkError;
    let ext = null;
    if (graphQLErrors &&
      graphQLErrors[0] &&
      graphQLErrors[0].extensions &&
      graphQLErrors[0].extensions.code &&
      graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR' &&
      graphQLErrors[0].extensions.response &&
      graphQLErrors[0].extensions.response.body &&
      graphQLErrors[0].extensions.response.body.errors &&
      graphQLErrors[0].extensions.response.body.errors[0] &&
      graphQLErrors[0].extensions.response.body.errors[0].errMsg
    ) {
      ext = graphQLErrors[0].extensions.response.body.errors[0].errMsg;
    };
    if (network &&
      network.error &&
      network.error.errors &&
      network.error.errors.length > 0 &&
      network.error.errors[0] &&
      network.error.errors[0].extensions &&
      network.error.errors[0].extensions.code &&
      network.error.errors[0].extensions.code === 'INTERNAL_SERVER_ERROR' &&
      network.error.errors[0].extensions.response &&
      network.error.errors[0].extensions.response.body &&
      network.error.errors[0].extensions.response.body.errors &&
      network.error.errors[0].extensions.response.body.errors[0] &&
      network.error.errors[0].extensions.response.body.errors[0].errMsg
    ) {
      ext = network.error.errors[0].extensions.response.body.errors[0].errMsg;
    }
    if (ext === 'UNAUTHENTICATED' || ext === 'FORBIDDEN') {
      // modify the operation context with a new token
      this.cookieService.delete('refreshToken', '/', environment.domain);
      const refreshToken = cookieService.get('refreshToken');
      if (router.url.indexOf('jwt') < 0) {
        window.location.href = '/jwt?token=' + refreshToken + '&router=' + router.url;
      }
    }
  });


  return {
    link: errorLink.concat(link),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, Router, CookieService]
    }
  ]
})
export class GraphQLModule { }
