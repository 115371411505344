import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from 'app/shared/services/data.service';
import Utils from '../../../../../shared/utils/utils';
import { FileService } from './../../../../file/file.service';
const _ = require('lodash')

@Component({
  selector: 'app-infomaterial',
  templateUrl: 'infomaterial.component.html',
  styleUrls: ['./infomaterial.component.scss']
})
export class InfomaterialDialogComponent implements OnInit {
  params: any = { orderBy: "createdAt", orderDir: "DESC", page: 1, pageSize: 25, searchText: '', filter: '' }
  loading: any = false
  disabled: boolean = true
  parent: any = null;
  parentId: string = null;
  apiname: any = null
  nameres: string = 'client_fileCols';
  listParent: any = [];
  Utils = Utils;
  choice: any = {};
  detail: any = null
  idallow: string = '';
  myFileAllow: boolean = false
  constructor(
    public dialogRef: MatDialogRef<InfomaterialDialogComponent>,
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    public fileService: FileService,
    public dataService: DataService,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.detail = JSON.parse(JSON.stringify(data));
    this.idallow = data.fileColId;
    this.myFileAllow = data.myFileAllow;
    if (data && data.consultations && data.consultations.length === 1 && data.consultations[0].files) {
      this.choice = _.keyBy(data.consultations[0].files, 'id') || [];
    }
    this.rowFilter = this.rowFilter.bind(this);
  }
  ngOnInit() {
    this.connectApi();
  }

  onClickCancel() {
    this.dialogRef.close();
  }


  ngAfterViewInit() {

  }

  checkName(title) {
    if (title.indexOf('USER_') === 0) {
      return 'Meine Dateien';
    }
    return title;
  }



  onChangeSearch(e) {
    this.params.searchText = e.searchText.trim();
    this.params.page = 1
    this.connectApi();
  }

  onSizeChange(e) {
    this.params.pageSize = e;
    this.connectApi();
  }

  onPageChange(e) {
    this.params.page = e;
    this.connectApi();
  }

  onOrderChange(e) {
    this.params.orderBy = e.name;
    this.params.orderDir = e.type;
    this.connectApi();
  }

  connectApi() {
    this.loading = true;
    if (this.parentId) {
      this.apiname = this.fileService.apiGetFiles({ ...this.params, parentId: this.parentId })
      this.nameres = 'files';
    } else {
      this.apiname = this.fileService.apiGetClientFileCols(this.params);
      this.nameres = 'client_fileCols';
    }
  }

  rowFilter(rows) {
    const data = _.filter(rows, (e) => {
      if (e.rootFileId) {
        if (this.idallow === e.id) {
          return true
        } else if (this.myFileAllow && e.title.indexOf('USER_') === 0) {
          return true
        }
        return false
      }
      return true
    });
    this.loading = false;
    return data
  }

  onClickBack() {
    if (this.listParent.length > 1) {
      const file = this.listParent[this.listParent.length - 2];
      this.parentId = file.rootFileId || file.id;
      this.parent = file;
      this.listParent.splice(this.listParent.length - 1, 1);
    } else {
      this.listParent = [];
      this.parentId = null;
      this.parent = null;
    }
    this.connectApi();
  }

  onSubmit() {
    this.detail.consultations[0].fileIds = _.keys(this.choice);
    this.detail.consultations[0].files = _.values(this.choice);
    this.dialogRef.close(this.detail);
  }

  onRowActive(row) {
    if (this.parentId === null || row.isDir) {
      this.parent = row;
      if (this.parentId === null) {
        this.parentId = row.rootFileId;
        this.listParent.push(row)
      } else {
        this.parentId = row.id;
        this.listParent.push(row)
      }
      this.connectApi();
    } else {
      if (this.choice && this.choice[row.id]) {
        delete this.choice[row.id];
      } else {
        this.choice[row.id] = row;
      }
    }
  }

  checked() {
    if (_.values(this.choice).length > 0) return true;
    return false;
  }


}
