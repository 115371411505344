import { DataService } from 'app/shared/services/data.service';
import {
  Component,
  OnInit,
} from '@angular/core';
import { MessageService } from 'app/shared/services/MessageService';
import Utils from 'app/shared/utils/utils';

@Component({
  selector: 'delete-headbar',
  templateUrl: './delete-headerbar.template.html',
})
export class DeleteHeaderbar implements OnInit {
  
  constructor(  public dataService: DataService,public messageService: MessageService) {

   
  }
  Utils = Utils;
  ngOnInit(): void {
  }

  
}
