import { Injectable } from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import * as Rx from 'rxjs/Rx';
import { BehaviorSubject } from 'rxjs';

interface BroadcastEvent {
  key: any;
  data?: any;
}
@Injectable({providedIn: 'root'})
export class BroadcastService {
  private _eventBus: Subject<BroadcastEvent>;
  constructor() {
    this._eventBus = new Subject<BroadcastEvent>();
  }

  broadcast(key: any, data?: any) {
    this._eventBus.next({key, data});
  }

  on<T>(key: any): Observable<T> {
    return this._eventBus.asObservable()
      .filter((event) => {
        // console.log('[BroadcastService] on event:', event);
        return event.key === key;
      })
      .map(event => <T>event.data);
  }

}
