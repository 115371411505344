import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatCalendar } from '@angular/material/datepicker';
import { HeaderCalender } from './header-calendar';
import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA,  } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
const _ = require('lodash')

@Component({
  selector: 'app-make-appointment',
  templateUrl: 'make-appointment.component.html',
  styleUrls: ['./make-appointment.component.scss']
})
export class MakeAppointmentDialogComponent implements OnInit {
  @ViewChild(MatCalendar, {static: false}) calendar: MatCalendar<any>;
  headerCalender = HeaderCalender;
  loading: any = false
  disabled: boolean = true
  selectedTime: any = null;
  minDate: Date = new Date();
  label:string = "";
  constructor(
    public dialogRef: MatDialogRef<MakeAppointmentDialogComponent>,
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    public translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }
  ngOnInit() {
    console.log('data in popup make', this.data.appointment);
    moment.locale(this.translateService.currentLang);
    this.label = moment().format('MMMM')
    if(this.data.appointment){
      this.selectedTime = moment(this.data.appointment);
    }
    console.log(this.selectedTime)
  }

  onSelect(event){
    this.selectedTime = event;
    this.disabled = false;
    console.log(moment(event).format('YYYY-MM-DD'));
  }

  onClickCancel() {
    this.dialogRef.close();
  }


  ngAfterViewInit() {

  }

  viewDate(mm) {
    mm.locale(this.translateService.currentLang);
    return mm.format(this.translateService.instant('FORMATDATE3'));
  }


  onSubmit() {
    this.dialogRef.close({appointment: moment(this.selectedTime).format()});
    // this.dialogRef.close({apointment: moment(this.selectedTime).format('YYYY-MM-DD')});
  }

  previousCaledar(){
    this.calendar.activeDate = moment(this.calendar.activeDate).subtract(1,'month');
    this.label = this.calendar.activeDate.locale(this.translateService.currentLang).format('MMMM')
  }

  nextCaledar(){
    this.calendar.activeDate = moment(this.calendar.activeDate).add(1,'month');
    this.label = this.calendar.activeDate.locale(this.translateService.currentLang).format('MMMM')
  }

}
