import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from 'app/shared/services/data.service';
import { ValidatorsService } from 'app/shared/services/validators.service';
import Constants from '../../../../../../shared/utils/constants'

@Component({
  selector: 'app-ds-step2',
  templateUrl: 'ds-step2.component.html',
  styleUrls: ['./ds-step2.component.scss']
})
export class DigitalSignatureStep2Component implements OnInit {
  @Input('group') group: any = null;
  @Input('dataDefault') dataDefault: any = null;
  @Input('lead') lead: any = null;
  myForm: FormGroup = null;
  maxLengthEmail: number;
  @Output() onValid = new EventEmitter();
  controls:any={}
  validDynamic:any={}
  constructor(
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    private dataService: DataService,
    private validatorsService: ValidatorsService,
    private fb: FormBuilder,
  ) {
    this.maxLengthEmail = Constants.MAX_LENGTH_INPUT_EMAIL
    this.validDynamic={
      landline:[Validators.required,this.validatorsService.phone()],
      email:[Validators.required, Validators.email],
      mobile:[Validators.required,this.validatorsService.phone()],
    }
  }
  ngOnInit() {
    const user= this.dataService.sData.user;
    this.controls = {
      contactId: new FormControl(this.lead?this.lead.id:'',Validators.required),
      salutation: new FormControl(this.lead?this.lead.salutation:'',[]),
      firstName: new FormControl(this.lead?this.lead.firstName:'',[]),
      lastName: new FormControl(this.lead?this.lead.lastName:'',[]),
      company: new FormControl(this.lead?this.lead.company:'',[]),
      street: new FormControl(this.lead?this.lead.street:'',Validators.required),
      houseNumber: new FormControl(this.lead?this.lead.houseNumber:'',Validators.required),
      zip: new FormControl(this.lead?this.lead.zip:'',Validators.required),
      city: new FormControl(this.lead?this.lead.city:'',Validators.required),
      email: new FormControl(this.lead?this.lead.email:'', []),
      landline:  new FormControl(this.lead?this.lead.landline:'', []),
      mobile: new FormControl(this.lead?this.lead.mobile:'', []),
      customerNumber: new FormControl('',[]),
      dob: new FormControl('',[]),
      permPhone: new FormControl('',[]),
      permEmail: new FormControl('',[]),
      permSms: new FormControl('',[]),
      isTelekomPartner: new FormControl(false, []),
      partnerCompanyName: new FormControl('', []),
      customDocusignTemplateId: new FormControl(user?user.customDocusignTemplateId:'', []),
      docusignRevocationEmail: new FormControl('', []),
      docusignTemplateType: new FormControl('permKek4', []),
      docusignMethod: new FormControl('', []),
      doPartnerTelekomRelevant: new FormControl(false, []),
    }
    this.initForm();
  }

  initForm() {
   
    this.myForm = new FormGroup(this.controls);
    if(this.dataDefault)this.myForm.setValue(this.dataDefault);
    this.myForm.valueChanges.subscribe(()=>{
      this.onValid.emit({status:this.myForm.valid,value:this.myForm.value});
    })
  }

  changeMethod(e,controlName)
  {
    const newVal = this.myForm.value;
    if(e.target.checked)
    {
      newVal[e.target.name] = e.target.value;
      this.controls[controlName] = new FormControl(this.lead?this.lead[controlName]:'', this.validDynamic[controlName]);
    }else{
      newVal[e.target.name] = '';
      this.controls[controlName] = new FormControl(this.lead?this.lead[controlName]:'', []);
    }
    this.initForm();
    this.myForm.setValue(newVal)
  }
  
}
