import { DataService } from 'app/shared/services/data.service';
import {
  Component,
  OnInit,
} from '@angular/core';
import { MessageService } from 'app/shared/services/MessageService';


@Component({
  selector: 'dashboard-headbar',
  templateUrl: './dashboard-headerbar.template.html',
  styleUrls: ['./dashboard-headerbar.component.scss'],
})
export class DashboardHeaderbar implements OnInit {
  constructor(public dataService: DataService, public messageService: MessageService) {


  }
  ngOnInit(): void {

  }

  myClass() {
    return 'btn-headerbar ' + (this.messageService.recordChoice === 'UNSAVED_CHANGES' ? 'active' : '');
  }

  save() {
    if (this.messageService.actions && this.messageService.selectActive && this.messageService.recordChoice === 'UNSAVED_CHANGES') {
      this.messageService.actions.save();
      this.messageService.updateActive(false);
    }
  }

  change() {
    if (this.messageService.actions) {
      this.messageService.updateActive(true);
      this.messageService.actions.change();
    }
  }

}
