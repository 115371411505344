import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import Utils from 'app/shared/utils/utils';
import { environment } from 'environments/environment';
const _ = require('lodash')

@Component({
  selector: 'app-create-profile',
  templateUrl: 'create-profile.component.html',
  styleUrls: ['./create-profile.component.scss']
})
export class CreateProfileDialogComponent implements OnInit {
  isLda:boolean =  environment.groupType === 'lda'?true:false
  isPtm:boolean =  environment.groupType === 'ptm'?true:false
  consultations: any = {};
  questions: any = []
  loading: any = false
  disabled: boolean = true
  useSideAgreement: boolean = false
  disabledCheck: boolean = true;
  activeuseSideAgreement: boolean = false;
  Utils = Utils;
  constructor(
    public dialogRef: MatDialogRef<CreateProfileDialogComponent>,
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log(data.partner);
    if (data.partner
      && data.partner.useSideAgreement) {
      this.activeuseSideAgreement = data.partner.useSideAgreement;
    }
    if (data.consultations) {

      this.consultations = JSON.parse(JSON.stringify(data.consultations));
      if (this.consultations[0].profiling && _.isString(this.consultations[0].profiling)) {
        const profiling = JSON.parse(this.consultations[0].profiling.toString());
        this.consultations[0].profiling = profiling;
        this.questions = JSON.parse(JSON.stringify(profiling.content.questions))
        this.useSideAgreement = profiling.useSideAgreement;
        
      } else if (data.profiling
        && data.profiling.content
        && data.profiling.content.questions) {
        this.consultations[0].profiling = JSON.parse(JSON.stringify(data.profiling));
        this.questions = JSON.parse(JSON.stringify(data.profiling.content.questions));
        
      }
     
    }
  }
  ngOnInit() {
    console.log('ngOnInit')
  }

  onClickCancel() {
    this.dialogRef.close();
  }


  ngAfterViewInit() {
    this.setDisabledCheck();
  }

  choiceAnser(indexQues, indexAns) {
    const question = this.questions[indexQues];
    if (question) {
      if (question.type == 'single' || question.type == 'slider') {
        let i = 0;
        question.answers.map((ans) => {
          if (indexAns !== i) {
            ans.checked = false;
          } else {
            if (ans.checked == true) {
              ans.checked = false;
            } else {
              ans.checked = true;
            }
          }
          i++;
          return ans;
        })
      } else {
        if (question.answers[indexAns].checked)
          question.answers[indexAns].checked = false
        else
          question.answers[indexAns].checked = true;
      }
      this.setDisabledCheck();
    }
  }

  onSliderChange(indexQues, e) {
    const question = this.questions[indexQues];
    if (question.type == 'slider') {
      question.answers.map((ans) => {
        ans.checked = false;
        return ans;
      })
      question.answers[e.value].checked = true;
      this.setDisabledCheck();
    }
  }

  getIndex(indexQues) {
    let index = -1;
    const question = this.questions[indexQues];
    if (question.type == 'slider') {
      let count = -1;
      question.answers.map((ans) => {
        count++;
        if (ans.checked) {
          index = count;
        }
        return ans;
      })
    }
    return index;
  }

  onChange(e:any, indexQues) {
    const question:any = this.questions[indexQues];
    question.answers = e.target.value;
    console.log(question);
    this.setDisabledCheck();
  }

  setDisabledCheck() {
    let error = true
    this.questions.map((ques) => {
      if (error === true) {
        if (ques.type == 'text') {
          if (ques.answers) error = false;
        } else {
          ques.answers.map((ans) => {
            if (ans.checked) error = false;
            return ans;
          })
        }
      }
      return ques;
    })
    this.disabledCheck = error;
    if (!this.activeuseSideAgreement && !this.disabledCheck) {
      this.useSideAgreement = true;
    } else if (this.disabledCheck) {
      this.useSideAgreement = false;
    }
    console.log('disabledCheck',this.disabledCheck);
    
     if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
  }

  onSubmit() {
    this.consultations[0].profiling.content.questions = this.questions;
    this.consultations[0].profiling.useSideAgreement = this.activeuseSideAgreement === true ? this.useSideAgreement : null;
    this.consultations[0].profiling = JSON.stringify(this.consultations[0].profiling);
    const data = { consultations: this.consultations };
    this.dialogRef.close(data);
  }

}
