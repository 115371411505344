import { DataService } from '../../../shared/services/data.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HostListener } from '@angular/core';
import { environment } from 'environments/environment';
const _ = require('lodash');

@Component({
  selector: 'partner-filter-item',
  templateUrl: './partner-filter-item.component.html',
  styleUrls: ['./partner-filter-item.component.scss'],
})
export class PartnerFilterItemComponent implements OnInit {
  isLda:boolean =  environment.groupType === 'lda'?true:false
  isPtm:boolean =  environment.groupType === 'ptm'?true:false  
  @Input('dataPartner') dataPartner: any = {};
  @Output() updateItem = new EventEmitter();//Sự kiện thay đổi số phần tử trên 1 trang
  boxsearch: string = '';
  activeFilter: boolean = false;
  isShowPartner: boolean = false;
  isShowMore: boolean = false;
  isShowLess: boolean = false;
  typeShow: string = 'less';
  textSearch: string = '';
  isClickButtonShow: boolean = true;

  @HostListener('document:click', ['$event'])
  onKeyUp(ev) {
    const parent = document.getElementById('modal-partner');
    if (parent && this.isClickButtonShow === false) {
      const isDescendant = parent.contains(ev.target);
      if (this.isShowPartner === true) {
        if (isDescendant || parent == ev.target) {
        } else {
          this.isShowPartner = false;
          this.isClickButtonShow = true;
        }
      }
    }
  }

  constructor(
    public dataService: DataService,
    private translateService: TranslateService,
  ) {}

  selectAllOrgs() {
    this.dataPartner.subOrgs = this.dataPartner.subOrgs.map((item) => {
      return {
        ...item,
        selected: true,
      };
    });
    this.dataPartner.isMain = true;
  }

  ngOnDestroy() {}

  isShowButtonClear() {
    if (this.dataPartner.subOrgs && this.dataPartner.subOrgs.length > 0) {
      const count = this.dataPartner.subOrgs.filter(
        (item) => item.selected === true,
      ).length;
      if (count > 0) {
        return true;
      }
    }
    return false;
  }

  closeOrgs() {
    this.isShowPartner = false;
    this.isClickButtonShow = true;
  }

  clearOrgs() {
    this.dataPartner.subOrgs = this.dataPartner.subOrgs.map((item) => {
      return {
        ...item,
        selected: false,
      };
    });
    this.dataPartner.isMain = false;
    this.isClickButtonShow = true;
  }

  onChangeSearch(e) {
    this.textSearch = e.searchText.trim();
  }

  getTextSubPartner() {
    if (this.dataPartner.subOrgs && this.dataPartner.subOrgs.length > 0) {
      const countAll = this.dataPartner.subOrgs.length;
      const count = this.dataPartner.subOrgs.filter(
        (item) => item.selected === true,
      ).length;
      let text = '';
      if (this.dataPartner.isMain) {
        if (count === 0) {
          text += `${this.translateService.instant('ONLY')} `;
        }
        text += `${this.translateService.instant('MAIN_PARTNER')} `;
      }
      if (count === 0) {
        if (!this.dataPartner.isMain) {
          text += this.translateService.instant('FILTER');
        }
      } else {
        if (this.dataPartner.isMain) {
          text += `${this.translateService.instant('AND')} `;
        }
        if (count === 1) {
          text += `${this.translateService.instant('ONLY')} `;
        }
        text += `${count} ${this.translateService.instant(
          'OF',
        )} ${countAll} ${this.translateService.instant(
          'SUB_PARTNER_SELECTED',
        )}`;
      }

      if(this.dataPartner.isMain && countAll === count) {
        text = this.translateService.instant('FILTER');
      }

      return {
        text,
        class: 'partner-item__subpartner',
        canSelect: true,
      };
    } else {
      return {
        text: this.translateService.instant('NO_SUB_PARTNER_AVAILABLE'),
        class: 'partner-item__empty',
        canSelect: false,
      };
    }
  }

  getErrorSubPartner() {
    const count = this.dataPartner.subOrgs.filter(
      (item) => item.selected === true,
    ).length;

    if (
      this.dataPartner.isMain === false &&
      count===0
    ) {
      this.dataPartner.hasError = true;
      return this.translateService.instant(
        'MUST_BE_CHOOSE_MAIN_PARTNER_OR_SUB_PARTNER',
      );
    }
    this.dataPartner.hasError = false;
  }

  getOrgFilter(orgs) {
    return orgs.filter(
      (item) =>
        item.companyName
          .toLowerCase()
          .indexOf(this.textSearch.toLocaleLowerCase()) >= 0,
    );
  }

  ngOnInit() {}

  onClickShowPartner() {
    this.isShowPartner = !this.isShowPartner;
    this.isClickButtonShow = true;
    setTimeout(() => {
      this.isClickButtonShow = false;
    }, 1);
  }

  deleteItem()
  {
    this.dataPartner.selected = false
    this.updateItem.emit(this.dataPartner)
  }
  
  change($event)
  {
    console.log('change', this.dataPartner);
    this.updateItem.emit(this.dataPartner)
  }
}
