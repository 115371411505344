import { DataService } from 'app/shared/services/data.service';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
const _ = require('lodash');

@Component({
  selector: 'app-group-choice-dialog',
  templateUrl: 'group-choice-dialog.component.html',
  styleUrls: ['./group-choice-dialog.component.scss'],
})
export class GroupChoiceialogComponent implements OnInit {
  link: string = environment.mediaUrl + 'fs/files/appdata/';
  listGroup: any = []
  constructor(
    public dialogRef: MatDialogRef<GroupChoiceialogComponent>,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.listGroup = data.list
  }
  viewImg(url) {
    if (url) {
      return this.link + url;
    }
    return 'assets/icons/Group.svg'
  }
  ngOnInit() {
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  ngAfterViewInit() { }



  onSubmit(row) {
    this.dialogRef.close(row);
  }
}
