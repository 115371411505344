import { DataService } from 'app/shared/services/data.service';
import {
  Component,
  OnInit,
} from '@angular/core';
import { MessageService } from 'app/shared/services/MessageService';
import { environment } from 'environments/environment';


@Component({
  selector: 'partner-list-headbar',
  templateUrl: './partner-list-headerbar.template.html',
})
export class PartnerListHeaderbar implements OnInit {
  isLda:boolean =  environment.groupType === 'lda'?true:false
  isPtm:boolean =  environment.groupType === 'ptm'?true:false
  constructor(  public dataService: DataService,public messageService: MessageService) {

   
  }
  ngOnInit(): void {
  }

  
}
