import { DataService } from 'app/shared/services/data.service';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';

@Component({
  selector: 'pop-up-choice-template',
  templateUrl: 'pop-up-choice-template.component.html',
  styleUrls: ['./pop-up-choice-template.component.scss'],
})
export class TemplateChoiceDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TemplateChoiceDialogComponent>,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    public snackBar: MatSnackBar,
    private location: Location,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }

  ngOnInit() {
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  ngAfterViewInit() { }

  onSubmit(row) {
    this.location.go('/dashboard/' + row.id);
    this.dialogRef.close({ choice: row });
  }

  onEdit(row) {
    this.dialogRef.close({ edit: row });
  }
}
