import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import Constants from '../utils/constants';
import Utils from '../../shared/utils/utils';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { CookieService } from 'ng2-cookies';
import { variable } from '@angular/compiler/src/output/output_ast';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private apollo: Apollo,
  ) {}

  apiAccountUserLogin(params) {
    params.tenantId = Constants.tenantId;
    let jsonParams = JSON.stringify(params).replace(/\"([^(\")"]+)\":/g,"$1:");
    return this.apollo.mutate({mutation: gql`
      mutation {
        accountUserLogin(input: ${jsonParams}) {
          data
        }
      }`, variables: { },
    });
  }
  apiAccountForgotPassword(params) {
    params.tenantId = Constants.tenantId;
    let jsonParams = JSON.stringify(params).replace(/\"([^(\")"]+)\":/g,"$1:");
    return this.apollo.mutate({mutation: gql`
      mutation {
        accountForgotPassword(input: ${jsonParams}) {
          data
        }
      }`, variables: { },
    });
  }
  apiLogin(params) {
    // console.log('apiLogin params:', params);
    return this.apollo.mutate({
      // mutation: gql`
      //   mutation {
      //     slApiCall(input: {
      //       method: "POST",
      //       uri: "/api/client/v2/session/login",
      //       body: {
      //         email: "${params.email}",
      //         password: "${params.password}"
      //       }
      //     }) {
      //       data
      //     }
      //   }
      // `,
      mutation: gql`
        mutation {
          signIn(tenantId: "${Constants.tenantId}", email: "${params.email}", password: "${params.password}") {
            data
            tokenSet
          }
        }
      `,
      variables: {
      },
    });
  }

  apiGetGroups(params) { // old SL -> to remove
    return this.apollo.mutate({
      mutation: gql`
        mutation {
          slApiCall(input: {
            method: "GET",
            uri: "/api/client/v2/groups",
          }) {
            data
          }
        }
      `,
      variables: {
      },
    });
  }

  apiGetGroupDetail(groupId) {
    return this.apollo.mutate({
      mutation: gql`
        mutation {
          slApiCall(input: {
            method: "GET",
            uri: "/api/client/v2/groups/${groupId}",
          }) {
            data
          }
        }
      `,
      variables: {
      },
    });
  }

  apiGetMeV2Data() {
    return this.apollo.mutate({
      mutation: gql`
        mutation {
          slApiCall(input: {
            method: "GET",
            uri: "/api/client/v2/mdav2/me/v2data",
          }) {
            data
          }
        }
      `,
      variables: {
      },
    });
  }

  setAuthToken(data) {
    const { access_token, refresh_token, expires_at } = data;
    if (access_token) {
      this.cookieService.set('token', access_token, undefined, '/', environment.domain);
    }
    if (refresh_token) {
      this.cookieService.set('refreshToken', refresh_token, undefined, '/', environment.domain);
    }
    const d = new Date();
    const totalSecond: number = d.getTime() + expires_at * 1000;
    localStorage.setItem('ExpiresIn', totalSecond.toString());
  }

  getAuthToken() {
    return this.cookieService.get('token');
  }
  logout() {

  }

  update(params) {
    return this.apollo.mutate({
      mutation: gql`
      mutation  {
        updateMyself(${Utils.params2GqlStr(params)}){
          data
      }}`,
    });
  }

  changePasswordMyself(params) {
    return this.apollo.mutate({
      mutation: gql`
      mutation  {
        changePasswordMyself(${Utils.params2GqlStr(params)}){
          data
      }}`,
    });
  }

  getNewToken() {
    return this.apollo.mutate({
      mutation: gql`
      mutation  {refreshToken}`,
    });
  }

  me() {
    return this.apollo.query({
      query: gql`query {
      myself {
        data
    }}`, fetchPolicy: 'no-cache',
    });
  }
}
