import { environment } from 'environments/environment';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'localizedAmTimeAgo',
  pure: false
})
export class LocalizedAmTimeAgoPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
    moment.locale(this.translateService.currentLang);
    this.translateService.onLangChange.subscribe((v) => {
      moment.locale(v.lang);
    });
  }

  transform(value: Date | moment.Moment): any {
    return moment(value).fromNow();
  }
  ngAfterViewChecked() {
    console.log("! changement de la date du composant !");
  }
}