import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, AfterViewInit, OnDestroy, Input, ChangeDetectorRef, QueryList, Directive, ContentChildren, TemplateRef, ContentChild, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MessageService } from '../services/MessageService';
import Utils from '../utils/utils';
const _ = require('lodash');

@Directive({
  selector: 'app-no-data'
})
export class NoDataDirective {
  @ContentChild(TemplateRef, { static: false }) template;
}


@Directive({
  selector: 'app-column'
})
export class ColumnDirective {
  @Input() header: string;
  @Input() key: string;
  @Input() className = '';
  @Input() width = 'auto';
  @Input() align = 'left';
  @Input() activeOrder: Boolean = true;
  @ContentChild(TemplateRef, { static: false }) template;
}


@Directive({
  selector: 'app-thumbnail'
})
export class ThumbnailDirective {
  @Input() header: string;
  @Input() key: string;
  @Input() className = '';
  @Input() styleCheckbox = '';
  @Input() width = 'auto';
  @Input() activeOrder: Boolean = true;
  @ContentChild(TemplateRef, { static: false }) template;
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() detectData: Subject<any>;
  @Input('isEffectActive') isEffectActive = true; // Tùy biến hiển thị effect [hover,...]
  @Input('data') data: any = []; // Mảng dữ liệu cố định nếu ko có API
  @Input('viewType') viewType = 0; // Loại hiển thị: mặc định dạng List
  @Input('apiGet') apiGet: any; // Chứa kết quả trả về của 1 hàm lấy nhiều data từ API trong service bất kỳ
  @Input('nameres') nameres: string; // Tên key gốc lấy data từ api
  @Input('moreres') moreres = ''; // Tên key mở rộng lấy data từ api
  @Input('size') size = 25; // Số phần tử trên 1 trang
  @Input('defaultChoice') defaultChoice: any = []; // Danh sách chọn mặc định
  @Input('defaultPage') defaultPage = 1; // Trang mặc định
  @Input('disabledChoice') disabledChoice = true; // Khóa chức năng lựa chọn
  @Input('disabledPage') disabledPage = false; // Khóa chức năng phân trang
  @Input('nolabel') nolabel = 'NO_RESULT_AVAILABLE'; // Tên key ngôn ngữ thể hiện dữ liệu không có
  @Input('rowFilter') rowFilter: (rows: any) => null; // Hàm lọc data trước khi hiển thị trên danh sách
  @Input('height') height = 0; //
  @Input('refresh') refresh: any = false; //
  @Input('marginBottom') marginBottom = 90; //
  @Input('allowSize') allowSize = true; //
  @Input('refreshList') refreshList: any = false;
  @Input('isEnableExportFn') isEnableExportFn = false;
  @Output() onSizeChange = new EventEmitter(); // Sự kiện thay đổi số phần tử trên 1 trang
  @Output() onPageChange = new EventEmitter(); // Sự kiện thay đổi trang
  @Output() onOrderChange = new EventEmitter(); // Sự kiện thay đổi sắp xếp cột
  @Output() onSelectChange = new EventEmitter(); // Sự kiện thay đổi lựa chọn
  @Output() onRowActive = new EventEmitter(); // Sự kiện click chuột trên danh sách
  @Output() onGetData = new EventEmitter(); // Sự kiện trả về danh sách sau khi lấy từ api
  @Output() onGetTotal = new EventEmitter(); // Sự kiện trả về Tổng dữ liệu sau khi lấy từ api
  @Output() onActionClick = new EventEmitter(); // Sự kiện trả về Tổng dữ liệu sau khi lấy từ api
  selectActive = false;
  activeCheckbox = false; // Biến lưu trạng thái ẩn hiện checkbox
  listArray: any = []; // Mảng dữ liệu lấy từ API
  total = 0; // Tổng số bản ghi
  currentPage = 1; // Trang hiện tại
  loading = false; // Trạng thái tải dữ liệu
  listChoicePage: any = []; // Mảng dữ liệu thể hiện các item dc chọn trên 1 trang
  listChoiceGlobal: any = {}; // Mảng dữ liệu thể hiện các item dc chọn trong cả danh sách trên tất cả các trang
  orderColumn = ''; // Cột được sắp xếp
  orderType = 'DESC'; // Loại sắp xếp (ASC|DESC)
  arrPerPage: any = [10, 25, 50, 100, 200]; // Danh sách số phần tử trên 1 trang
  totalPage = 1;
  Utils = Utils;
  @ContentChildren(ColumnDirective) columns: QueryList<ColumnDirective>;
  @ContentChild(ThumbnailDirective, { static: false }) thumbnail!: ThumbnailDirective;
  @ContentChild(NoDataDirective, { static: false }) nodata!: NoDataDirective;

  constructor(
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public messageService: MessageService,
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.apiGet) {
        this.apiGet = changes.apiGet.currentValue;
        this.doGetData();
      }
      if (changes.defaultChoice) {
        if (changes.defaultChoice.currentValue.length > 0) {
          this.listChoiceGlobal = _.keyBy(changes.defaultChoice.currentValue, 'id');
          this.onAutoCheck();
        } else {
          this.onCloseSelectActive();
        }
      }
      if (changes.defaultPage) {
        this.currentPage = changes.defaultPage.currentValue;
      }
      if (changes.data) {
        this.data = changes.data.currentValue;
        this.createListFromData(this.data);
      }
      if (changes.refresh && this.refresh.currentValue !== changes.refresh) {
        this.doGetData(false);
      }
      if (changes.refreshList) {
        this.selectActive = false;
        this.cdr.detectChanges();
      }
    }
  }

  ngOnInit() {
    this.listChoiceGlobal = _.keyBy(this.defaultChoice, 'id');
    this.doGetData();
    if (this.detectData) {
      this.detectData.subscribe((v) => {
        this.data = v;
        this.createListFromData(this.data);
      });
    }
  }

  onClickDelete() {
    this.onActionClick.emit({ selectedList: _.values(this.listChoiceGlobal), action: 'delete' });
    // sthis.selectActive = false
  }

  onClickExport() {
    this.onActionClick.emit({ selectedList: _.values(this.listChoiceGlobal), action: 'export' });
    // this.selectActive = false
  }

  doGetData(load: boolean = true) {
    if (this.apiGet) {
      if (load) {
        this.loading = true;
        if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
      }
      this.apiGet.subscribe(({ data }) => {
        const apiRes: any = data;
        if (this.nameres) {
          // document.getElementById('rightside-content-hold').scroll(0, 0);
          const arrres = this.nameres.split('.');
          let _localData = apiRes;
          if (_localData) {
            arrres.map(key => {
              _localData = _localData[key];
              return key;
            });
            if (_localData && _localData.entities) {
              let list = _localData.entities;

              if (this.moreres) {
                const arrmore = this.moreres.split('.');
                let _localMore = Object.assign({}, _localData);
                let lastkey = '';
                arrmore.map(key => {
                  lastkey = key;
                  _localMore = _localMore[key];
                  return key;
                });
                if (_localMore) {
                  list = _.map(list, (element, index) => {
                    const more = {};
                    more[lastkey] = _localMore[index];
                    return _.extend({}, element, more);
                  });
                }

              }
              if (this.rowFilter) {
                const newdata: any = this.rowFilter(list);
                if (Array.isArray(newdata)) {
                  this.listArray = newdata;
                  this.total = _localData.total;
                } else if (newdata.list && newdata.total) {
                  this.listArray = newdata.list;
                  this.total = newdata.total;
                } else if (!newdata.list.length) {
                  this.listArray = [];
                  this.total = 0;
                }
              } else {
                this.listArray = list;
                this.total = _localData.total;
              }
              this.totalPage = Math.ceil(this.total / this.size);
              this.onGetData.emit(_localData);
              this.onGetTotal.emit(this.total);
              this.onAutoCheck();
            }/* else {
              this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002')
            }*/
          }
        }
        this.loading = false;
        if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
      }, () => {
        this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
        this.loading = false;
        if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
      });
    } else if (this.data) {
      this.createListFromData(this.data);
    }
  }

  createListFromData(_data) {
    if (_data && Array.isArray(_data) && _data.length > 0) {
      const begin = (this.currentPage - 1) * this.size;
      const end = begin + this.size;
      this.listArray = _.slice(this.data, begin, end);
      this.total = this.data.length;
      this.totalPage = Math.ceil(this.total / this.size);
      this.onAutoCheck();
      if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
    } else {
      this.listArray = [];
      this.total = 0;
      this.totalPage = Math.ceil(this.total / this.size);
      this.onAutoCheck();
      if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
    }
  }

  ngAfterViewInit(): void {
    // this.container.nativeElement.innerHTML = '<h1>Hello</h1>'

  }

  ngOnDestroy(): void {

  }
  // Tự động setCheck khi API dc gọi
  onAutoCheck() {
    const global = _.keyBy(this.listArray, 'id');
    this.listChoicePage = _.isEmpty(this.listChoiceGlobal) ? [] : _.values(_.pick(this.listChoiceGlobal, _.keys(global)));
  }
  // Sự kiện chọn tất cả
  onCheckAll(e) {
    const global = _.keyBy(this.listArray, 'id');
    if (e.detail.checked) {
      this.listChoicePage = [...this.listArray];
      this.listChoiceGlobal = { ...this.listChoiceGlobal, ...global };
    } else {
      this.listChoicePage = [];
      this.listChoiceGlobal = _.omit(this.listChoiceGlobal, _.keys(global));
    }
    this.onSelectChange.emit(_.values(this.listChoiceGlobal));
  }
  // Sự kiện chọn 1 phần tử
  onCheckItem(e, _row) {
    if (e.checked) {
      this.listChoicePage.push(_row);
      this.listChoiceGlobal[_row.id] = _row;
    } else {
      const localChoice = _.keyBy(this.listChoicePage, 'id');
      delete localChoice[_row.id];
      this.listChoicePage = _.values(localChoice);
      delete this.listChoiceGlobal[_row.id];
    }
    this.onSelectChange.emit(_.values(this.listChoiceGlobal));
  }
  // Sự kiệm bấm nút sang trang
  onPage(_page) {
    if (_page > 0 && _page <= this.totalPage) {
      this.listChoicePage = [];
      this.currentPage = _page;
      this.onPageChange.emit(_page);
      if (!this.apiGet && this.data) {
        // document.getElementById('rightside-content-hold').scroll(0, 0);
        this.createListFromData(this.data);
      }
    }
  }

  getstartElement() {
    return (this.currentPage - 1) * this.size;
  }
  scalePagination($event) {
    this.listChoicePage = [];
    this.currentPage = Math.floor($event.detail.startElement / this.size) + 1;
    this.onPageChange.emit(this.currentPage);
    if (!this.apiGet && this.data) {
      // document.getElementById('rightside-content-hold').scroll(0, 0);
      this.createListFromData(this.data);
    }
  }
  // Tính ra số trang
  // totalPage() {
  //   const totalPage = Math.ceil(this.total / this.size)
  //   return new Array(totalPage).fill(1)
  // }
  createPageView() {
    if (this.totalPage < 6) { return new Array(this.totalPage).fill(1); } else {
      if (this.currentPage === 1 || this.currentPage === 2 || this.currentPage === 3) {
        return [1, 2, 3, 4, '...', this.totalPage];
      } else if (this.currentPage === this.totalPage || this.currentPage === this.totalPage - 1
        || this.currentPage === this.totalPage - 2) {
        return [1, '...', this.totalPage - 3, this.totalPage - 2, this.totalPage - 1, this.totalPage];
      } else {
        return [1, '...', this.currentPage - 1, this.currentPage, this.currentPage + 1, '...', this.totalPage];
      }
    }
  }
  // Sự kiện bấm đầu đề cột
  headClick(_name, _oldType) {
    this.orderColumn = _name;
    this.orderType = _oldType === 'ASC' ? 'DESC' : 'ASC';
    this.onOrderChange.emit({ name: this.orderColumn, type: this.orderType });
  }
  // Sự kiện bấm vào các dòng
  rowClick(row, noActive) {
    if (!noActive) {
      this.onRowActive.emit(row);
    }
  }

  // Sự kiện thay đổi size
  onSize() {
    this.onSizeChange.emit(parseInt(this.size.toString()));
    this.onPage(1);
  }

  getSize(i) {
    return typeof (i);
  }

  getTotalChoice() {
    return Object.keys(this.listChoiceGlobal).length;
  }

  onCloseSelectActive() {
    this.selectActive = false;
    this.listChoiceGlobal = [];
    this.listChoicePage = [];
  }

}
