import { MessageService } from './../../../../shared/services/MessageService';
import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { PartnerService } from './../../partner.service';
import Constants from '../../../../shared/utils/constants'

@Component({
  selector: 'user-add-item',
  templateUrl: 'add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddNewUserComponent implements OnInit {
  email: any = '';
  mobileNumber: any = '';
  messageEmail: any = '';
  messagePhone: any = '';
  loading: any = false;
  userId: any = '';
  maxLengthEmail: number;
  @ViewChild('searchTextInput', { static: false }) searchTextInput: any;
  constructor(
    public dialogRef: MatDialogRef<AddNewUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    myElement: ElementRef,
    public translateService: TranslateService,
    private partnerService: PartnerService,
    public snackBar: MatSnackBar,
    public messageService: MessageService,
    private fb: FormBuilder,
  ) {
    this.messageEmail = '';
    this.messagePhone = '';
    this.maxLengthEmail = Constants.MAX_LENGTH_INPUT_EMAIL
  }

  ngOnInit() { }

  changeEmail(value) {
    this.email = value;
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(value).toLowerCase())) {
      this.messageEmail = '';
    } else {
      this.messageEmail = this.translateService.instant(
        'INVALID_EMAIL_ADDRESS',
      );
    }
  }

  changePhoneNumber(value) {
    const re = /^([0-9\-+\s]{8,})?$/i;
    if (re.test(String(value).toLowerCase())) {
      this.messagePhone = '';
    } else {
      this.messagePhone = this.translateService.instant(
        'INVALID_MOBILE_NUMBER',
      );
    }
    this.mobileNumber = value;
  }

  isDisableSave() {
    if (
      !this.email ||
      !this.mobileNumber ||
      this.messageEmail ||
      this.messagePhone
    ) {
      return true;
    }
    return false;
  }

  onClickSave() {
    this.messageEmail = '';
    this.loading = true;
    this.partnerService
      .adOrgAddUserByEmail({
        id: this.data.PartnerId,
        step: 'checkEmail',
        email: this.email,
      })
      .subscribe(
        ({ data }) => {
          let apiRes: any = data;
          if (apiRes.adOrgAddUserByEmail.data.code === 'USER_ALREADY_ADDED') {
            this.messageEmail = this.translateService.instant('ACCOUNT_ALREADY_EXISTS');
            this.loading = false;
          } else if (apiRes.adOrgAddUserByEmail.data.code === 'USER_EXISTED_IN_ANOTHER_ORG') {
            this.messageEmail = this.translateService.instant('USER_EXISTED_IN_ANOTHER_ORG');
            this.loading = false;
          } else {
            this.messageEmail = '';
            this.partnerService
              .adOrgAddUserByEmail({
                id: this.data.PartnerId,
                step: 'createUser',
                email: this.email,
                mobileNumber: this.mobileNumber,
              })
              .subscribe(
                ({ data }) => {
                  let apiRes: any = data;
                  this.loading = false;
                  if (apiRes.adOrgAddUserByEmail.data.success) {
                    this.messageService.showSuccess(
                      'USER_ADDED',
                      'I_GENERAL_004',
                    );
                    this.dialogRef.close('Ok');
                  } else {
                    this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
                  }
                },
                () => {
                  this.loading = false;
                  this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
                },
              );
          }
        },
        () => {
          this.loading = false;
          this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
        },
      );
  }

  onClickCancel() {
    this.dialogRef.close();
  }
}
