import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Inject, } from '@angular/core';
import Utils from 'app/shared/utils/utils';

@Component({
  selector: 'app-popup-confirm',
  templateUrl: './popup-confirm.component.html',
  styleUrls: ['./popup-confirm.component.scss']
})
export class PopupConfirmComponent implements OnInit {
  comment:string = "";
  constructor(
    public dialogRef: MatDialogRef<PopupConfirmComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  Utils = Utils;
  
  ngOnInit() {
  }

  onClickCancel(re=null) {
    this.dialogRef.close(re);
  }

  onConfirm() {
    this.dialogRef.close(this.data.iscomment?this.comment:'OK');
  }

}
