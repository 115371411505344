import { BreakpointObserver } from '@angular/cdk/layout';
import { ParentComponent } from './../../../parent.component';
import { LayoutService } from 'app/shared/services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { LeadHeaderbar } from './headerbar/lead-headerbar.component';
import { PopupConfirmComponent } from './../../../shared/components/popup-confirm/popup-confirm.component';
import { MessageService } from './../../../shared/services/MessageService';
import { LeadService } from './../lead.service';
import { environment } from '../../../../environments/environment';
import { VcardScanDialogComponent } from './dialog/vcard-scan/vcard-scan.component';
import { MakeAppointmentDialogComponent } from './dialog/make-appointment/make-appointment.component';
import { InfomaterialDialogComponent } from './dialog/infomaterial/infomaterial.component';
import { CustomerNumberDialogComponent } from './dialog/customer-number/customer-number.component';
import { CustomerConsentDialogComponent } from './dialog/customer-consent/customer-consent.component';
import { CreateProfileDialogComponent } from './dialog/create-profile/create-profile.component';
import { ContactDetailDialogComponent } from './dialog/contact-detail/contact-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../../shared/services/data.service';
import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import moment from 'moment';
import { AuthService } from 'app/shared/services/auth.service';
import md5 from 'crypto-js/md5';
import { SaveTemplateDialogComponent } from './dialog/save-template/save-template.component';
import { ResetTemplateDialogComponent } from './dialog/reset-template/reset-template.component';
import { DigitalSignatureDialogComponent } from './dialog/digital-signature/digital-signature.component';
import Utils from 'app/shared/utils/utils';
const _ = require('lodash');

@Component({
  selector: 'app-lead-detail',
  templateUrl: './lead-detail.component.html',
  styleUrls: ['./lead-detail.component.scss'],
})
export class LeadDetailComponent extends ParentComponent implements OnInit {
  isLda: boolean = environment.groupType === 'lda' ? true : false;
  isPtm: boolean = environment.groupType === 'ptm' ? true : false;
  @Input('type') type = 'lead';
  linkparent = '/lead/all';
  queryParams: any = null;
  loading = false;
  group: any = null;
  partner: any = null;
  detail: any = null;
  id: any = null;
  dateAppointment: any;
  isShowOptional = true;
  PermManageContact = false;
  PermAllContacts = false;
  PermContactsBasic = false;
  Utils = Utils;
  PERM_MANAGE_CONTACT = 'PermManageContact';
  PERM_ALL_CONTACTS = 'PermAllContacts';
  PERM_CONTACT_BASIC = 'PermContactsBasic';
  exportFail = false;
  infoMailSentAtHours: any;
  infoMailSentAtMinutes: any;
  exportAtHours: any;
  exportAtMinutes: any;
  currentImg = 1;
  link: string = environment.mediaUrl + 'fs/files/uploads/';
  allowReset = false;
  constructor(
    public breakpointObserver: BreakpointObserver,
    public dataService: DataService,
    public messageService: MessageService,
    public leadService: LeadService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    public translateService: TranslateService,
    public authService: AuthService,
    public layoutService: LayoutService,
    public dialog: MatDialog,
  ) {
    super(breakpointObserver, layoutService, dialog);
    // let option: any = this.layoutService.optionModal() || { width: '500px' }
  }

  ngOnInit() {
    this.partner = this.dataService.getPartner(true);
    if (this.type === 'lead') {
      this.linkparent = '/lead/all';
    } else {
      this.linkparent = '/contacts/all';
    }
    this.queryParams = { s: this.route.snapshot.queryParamMap.get('s') };
    this.initPage();
    const permission = JSON.parse(localStorage.getItem('permission'));
    this.PermManageContact = permission.includes(this.PERM_MANAGE_CONTACT);
    this.PermAllContacts = permission.includes(this.PERM_ALL_CONTACTS);
    this.PermContactsBasic = permission.includes(this.PERM_CONTACT_BASIC);
  }

  ngAfterViewInit() {
    this.messageService.compConfig(LeadHeaderbar);
  }

  ngOnDestroy(): void {
    this.messageService.reset();
  }

  checkPerBtnContactDetail() {
    if (this.PermManageContact) {
      return true;
    } else {
      if (this.detail.status === 'export') {
        return false;
      } else {
        return true;
      }
    }
  }

  checkStatus() {
    if (this.type === 'lead') {
      if (this.detail.status === 'freigegeben' || this.detail.status === 'export') {
        return false;
      }
    } else {
      if (this.PermManageContact) {
        return true;
      } else {
        if (this.detail.status === 'export') {
          return false;
        } else {
          return true;
        }
      }
    }
    return true;

  }

  checkDone() {
    let isDone = false;
    if (this.detail.status === 'laufend' &&
      this.detail.firstName &&
      this.detail.lastName &&
      this.detail.company &&
      this.detail.email) {
      isDone = true;
      if (this.group.settingLead) {
        const setting = this.group.settingLead;
        // Check Product
        if (setting.selectProduct.required &&
          setting.selectProduct.enabled &&
          isDone) {
          if (this.detail.consultations &&
            this.detail.consultations.length === 1 &&
            this.detail.consultations[0] &&
            this.detail.consultations[0].consultationLeads &&
            this.detail.consultations[0].consultationLeads.length > 0) {
            isDone = true;
          } else {
            isDone = false;
          }
        }
        // Check Customer consent
        if (setting.customerConsent.required &&
          setting.customerConsent.enabled &&
          isDone) {
          if (this.detail.customerConsent) {
            isDone = true;
          } else {
            isDone = false;
          }
        }
        // Check Enter Customer Number
        if (setting.customerNumber.required &&
          setting.customerNumber.enabled &&
          isDone) {
          if (this.detail.crmTdgCustomerId &&
            this.detail.crmTdgSegment) {
            isDone = true;
          } else {
            isDone = false;
          }
        }
        // Check Make Appointment
        if (setting.makeAppointment.required &&
          setting.makeAppointment.enabled &&
          isDone) {
          if (this.detail.appointment) {
            isDone = true;
          } else {
            isDone = false;
          }
        }
        // Check Profile
        if (setting.profile.required &&
          setting.profile.enabled &&
          isDone) {
          if (this.detail.consultations &&
            this.detail.consultations.length === 1 &&
            this.detail.consultations[0] &&
            this.detail.consultations[0].profiling
          ) {
            isDone = true;
          } else {
            isDone = false;
          }
        }
        // Check infomaterial
        if (setting.infomaterial.required &&
          setting.infomaterial.enabled &&
          isDone) {
          if (this.detail.consultations &&
            this.detail.consultations.length === 1 &&
            this.detail.consultations[0] &&
            this.detail.consultations[0].files &&
            this.detail.consultations[0].files.length > 0
          ) {
            isDone = true;
          } else {
            isDone = false;
          }
        }
        // Check vCard scan
        if (setting.cardScan.required &&
          setting.cardScan.enabled &&
          isDone) {
          if (this.detail.bCardFrontFilename &&
            this.detail.bCardBackFilename) {
            isDone = true;
          } else {
            isDone = false;
          }
        }
      } else {
        isDone = false;
      }
    }
    if (isDone) {
      this.messageService.updateActive(true);
      this.messageService.updateCount('');
    } else {
      this.messageService.updateActive(false);
      if (this.detail.status === 'laufend') {
        this.messageService.updateCount(this.translateService.instant('LEAD_FORM_NOT_COMPLETED'));
      } else { this.messageService.updateCount(''); }
    }
    this.messageService.registryAction({
      save: () => {
        this.loading = true;
        this.detail.status = 'freigegeben';
        this.leadService.client_upsertContact(this.detail).subscribe(() => {
          this.messageService.updateActive(false);
          this.messageService.updateCount('');
          this.loading = false;
          if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
          if (this.type === 'lead') {
            this.router.navigate(['/lead/all', { queryParams: this.queryParams }]);
          } else {
            this.router.navigate(['/contacts/all', { queryParams: this.queryParams }]);
          }
        }, () => {
          this.loading = false;
          if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
          this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
        });
      },
      close: () => {

      },
    });
    if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
  }



  initPage() {
    const { id, idgroup } = this.route.snapshot.params;
    this.id = idgroup;
    this.getGroup(idgroup, id);
    if (id === 'new') {
    } else {
    }

  }

  onChangeImage(index) {
    this.currentImg = index;
  }

  getOrg(id) {
    const partner = this.dataService.getPartner();
    let org = null;
    if (partner && _.find(partner.groups, { id })) {
      org = partner;
    }
    if (org) {
      this.leadService.apiDetailPartner({ id: org.id }).subscribe(({ data }: { data: any }) => {
        if (data.adOrg) {
          this.partner = { ...data.adOrg, ...this.partner };
          console.log('partner:', data.adOrg);
        }
      });
    }
  }

  getGroup(idgroup, id) {
    this.loading = true;
    const orgId = null;
    const listGroup = this.dataService.getGroups();
    if (listGroup && Array.isArray(listGroup) && listGroup.length > 0) {
      const find = _.find(listGroup, { id: idgroup });
      if (idgroup && find) {
        this.getOrg(idgroup);
      } else if (listGroup.length > 0) {
        this.getOrg(listGroup[0].id);
      }
    }

    this.leadService.apiGetGroupDetail(idgroup).subscribe(
      (res: any) => {
        this.group = { ...res.data.group, orgId };
        this.loading = false;
        this.getLead(id);
        for (const key in this.group.settingLead) {
          if (this.group.settingLead.hasOwnProperty(key)) {
            if (
              this.group.settingLead[key].enabled &&
              !this.group.settingLead[key].required
            ) {
              this.isShowOptional = true;
              break;
            } else {
              this.isShowOptional = false;
            }
          }
        }
        if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
      },
      () => {
        this.loading = false;
        if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
        this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
      },
    );
  }

  getLead(id) {
    this.loading = true;
    this.leadService.apiDetail(id).subscribe((res: any) => {
      if (res.data && res.data.contact) {
        this.detail = res.data.contact;
        if (this.detail.infoMailSentAt) {
          this.infoMailSentAtHours = new Date(this.detail.infoMailSentAt).getHours();
          this.infoMailSentAtMinutes = new Date(this.detail.infoMailSentAt).getMinutes();
        }
        if (this.detail.export.mcl) {
          this.exportAtHours = new Date(this.detail.export.mcl.exportedAt).getHours();
          this.exportAtMinutes = new Date(this.detail.export.mcl.exportedAt).getMinutes();
        }
        this.loading = false;
        this.checkAllowReset();
        this.checkDone();
        this.dateAppointment = moment(this.detail.appointment);
        console.log('Lead detail', this.detail);

        if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
      } else {
        this.loading = false;
        if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
      }
    }, () => {
      this.loading = false;
      if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
      this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
    });
  }

  onBtnExportLead() {
    console.log('onBtnExportLead');
    const contactId = this.route.snapshot.params.id;
    this.loading = true;
    this.leadService.apicontactExportMcl(contactId).subscribe((res: any) => {
      console.log('apicontactExportMcl', res.data.contactExportMcl.data.errorCode);
      if (res.data.contactExportMcl.data.errorCode === 1) {
        this.messageService.showSuccess('EXPORT_FAILURE_', 'E_LEAD_001');
        this.getLead(contactId);

      } else {
        this.messageService.showSuccess('EXPORTED_SUCCESS', 'I_LEAD_001');
        this.getLead(contactId);
      }
      this.loading = false; if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
    }, () => {
      this.loading = false;
      if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
      this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
    });
  }

  onBtnSendInfomail() {
    const contactId = this.route.snapshot.params.id;
    this.loading = true;
    this.leadService.apiSendInfomail(contactId).subscribe((res: any) => {
      this.loading = false;
      this.messageService.showSuccess('SEND_INFO_MAIL_SUCCESS', 'I_LEAD_002');
      this.getLead(contactId);
      if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
    }, () => {
      this.loading = false;
      this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
    });

    console.log('this.exportFail', this.exportFail);
  }

  onBtnDeleteLead(lead) {
    const option: any = this.layoutService.optionModal() || { width: '500px' };
    const dialogRef = this.dialog.open(PopupConfirmComponent, {
      ...option, data: {
        title: lead.status !== 'exported' ? 'delete-lead' : 'Sorry',
        description: lead.status !== 'exported' ? 'delete-lead-description' : 'cant-delete-lead-description',
        warning: lead.status !== 'exported' ? 'This step cannot be undone' : '',
        disresponse: lead.status === 'exported',
        param: { firstname: lead.firstName, lastname: lead.lastName }
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loading = true;
        this.leadService.apiDelete({ ids: [lead.id] }).subscribe(({ data }: { data: any }) => {
          this.loading = false;
          if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
          if (data.client_deleteContacts) {
            this.messageService.showSuccess('DELETE_LEAD_SUCCESS', 'I_LEAD_003');
            if (this.type === 'lead') {
              this.router.navigate(['/lead/' + this.group.id]);
            } else {
              this.router.navigate(['/contacts/' + this.group.id]);
            }

          } else {
            this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
          }
        }, error => {
          this.loading = false;
          this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
          if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
        });
      }
    });
  }

  onBtnShowContactDetail() {
    this.showModal(ContactDetailDialogComponent, { ...this.detail, type: this.type }, { width: '720px' }).subscribe((result) => {
      if (result != null) {
        this.detail = { ...this.detail, ...result };
        if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
        this.updateLead();
        localStorage.removeItem('valueScaned');
      }
    });
  }

  onSelectProductChange(consultations) {
    const meta = this.detail.meta;
    meta.isContactDesired = false;
    if (consultations) {
      const newConsultations = {
        ...consultations[0],
        consultationLeads: consultations[0].consultationLeads
          ? consultations[0].consultationLeads.map((item) => {
            if (item.isTelekomFollowUp) {
              meta.isContactDesired = true;
            }
            if (item.product) {
              return {
                ...item,
                product: {
                  id: item.product.id,
                  title: item.product.title,
                },
              };
            } else {
              return item;
            }
          })
          : [],
      };
      this.detail.meta = meta;
      this.detail = { ...this.detail, consultations: [newConsultations] };
    }
    if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
    this.updateLead();
  }

  onBtnShowCustomerConsent() {
    if (this.checkStatus()) {
      this.showModal(CustomerConsentDialogComponent, { ...this.group, type: this.type }, { width: '600px' }).subscribe((result) => {
        if (result != null) {
          this.detail = { ...this.detail, ...result };
          if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
          this.updateLead();
        }
      });
    } else { this.viewAlert(); }
  }


  onBtnShowCustomerNumber() {
    if (this.checkStatus()) {
      this.showModal(CustomerNumberDialogComponent, { ...this.detail, type: this.type }, { width: '600px' }).subscribe((result) => {
        if (result != null) {
          this.detail = { ...this.detail, ...result };
          if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
          this.updateLead();
        }
      });
    } else { this.viewAlert(); }
  }

  onBtnShowDigitalSignature() {
    if (this.checkStatus()) {
      this.showModal(DigitalSignatureDialogComponent,
        { lead: this.detail, type: this.type, group: this.group }, { width: '600px' }).subscribe((result) => {
          if (result != null) {
            this.detail = { ...this.detail, docusignLogs: result.filter(item => item.status === 'freigegeben' || item.status === 'done') };
            console.log('DigitalSignature', this.detail);
            if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
            this.updateLead();
          }
        });
    } else { this.viewAlert(); }
  }

  onBtnShowMakeAppointment() {
    if (this.checkStatus()) {
      this.showModal(MakeAppointmentDialogComponent, { ...this.detail, type: this.type }, { width: '600px' }).subscribe((result) => {
        if (result != null) {
          this.detail = { ...this.detail, ...result };
          this.updateLead();
          this.dateAppointment = moment(result.appointment);
          if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
        }
      });
    } else { this.viewAlert(); }
  }

  onBtnShowCreateProfile() {
    if (this.checkStatus()) {
      this.showModal(CreateProfileDialogComponent,
        { ...this.detail ? this.detail : null, profiling: this.group.profiling || [], type: this.type, partner: this.partner },
        { width: '650px' }).subscribe((result) => {
          if (result != null) {
            this.detail = { ...this.detail, ...result };
            if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
            this.updateLead();
          }
        });
    } else { this.viewAlert(); }
  }

  onBtnShowInfomaterial() {
    if (this.checkStatus()) {
      console.log(this.group);
      const option: any = this.layoutService.optionModal() || { width: '720px' };
      const dialogRef = this.dialog.open(InfomaterialDialogComponent, {
        ...option,
        data: {
          ...this.detail, fileColId: this.group.fileColId,
          myFileAllow: this.group.settingLead.infomaterial.showMyFiles, type: this.type
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result != null) {
          this.detail = { ...this.detail, ...result };
          if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
          this.updateLead();
        }
      });
    } else { this.viewAlert(); }
  }

  onBtnShowvCardScan() {
    if (this.checkStatus()) {
      this.showModal(VcardScanDialogComponent, { ...this.detail, type: this.type },
        { width: '100vw', height: '100vh' }).subscribe((result) => {
          if (result.ok) {
            this.detail = { ...this.detail, ...result.result };
            if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
            this.updateLead();
          }
          if (result.ok === false) {
            this.onBtnShowContactDetail();
          }
        });
    } else { this.viewAlert(); }
  }

  checkDocusign(list) {
    if (!list) { return false; }

    list = list.filter(item => item.status === 'freigegeben' || item.status === 'done');
    return list.length > 0;
  }

  viewDocusign(list) {
    if (!list) { return null; }
    list = list.filter(item => item.status === 'freigegeben' || item.status === 'done');
    const last = list[list.length - 1];
    return Utils.convertStringToSnakeCase(last.docusignTemplateType);
  }

  updateLead(status = null) {
    this.loading = true;
    if (status) {
      this.detail.status = status;
    } else if (!this.detail.status
      || this.detail.status === 'temp') {
      this.detail.status = 'laufend';
    }
    const meta = this.detail.meta;
    if (meta && meta.isContactDesired) {
      meta.isContactDesired = false;
    } else {
      meta.isContactDesired = true;
    }
    this.detail.meta = meta;
    this.leadService.client_upsertContact(this.detail).subscribe(() => {
      this.loading = false;
      this.checkDone();
      if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
    }, () => {
      this.loading = false;
      if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
      this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
    });
  }


  checkUInfo() {
    if (this.detail.firstName &&
      this.detail.lastName &&
      this.detail.company &&
      this.detail.email) {
      return true;
    }
    return false;
  }

  saveTemplate() {
    if (this.checkStatus()) {
      this.showModal(SaveTemplateDialogComponent, null, { width: '445px' }, false).subscribe((re) => {
        if (re) {
          const leadTemplate: any = {};
          const { consultationLeads, files, fileIds } = JSON.parse(JSON.stringify(this.detail.consultations[0]));
          let newConsLead = [...consultationLeads];
          if (newConsLead && newConsLead.length > 0) {
            newConsLead = newConsLead.map(e => {
              delete e.id;
              return e;
            });
          }
          const id = md5(this.group.id);
          leadTemplate['G' + id] = {
            idGroup: this.group.id, consultationLeads: newConsLead, files: files ? files : [],
            fileIds: fileIds ? fileIds : []
          };
          this.authService.update({ settings: { leadTemplate: leadTemplate } }).subscribe(res => {
            const newData = this.dataService.sData;
            newData.user.settings.leadTemplate = leadTemplate;
            this.dataService.updateSData(newData);
            this.checkAllowReset();
            this.messageService.showSuccess('TEMPLATE_SAVED', 'I_LEAD_003');
          }, err => {
            this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
          });
        }
      });
    }

  }

  checkAllowReset() {
    const settings = this.dataService.sData.user.settings;
    const idgroup = md5(this.group.id);
    if (settings && settings.leadTemplate && settings.leadTemplate['G' + idgroup]) {
      this.allowReset = true;
    } else {
      this.allowReset = false;
    }
    if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
  }

  resetLead() {
    if (this.checkStatus()) {
      this.showModal(ResetTemplateDialogComponent, null, { width: '445px' }, false).subscribe((re) => {
        if (re) {
          console.log('resetLead', re);
          const settings = this.dataService.sData.user.settings;
          const idgroup = md5(this.group.id);
          if (settings && settings.leadTemplate && settings.leadTemplate['G' + idgroup]) {
            console.log('settings', settings, settings.leadTemplate['G' + idgroup]);
            const { consultationLeads, files, fileIds } = JSON.parse(JSON.stringify(settings.leadTemplate['G' + idgroup]));
            const { id, status, createdByUserId, createdByOrgId, createdAt, consultations } = this.detail;
            this.detail = {
              id,
              groupId: this.group.id,
              salutation: '',
              title: '',
              status,
              consultations: [
                {
                  ...consultations[0],
                  consultationLeads, files, fileIds
                },
              ],
              createdByUserId,
              createdByOrgId,
              export: {},
              meta: {},
              createdAt,
              updatedAt: new Date(),
            };
            console.log('NEW detail', this.detail);
            this.updateLead();
            if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
          }
        }
      });
    }
  }

  viewDate(mm) {
    if (mm) {
      mm.locale(this.translateService.currentLang);
      return mm.format(this.translateService.instant('FORMATDATE3'));
    }
    return '';
  }
  viewAlert(): void {
    // const dialogRef = this.dialog.open(PopupConfirmComponent, { data
    //   : { disresponse: true, disableX: false, title: "Alert", 
    // description: 'You cant edit information for this Lead', icon: 'assets/icons/icon-close.svg'}});
    // dialogRef.afterClosed().subscribe((e)=>{
    // })
  }
}
