import { environment } from './../../../../../../../environments/environment';
import { FilesPopupComponent } from './../../../../../files/files-popup/files-popup.component';
import { LayoutService } from './../../../../../../shared/services/layout.service';
import { MessageService } from './../../../../../../shared/services/MessageService';
import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import Utils from '../../../../../../shared/utils/utils';
import { LeadService } from 'app/views/lead/lead.service';
const _ = require('lodash')

@Component({
  selector: 'app-view-infomaterial',
  templateUrl: 'view-infomaterial.component.html',
  styleUrls: ['./view-infomaterial.component.scss']
})
export class ViewInfomaterialComponent implements OnInit, OnChanges {
  @Input('type') type: string = 'lead';
  @Input('consultations') consultations: any = {};
  @Input('detail') detail: any = {};
  @Input('required') required: boolean = false;

  @Output() onOpen = new EventEmitter();
  Utils = Utils;
  PERM_MANAGE_CONTACT: string = 'PermManageContact';
  PermManageContact: boolean = false;
  files: any = []
  constructor(
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public messageService: MessageService,
    public leadService: LeadService,
    public layoutService: LayoutService,
  ) {
  }
  ngOnInit() {
    let permission = JSON.parse(localStorage.getItem('permission'));
    this.PermManageContact = permission.includes(this.PERM_MANAGE_CONTACT);
  }

  checkStatus() {
    if (this.type === 'lead') {
      if (this.detail.status === 'freigegeben' || this.detail.status === 'export') {
        return false;
      }
    } else {
      if (this.PermManageContact) {
        return true;
      } else {
        if (this.detail.status === 'export') {
          return false;
        } else {
          return true
        }
      }
    }
    return true
  }

  deleteItem(index) {
    this.files.splice(index, 1);
    this.detail.consultations[0].fileIds = _.keys(_.keyBy(this.files))
    this.detail.consultations[0].files = this.files
    this.leadService.client_upsertContact(this.detail).subscribe(() => {

    }, () => {
      this.messageService.showError('Failure occured', 'E_GENERAL_002')
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.consultations) {
        this.createFiles(changes.consultations.currentValue);
      }

    }
  }

  createFiles(_consultations) {
    const consultations = JSON.parse(JSON.stringify(_consultations));
    if (consultations[0].files && consultations[0].files.length > 0) {
      this.files = consultations[0].files
    }
  }

  showInfomaterial() {
    this.onOpen.emit();
  }

  //onRowActive
  onRowActive(row) {
    //Check ext file
    var fileExt = row.path.split('.').pop();
    if (fileExt == 'pdf' || fileExt == 'mp4' || fileExt == 'jpg' || fileExt == 'png' || fileExt == 'gif') {
      this.dialog.open(FilesPopupComponent, { disableClose: true, width: '70%', height: '90vh', data: { info: row, file: environment.mediaUrl +'/fs/files/' + row.path, ext: fileExt } });
    } else {
      this.downloadFile(environment.mediaUrl +'/fs/files/' + row.path);
    }

  }
  downloadFile(filePath) {
    var link = document.createElement('a');
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    link.click();
  }
  ngAfterViewInit() {

  }


  onSubmit() {
  }

}
