import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MessageService } from 'app/shared/services/MessageService';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import Constants from '../../utils/constants';

@Component({
  selector: 'app-import-popup',
  templateUrl: './import-popup.component.html',
  styleUrls: ['./import-popup.component.scss']
})
export class ImportPopupComponent implements OnInit, OnChanges {
  opened: boolean = true;
  @Input('file') file: string = "";//Link File
  @Input('title') title: string = "";
  @Input('alertsuccess') alertsuccess: string = "";
  @Input('alerterror') alerterror: string = "";
  @Input('label') label: string = "DELETE";
  @Input('description') description: string = "";
  @Input('infofinish') infofinish: any = null;

  @Output() onApply = new EventEmitter();
  uploading = false;
  files: any = [];
  loading = false;
  constructor(
    public messageService: MessageService
  ) { }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.opened = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['infofinish']) {
      if (this.infofinish) {
        this.loading = false;
      }
    }
  }


  runApply(): void {
    if (this.files.length > 0) {
      this.loading = true;
      this.onApply.emit(this.files);
    }
  }
  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          const size = file.size;
          if (size < Constants.MAX_FILE_SIZE) {
            this.files.push(file);
          } else {
            this.messageService.showError('FILE_MAX_SIZE_UPLOAD', 'E_FILE_005')
          }
        });
      }
    }
  }
}
