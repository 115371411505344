import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import Constants from '../../../../../shared/utils/constants'
const _ = require('lodash')
import isEmpty from 'lodash/isEmpty';
import Utils from 'app/shared/utils/utils';
@Component({
  selector: 'app-contact-detail',
  templateUrl: 'contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailDialogComponent implements OnInit {
  form: FormGroup = null;
  type: string = 'lead';
  contact: any = { title: '' }
  loading: any = false
  disabled: boolean = true
  leadDetailsForm: FormGroup
  isDisable: any
  isCanSave = false;
  currentImg: number = 1
  PermManageContact: boolean = false;
  codes = []
  PERM_MANAGE_CONTACT: string = 'PermManageContact';
  isFixed: boolean = false
  bCardFrontFilename: String = null
  bCardBackFilename: String = null
  link: string = environment.mediaUrl + 'fs/files/uploads/';
  tab: string = 'front';
  maxLengthEmail: number;
  Utils = Utils;
  constructor(
    public dialogRef: MatDialogRef<ContactDetailDialogComponent>,
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    private translateService: TranslateService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.type = data.type;
    this.maxLengthEmail = Constants.MAX_LENGTH_INPUT_EMAIL
    this.form = new FormGroup({
      salutation: new FormControl(data ? data.salutation : ""),
      title: new FormControl(data ? data.title : ""),
      firstName: new FormControl(data ? data.firstName : "", Validators.required),
      lastName: new FormControl(data ? data.lastName : "", Validators.required),
      company: new FormControl(data ? data.company : "", Validators.required),
      street: new FormControl(data ? data.street : ""),
      houseNumber: new FormControl(data ? data.houseNumber : ""),
      zip: new FormControl(data ? data.zip : ""),
      city: new FormControl(data ? data.city : ""),
      country: new FormControl(data ? data.country : ""),
      mobile: new FormControl(data ? data.mobile : ""),
      email: new FormControl(data ? data.email : "", [Validators.required, Validators.email]),
      position: new FormControl(data ? data.position : ""),
      fax: new FormControl(data ? data.fax : ""),
      web: new FormControl(data ? data.web : ""),
      department: new FormControl(data ? data.department : ""),
      landline: new FormControl(data ? data.landline : ""),
      
    });
  }
  ngOnInit() {

    this.initCountries()
    this.bCardFrontFilename = this.data ? this.data.bCardFrontFilename : null;
    this.bCardBackFilename = this.data ? this.data.bCardBackFilename : null;
    if (localStorage.getItem('valueScaned')) {
      let valueScaned = JSON.parse(localStorage.getItem('valueScaned'));
      this.bCardFrontFilename = valueScaned.bCardFrontFilename ? valueScaned.bCardFrontFilename : (this.data ? this.data.bCardFrontFilename : null);
      this.bCardBackFilename = valueScaned.bCardBackFilename ? valueScaned.bCardBackFilename : (this.data ? this.data.bCardBackFilename : null);
      console.log(JSON.parse(localStorage.getItem('valueScaned')));
      this.form = new FormGroup({
        salutation: new FormControl(this.data ? this.data.salutation : ""),
        title: new FormControl(this.data ? this.data.title : ""),
        firstName: new FormControl(valueScaned.firstName ? valueScaned.firstName : (this.data ? this.data.firstName : ''), Validators.required),
        lastName: new FormControl(valueScaned.lastName ? valueScaned.lastName : (this.data ? this.data.lastName : ''), Validators.required),
        company: new FormControl(valueScaned.company ? valueScaned.company : (this.data ? this.data.company : ''), Validators.required),
        street: new FormControl(valueScaned.street ? valueScaned.street : (this.data ? this.data.street : '')),
        houseNumber: new FormControl(valueScaned.houseNumber ? valueScaned.houseNumber : (this.data ? this.data.houseNumber : '')),
        zip: new FormControl(valueScaned.zip ? valueScaned.zip : (this.data ? this.data.zip : '')),
        city: new FormControl(valueScaned.city ? valueScaned.city : (this.data ? this.data.city : '')),
        country: new FormControl(valueScaned.country ? valueScaned.country : (this.data ? this.data.country : '')),
        mobile: new FormControl(valueScaned.mobile ? valueScaned.mobile : (this.data ? this.data.mobile : '')),
        email: new FormControl(valueScaned.email ? valueScaned.email : (this.data ? this.data.email : ''), Validators.required),
        position: new FormControl(this.data ? this.data.position : ""),
        fax: new FormControl(valueScaned.fax ? valueScaned.fax : (this.data ? this.data.fax : '')),
        web: new FormControl(valueScaned.web ? valueScaned.web : (this.data ? this.data.web : '')),
        department: new FormControl(this.data ? this.data.department : ""),
        landline: new FormControl(valueScaned.landline ? valueScaned.landline : (this.data ? this.data.landline : '')),
      });
    }

    let permission = JSON.parse(localStorage.getItem('permission'));
    this.PermManageContact = permission.includes(this.PERM_MANAGE_CONTACT);
  }

  changeTab() {
    if (this.tab === 'front') {
      this.tab = 'back'
    } else {
      this.tab = 'front'
    }
  }

  checkStatus() {
    if (this.type === 'lead') {
      if (this.data.status === 'freigegeben' || this.data.status === 'export') {
        return false;
      }
    } else {
      if (this.PermManageContact) {
        return true;
      } else {
        if (this.data.status === 'export') {
          return false;
        } else {
          return true
        }
      }

    }
    return true
  }

  initCountries() {
    const countries = this.translateService.instant('COUNTRY_CODE');
    this.codes = _.sortBy(_.entries(countries).map(([key, value]) => ({ key, value })), o => o.value);
    
    // this.countries = this.translateService.instant('country-code');
    // this.codes = Object.keys(this.countries);
  }

  onIsFixed() {
    this.isFixed = !this.isFixed;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  onClickCancel() {
    this.dialogRef.close();
    localStorage.removeItem('valueScaned');
  }

  onChangeImage(index) {
    this.currentImg = index
  }


  ngAfterViewInit() {

  }


  onSubmit() {
    console.log('this.form', this.form.value);
    this.dialogRef.close({
      ...this.form.value,
      bCardFrontFilename: this.bCardFrontFilename ? this.bCardFrontFilename : (this.data ? this.data.bCardFrontFilename : ''),
      bCardBackFilename: this.bCardBackFilename ? this.bCardBackFilename : (this.data ? this.data.bCardBackFilename : ''),
    });
  }
}
