import { element } from 'protractor';
import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { environment } from './../../../../environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { PopupConfirmAddUserComponent } from './popup-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from './../../services/MessageService';
import { PartnerService } from '../../../views/partner/partner.service';
const _ = require('lodash');

@Component({
  selector: 'add-user-item',
  templateUrl: 'add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
  list: any = [];
  loading = false;
  isApiSuccess = false;
  isApiNoResult = true;
  userCurrent: any = null;
  choice: any = {};
  link: string = environment.mediaUrl + 'fs/files/uploads/';
  params: any = {
    id: null,
    orderBy: 'updatedAt',
    orderDir: 'ASC',
    searchText: '',
    filter: 'ONLY_PARTNER,HAS_NO_ORG',
    page: 1,
    pageSize: 25,
  };
  total: number = 0;
  @ViewChild('searchTextInput', { static: false }) searchTextInput: any;
  constructor(
    public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    myElement: ElementRef,
    public translateService: TranslateService,
    private userService: UserService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private fb: FormBuilder,
    private partnerService: PartnerService,
    public messageService: MessageService,
  ) {
    this.params.id = data.PartnerId;
    this.choice = _.keyBy(this.data.admins, 'id') || [];
  }

  ngOnInit() {
    this.apiSearch();
  }

  onChangeSearch(e) {
    this.params.searchText = e.searchText.trim();
    this.apiSearch();
  }

  userFilter() {
    const ids = this.data.usersCurrent.map((item) => item.id);
    return this.list.filter((item) => !ids.includes(item.id));
  }

  apiSearch() {
    this.loading = true;
    this.userService.adOrgListUserForAdding(this.params).subscribe(
      ({ data }) => {
        let apiRes: any = data;
        if (apiRes.adOrgListUserForAdding.entities) {
          this.list = apiRes.adOrgListUserForAdding.entities;
          this.total = apiRes.adOrgListUserForAdding.total;
        } else {
          this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
        }
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
      },
    );
  }

  onPage(page) {
    this.params.page = page;
    this.apiSearch();
  }

  totalPage() {
    const totalPage = Math.ceil(this.total / this.params.pageSize);
    return new Array(totalPage).fill(1);
  }

  onChooseUser(user) {
    const dialogRef = this.dialog.open(PopupConfirmAddUserComponent, {
      width: '500px',
      data: { user: user },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.userCurrent = user;
        this.partnerService
          .adOrgAddUser({
            id: this.data.PartnerId,
            userId: user.id,
          })
          .subscribe(
            ({ data }) => {
              let apiRes: any = data;
              if (apiRes.adOrgAddUser) {
                this.messageService.showSuccess('User added', 'I_GENERAL_004');
                this.dialogRef.close('OK');
              }
            },
            () => {
              this.messageService.showError('Failure occured', 'E_GENERAL_002');
            },
          );
      }
    });
  }

  onClickCancel() {
    this.dialogRef.close();
  }
}
