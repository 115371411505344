import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RelativeTimePipe } from './relative-time.pipe';
import { ExcerptPipe } from "./excerpt.pipe";
import { GetValueByKeyPipe } from './get-value-by-key.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';
import { LocalizedAmTimeAgoPipe } from './localized-am-time-ago.pipe';
import { HighlightPipe } from './highlight-text.pipe';
import { HighlightSearch } from './highlight.pipe';

const pipes = [
  RelativeTimePipe,
  ExcerptPipe,
  GetValueByKeyPipe,
  LocalizedDatePipe,
  LocalizedAmTimeAgoPipe,
  HighlightPipe,
  HighlightSearch,
]

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: pipes,
  exports: pipes
})
export class SharedPipesModule {}