import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, } from '@angular/core';


@Component({
  selector: 'app-confirm-csv',
  templateUrl: './confirm-csv.component.html',
  styleUrls: ['./confirm-csv.component.scss']
})
export class ConfirmCsvComponent implements OnInit {
  checked:boolean = false
  constructor(
    public dialogRef: MatDialogRef<ConfirmCsvComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }
  ngOnInit() {
  }

  onClickCancel() {
    this.dialogRef.close();
  }


  onSubmit() {
    this.dialogRef.close({ok:true});
  }

}
