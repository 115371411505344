import { TranslateService } from '@ngx-translate/core';
import {
  MatSnackBar,
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import {
  Injectable,
  ChangeDetectorRef,
  Component,
  Inject,
} from '@angular/core';
import { Subject } from 'rxjs';
import Utils from 'app/shared/utils/utils';

@Component({
  selector: 'snack-bar',
  template: `
    <div [attr.data-testid]="data.id" class="{{ data.class }}">
      {{ Utils.convertStringToSnakeCase(data.message)  | translate }}
      <button
        class="close"
        (click)="snackBarRef.dismiss()"
        [attr.data-testid]="'btn__close--' + data.id"
      >
        {{ Utils.convertStringToSnakeCase(data.close) | translate }}
      </button>
    </div>
  `,
})
export class ErrorComponent {
  Utils = Utils;
  constructor(
    public snackBarRef: MatSnackBarRef<ErrorComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  public configObservable = new Subject<number>();
  public dataObservable = new Subject<any>();
  public generalObservable = new Subject<any>();
  // user detail
  public isBothAuthorAndPublisher = false;
  public pageName = '';
  public isCanSave: Boolean = false;
  public isReviewActive = false;
  public selectActive = false;
  public isInReviewActive = false;
  public isReviewRejected = false;
  public isPublished = false;
  public isPresentative = false;
  public isBypassReview = false;
  public isAuthorOrEditor = false;
  public recordChoice = '';
  public actions: any = null;
  public component: any = null;
  public buttonLabel = '';
  public isDisable = false;
  public isCheck = false;
  public allowDashBoardEdit: Boolean = false;
  public arrayButton: any = [];

  constructor(
    public snackBar: MatSnackBar,
    public translateService: TranslateService,
  ) {}

  reset() {
    this.pageName = '';
    this.recordChoice = '';
    this.selectActive = false;
    this.isPublished = false;
    this.isReviewActive = false;
    this.isInReviewActive = false;
    this.isReviewRejected = false;
    this.isCheck = false;
    this.isAuthorOrEditor = false;
    this.isBypassReview = false;
    this.isBothAuthorAndPublisher = false;
    this.actions = null;
    this.compConfig(null);
    this.changeData(this);
  }

  updateActive(stt: boolean = null) {
    if (stt === null) {
      this.selectActive = !this.selectActive;
    } else {
      this.selectActive = stt;
    }
    if (!this.selectActive) { this.updateCount(''); }
    this.changeData(this);
  }

  updateDisable(stt: boolean) {
    this.isDisable = stt;
  }

  upDatePublishStatus(isPublished: boolean): void {
    this.isPublished = isPublished;
    this.changeData(this);
  }

  updateReviewStatus(isReview: boolean, isInReview: boolean, isRejected: boolean): void {
    this.isReviewActive = isReview;
    this.isInReviewActive = isInReview;
    this.isReviewRejected = isRejected;
    this.changeData(this);
  }

  updateCount(number: string) {
    this.recordChoice = number;
    this.changeData(this);
  }

  updateBypassReview(isBypass: boolean) {
    this.isBypassReview = isBypass;
    console.log(isBypass);
    this.changeData(this);
  }

  registryButton(buttons: any): void {
    this.arrayButton = buttons;
    this.changeData(this);
  }

  registryAction(myaction: any): void {
    this.actions = myaction;
  }

  setPageName(page: string, label: string = '') {
    this.pageName = page;
    this.buttonLabel = label;
    this.changeData(this);
  }

  setButtonLabel(label: string = '') {
    this.buttonLabel = label;
    this.changeData(this);
  }

  compConfig(val: any) {
    this.configObservable.next(val);
  }

  changeData(val) {
    this.dataObservable.next(val);
  }

  setIsCanSave(val: Boolean) {
    this.isCanSave = val;
    this.changeData(this);
  }

  setAllowDashboardEdit(val: Boolean) {
    this.allowDashBoardEdit = val;
    this.dataObservable.next(val);
  }

  generalData(val) {
    this.generalObservable.next(val);
  }

  showSuccess(message: string, id?: string) {
    this.snackBar.openFromComponent(ErrorComponent, {
      panelClass: ['success-snackbar'],
      duration: 5000,
      data: { message, close: 'Close', class: 'snackbar', id },
    });
  }

  showError(message: string, id?: string) {
    this.snackBar.openFromComponent(ErrorComponent, {
      panelClass: ['error-snackbar'],
      duration: 5000,
      data: { message, close: 'Close', class: 'snackbar', id },
    });
  }

  hasbothPublishAndAuthor(isBothAuthorAndPublishPerm: boolean) {
    this.isBothAuthorAndPublisher = isBothAuthorAndPublishPerm;
  }

  updateCheck(check: boolean) {
    this.isCheck = check;
  }

  isInPresentative(isPresentative: boolean) {
    this.isPresentative = isPresentative;
  }

  hasAuthorOrEditorArticle(isAuthor: boolean) {
    this.isAuthorOrEditor = isAuthor;
  }

}

