import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { environment } from 'environments/environment';
import gql from 'graphql-tag';
import { DataService } from '../../shared/services/data.service';
import Utils from '../../shared/utils/utils';

@Injectable()
export class PartnerService {
 
  constructor(private apollo: Apollo, private dataService: DataService) {}
  apiGets(params) {
    this.dataService.setParams('partner', params);
    return this.apollo.query({
      query: gql`query {
        adOrgs(${Utils.params2GqlStr(params)}) {
          entities {
            id
            companyName
            type
            useSideAgreement
            isNeutral
            region
            bpvNo
            status
            updatedAt
            childrenCount
          }
          total
    }}`,
      fetchPolicy: 'no-cache',
    });
  }
  apiDetail(params) {
    return this.apollo.query({
      query: gql`
        query adOrg($id: ID!) {
          adOrg(id: $id) {
            id
            companyName
            type
            useSideAgreement
            isNeutral
            subPartnerNo
            bpvNo
            vpnrNo
            voNo
            status
            meta
            updatedAt
            region
            parentId
            groups {id title}
            repUsers {
              id
              firstName
              lastName
            }
          }
        }
      `,
      variables: params,
    });
  }

  apiDetailParent(params) {
    return this.apollo.query({
      query: gql`
        query adOrg($id: ID!) {
          adOrg(id: $id) {
            id
            companyName
          }
        }
      `,
      variables: params,
    });
  }

  apiGetUsers(params) {
    return this.apollo.query({
      query: gql`query {
        adOrgUsers(${Utils.params2GqlStr(params)}) {
          entities {
            id
            firstName
            lastName
            mobileNumber
            email
            status
            role
            avatarImg
            partnerCompanyName
          }
          total
          meta
    }}`,
      fetchPolicy: 'no-cache',
    });
  }

  apiGetSubs(params) {
    return this.apollo.query({
      query: gql`query {
        adOrgSubOrgs(${Utils.params2GqlStr(params)}) {
          entities {
            id
            companyName
            useSideAgreement
            isNeutral
            bpvNo
            vpnrNo
            voNo
            region
            subPartnerNo
            updatedAt
        }
        total
    }}`,
      fetchPolicy: 'no-cache',
    });
  }

  apiDelete(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
        adOrgDelete(${Utils.params2GqlStr(params)})
    }`,
    });
  }

  apiDeleteUserFromOrg(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
      adOrgRemoveUsers(${Utils.params2GqlStr(params)})
    }`,
    });
  }

  apiSetAdminUserOrg(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
      adOrgSetAdminUser(${Utils.params2GqlStr(params)})
    }`,
    });
  }

  adOrgAddUser(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
      adOrgAddUser(${Utils.params2GqlStr(params)})
    }`,
    });
  }

  adOrgAddUserByEmail(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
      adOrgAddUserByEmail(${Utils.params2GqlStr(params)}){
        data
      }
    }`,
    });
  }

  adOrgCreate(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
      adOrgCreate(${Utils.params2GqlStr(params)}){
        id
      }
    }`,
    });
  }

  adOrgDelete(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
      adOrgDelete(${Utils.params2GqlStr(params)})
    }`,
    });
  }

  adOrgUpdate(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
      adOrgUpdate(${Utils.params2GqlStr(params)})
    }`,
    });
  }

  apiCreate(params) {
    return this.apollo.mutate({
      mutation: gql`
        mutation adOrgCreate(
          $companyName: String!
          $type: Int
          $meta: JSON
          $bpvNo: String
          $vpnrNo: String
          $voNo: String
          $region: String
          $parentId: ID
        ) {
          adOrgCreate(
            companyName: $companyName
            bpvNo: $bpvNo
            type: $type
            meta: $meta
            vpnrNo: $vpnrNo
            voNo: $voNo
            region: $region
            parentId: $parentId
          ) {
            id
            companyName
          }
        }
      `,
      variables: params,
    });
  }

  adOrgUsersImportCsv(orgId,fileUpload) {
    return this.apollo.mutate({
      mutation: gql`mutation($orgId: ID!,$fileUpload: Upload) {
        adOrgUsersImportCsv(orgId: $orgId,fileUpload: $fileUpload) {
      data
    }}`,
      variables: { orgId, fileUpload },
      context: { useMultipart: true },
    });
  }

}
