import { DataService } from 'app/shared/services/data.service';
import {
  Component,
  OnInit,
} from '@angular/core';
import { MessageService } from 'app/shared/services/MessageService';


@Component({
  selector: 'user-list-headbar',
  templateUrl: './user-list-headerbar.template.html'
})
export class UserListHeaderbar implements OnInit {
  
  constructor(  public dataService: DataService,public messageService: MessageService) {

   
  }
  ngOnInit(): void {
  }

  
}
