import { environment } from 'environments/environment';
import { Component, OnInit, Input, OnDestroy, Renderer2, ChangeDetectorRef } from '@angular/core';
import { NavigationService } from '../../../shared/services/navigation.service';
import { Subscription } from 'rxjs';
import { ThemeService } from '../../../shared/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../services/layout.service';
import Utils from 'app/shared/utils/utils';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html'
})
export class HeaderTopComponent implements OnInit, OnDestroy {
  mktUrl= environment.mktUrl;
  title: string = environment.title;
  layoutConf: any;
  menuItems: any;
  menuItemSub: Subscription;
  mdaThemes: any[] = [];
  currentLang = environment.language;
  availableLangs = [{
    name: 'English',
    code: 'en',
  }, {
    name: 'Deutsch',
    code: 'de',
  }];
  Utils = Utils;

  @Input() notificPanel;
  constructor(
    private layout: LayoutService,
    private navService: NavigationService,
    public themeService: ThemeService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
  ) { }
  ngAfterViewInit(): void {
    // console.log('[ScrollTopComponent] ngAfterViewInit');
    setTimeout(() => {
      document.querySelector('.main-content-wrap').addEventListener('scroll', this.screenScroll, false);
    }, 1000);
  }

  screenScroll(ev) {
    // console.log('ev:', ev.target.scrollTop);
    const elHeaderTopnav: any = document.querySelector('.header-topnav');
    if (ev.target.scrollTop > 0) {
      if (elHeaderTopnav) elHeaderTopnav.style.top = '0px';
    } else {
      if (elHeaderTopnav) elHeaderTopnav.style.top = '42px';
    }
  }
  ngOnInit() {
    this.layoutConf = this.layout.layoutConf;
    this.mdaThemes = this.themeService.mdaThemes;
    this.menuItemSub = this.navService.menuItems$
      .subscribe(res => {
        res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
        const limit = 4;
        const mainItems: any[] = res.slice(0, limit);
        if (res.length <= limit) {
          return this.menuItems = mainItems;
        }
        const subItems: any[] = res.slice(limit, res.length - 1);
        mainItems.push({
          name: 'More',
          type: 'dropDown',
          tooltip: 'More',
          icon: 'more_horiz',
          sub: subItems
        });
        this.menuItems = mainItems;
      });
  }
  ngOnDestroy() {
    this.cdr.detach();
    this.menuItemSub.unsubscribe();
    console.log('[ScrollTopComponent] ngOnDestroy');
    document.querySelector('.main-content-wrap').removeEventListener('scroll', this.screenScroll, false);
  }
  setLang() {
    console.log('setLang this.currentLang:', this.currentLang);
    this.translate.use(this.currentLang);
    // setTimeout(() => {
    //   this.translate.use(this.currentLang);
    //    if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
    // }, 200);

  }
  changeTheme(theme) {
    this.layout.publishLayoutChange({ matTheme: theme.name });
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    });
  }
}
