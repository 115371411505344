import { Routes } from '@angular/router';
import { environment } from 'environments/environment';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { MainComponent } from './shared/components/layouts/main/main.component';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { JWTComponent } from './views/jwt/index.component';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'account',
    pathMatch: 'full'
  },
  {
    path: 'jwt',
    component: JWTComponent
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'outs',
        loadChildren: () => import('./views/outs/outs.module').then(m => m.OutsModule),
        data: { title: 'Out' }
      }
    ]
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'items',
        loadChildren: () => import('./views/item/item.module').then(m => m.ItemModule),
        data: { title: 'Items', breadcrumb: 'Items' }
      },
      {
        path: environment.groupType === 'lda'?'squads':'partner',
        loadChildren: () => import('./views/partner/partner.module').then(m => m.PartnerModule),
        data: { title:  environment.groupType === 'lda'?'Squads':'Partner', breadcrumb: environment.groupType === 'lda'?'Squads':'Partner' }
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
        data: { title: 'Profile', breadcrumb: 'Profile' }
      },
      {
        path: 'team',
        loadChildren: () => import('./views/team/team.module').then(m => m.TeamModule),
        data: { title: 'Team', breadcrumb: 'Team' }
      },
      {
        path: 'lead',
        loadChildren: () => import('./views/lead/lead.module').then(m => m.LeadModule),
        data: { title: 'My Leads', breadcrumb: 'Lead' }
      },
      {
        path: 'channel',
        loadChildren: () => import('./views/group/group.module').then(m => m.GroupModule),
        data: { title: 'Channel', breadcrumb: 'Channel' }
      },
      {
        path: 'xfiles',
        loadChildren: () => import('./views/file/file.module').then(m => m.FileModule),
        data: { title: 'Files', breadcrumb: 'Files' }
      },
      {
        path: 'files',
        loadChildren: () => import('./views/files/files.module').then(m => m.FilesModule),
        data: { title: 'Files', breadcrumb: 'Files' }
      },
      {
        path: 'contacts',
        loadChildren: () => import('./views/contact/contact.module').then(m => m.ContactModule),
        data: { title: 'All Leads', breadcrumb: 'Contacts' }
      },
      {
        path: 'orders',
        loadChildren: () => import('./views/orders/order.module').then(m => m.OrderModule),
        data: { title: 'Orders', breadcrumb: 'Orders' }
      },
      {
        path: 'customers',
        loadChildren: () => import('./views/customers/customers.module').then(m => m.CustomerModule),
        data: { title: 'Customers', breadcrumb: 'Customers' }
      },
      {
        path: 'users',
        loadChildren: () => import('./views/user/user.module').then(m => m.UserModule),
        data: { title: 'Users', breadcrumb: 'Users' }
      },
      {
        path: 'account',
        loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule),
        data: { title: 'Accounts', breadcrumb: 'Accounts' }
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Dashboard', breadcrumb: 'Dashboard' }
      },
      {
        path: 'setting',
        loadChildren: () => import('./views/setting/setting.module').then(m => m.SettingModule),
        data: { title: 'Setting', breadcrumb: 'Setting' }
      },
      {
        path: 'portfolio',
        loadChildren: () => import('./views/portfolio/portfolio.module').then(m => m.PortfolioModule),
        data: { title: 'Portfolio', breadcrumb: 'Portfolio' }
      },
      {
        path: 'support',
        loadChildren: () => import('./views/support/support.module').then(m => m.SupportModule),
        data: { title: 'Support', breadcrumb: 'Support', type: 'support'}
      },
      {
        path: 'solution',
        loadChildren: () => import('./views/support/support.module').then(m => m.SupportModule),
        data: { title: 'Solution', breadcrumb: 'Solution', type: 'solution' }
      },
      {
        path: 'page',
        loadChildren: () => import('./views/support/support.module').then(m => m.SupportModule),
        data: { title: 'Pages', breadcrumb: 'Pages', type: 'page' }
      },
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
        data: { title: 'Others', breadcrumb: 'Others' }
      },
      {
        path: 'news',
        loadChildren: () => import('./views/news/news.module').then(m => m.NewsModule),
        data: { title: 'News', breadcrumb: 'News' }
      },
      {
        path: 'tasklist',
        loadChildren: () => import('./views/tasklist/tasklist.module').then(m => m.TaskListModule),
        data: { title: 'Task List', breadcrumb: 'Task List' }
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'outs/404'
  }
];

