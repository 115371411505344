import { environment } from '../../../environments/environment';

export default class Constants {
  static tenantId = '4C1A8410-3D22-11EA-8616-8519666E76E8';
  static secretKey = 'Ej2h338dnnfEd';
  static API_TIMEOUT = 5 * 60 * 1000; // 5 minutes
  static SESSION_ID = '1'; // TOFIX
  static USER_ID = '1';
  static DEVICE_ID = '1';
  static NE_PROTOCOLS = [
    { protocol: 'rdp', port: 3389 },
    { protocol: 'ssh', port: 22 },
    { protocol: 'scp', port: 22 },
    { protocol: 'x2go', port: 22 },
    { protocol: 'nx', port: 22 },
    { protocol: 'telnet', port: 23 },
  ];

  static WIDGET_TYPE = {
    TITLE: 'title',
    HTML: 'html',
    HIGHLIGHT: 'highlight',
  };

  static MODE_EDIT = {
    DRAG_DROP: 'DRAG_DROP',
    COLLAPSE: 'COLLAPSE',
  };

  static MODE_VIEW = {
    ADMIN: 'EDIT',
    END_USER: 'END_USER',
  };

  static REDUCER_TYPE = {
    INIT: 'INIT',
    UPDATE_VALUE: 'UPDATE_VALUE',
    UPDATE_STYLE: 'UPDATE_STYLE',
    UPDATE_ORDER: 'UPDATE_ORDER',
    ADD_WIDGET: 'ADD_WIDGET',
    ACTIVE_SECTION: 'ACTIVE_SECTION',
  };

  static PORTAL_URL = environment.webPortalUrl;
  static GROUP_URL = environment.webPortalUrl.replace('https://', 'https://{slug}.');
  static LOGIN_STATUS = [
    { id: 'E_LOGIN_001', message: 'LOGIN_USER_SUSPENDED' },
    { id: 'E_LOGIN_002', message: 'LOGIN_USER_LOCKED' },
    { id: 'E_LOGIN_003', message: 'USER_NOT_FOUND' },
  ];

  static MAX_SIZE_AVATAR = 10 * 1000 * 1000; // 10mb
  static API_KEY_CONVERTAPI = 'uVzbYNrzQdAlXr7G';
  static MAX_LENGTH_INPUT = 255;
  static MAX_LENGTH_INPUT_EMAIL = 75;
  static MAX_FILE_SIZE = 50 * 1000 * 1000; // 50mb
}
