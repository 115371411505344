import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'app/shared/services/data.service';
import { LayoutService } from 'app/shared/services/layout.service';
import { MessageService } from 'app/shared/services/MessageService';
import Constants from 'app/shared/utils/constants';
import Utils from 'app/shared/utils/utils';
import { environment } from 'environments/environment';
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  NgxFileDropEntry,
} from 'ngx-file-drop';
import { ParentComponent } from './../../parent.component';
import { PopupConfirmComponent } from './../../shared/components/popup-confirm/popup-confirm.component';
import { CollectionAddDialogComponent } from './collection-add/collection-add-dialog.component';
import { FilesListHeaderbar } from './files-list-headerbar/files-list-headerbar.component';
import { FilesPopupComponent } from './files-popup/files-popup.component';
import { FilesService } from './files.service';
import { MyfileAlertDialogComponent } from './myfile-alert/myfile-alert-dialog.component';
import { filesActionEnum } from './constants';
import { HttpClient } from '@angular/common/http';
const JSURL = require('jsurl2');
const _ = require('lodash');

@Component({
  selector: 'app-file',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
})
export class FilesComponent extends ParentComponent implements OnInit {
  @Input() page: any = null;
  @Output() onBreadcrumbs = new EventEmitter();
  queryParams: any = null;
  params: any = {
    orderBy: 'updatedAt',
    orderDir: 'DESC',
    page: 1,
    pageSize: 25,
    searchText: '',
    filter: '',
  };
  loading = false;
  uploading = false;
  parent: any = null;
  apiname: any = null;
  nameres = 'client_fileCols';
  Utils = Utils;
  choice: any = {};
  listChoice: any = [];
  viewType = 0;
  breadcrumbs: any = [];
  userlogin: any = null;
  parentId: string = null;
  isCenter: boolean = null;
  showControl = true;
  allowMulti = true;
  constructor(
    public breakpointObserver: BreakpointObserver,
    public cdr: ChangeDetectorRef,
    public route: ActivatedRoute,
    public router: Router,
    public filesService: FilesService,
    public dataService: DataService,
    public translateService: TranslateService,
    public messageService: MessageService,
    public layoutService: LayoutService,
    public location: Location,
    public http: HttpClient,
    public dialog: MatDialog,
  ) {
    super(breakpointObserver, layoutService, dialog);
    this.userlogin = dataService.getSData().user;
  }

  ngOnInit() {
    if (this.page === null) {
      const params = JSURL.parse(this.route.snapshot.queryParamMap.get('s'));
      if (params) {
        this.params = { ...this.params, ...params };
      }
      this.route.params.subscribe((val) => {
        this.parentId = this.route.snapshot.params.parentId || null;
        if (this.parentId && this.parentId !== 'all') {
          this.getParent(this.parentId);
        } else {
          this.parent = null;
          this.parentId = null;
          this.connectApi();
        }
      });
    } else {
      this.route.queryParams.subscribe((val) => {
        const iddir = this.route.snapshot.queryParams.iddir || null;
        if (iddir) {
          this.showControl = true;
          this.parentId = iddir;
          this.getParent(this.parentId);
        } else {
          this.showControl = false;
          this.parentId = this.page.downloadDirId;
          this.getParent(this.parentId);
        }
      });
    }
    this.rowFilter = this.rowFilter.bind(this);
  }

  getParent(parentId) {
    this.loading = true;
    this.filesService.apiGetFile(parentId).subscribe(
      ({ data }) => {
        this.loading = false;
        const apiRes: any = data;
        if (apiRes && apiRes.file) {
          this.parent = apiRes.file;
          this.connectApi();
        } else {
          this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
        }
        if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
      },
      () => {
        this.loading = false;
        if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
        this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
      },
    );
  }

  onSelectChange(e, isclose) {
    this.listChoice = e;
    if (
      this.dataService.checkRole('PermAllCollections') ||
      this.dataService.checkRole('PermMyCollection')
    ) {
      this.messageService.updateCount(
        e.length
          ? e.length +
          (e.length > 1
            ? ' ' +
            this.translateService.instant(
              !this.parent ? 'COLLECTIONS' : 'FILES',
            )
            : ' ' +
            this.translateService.instant(
              !this.parent ? 'COLLECTION' : 'FILE',
            )) +
          ' ' +
          this.translateService.instant('SELECTED')
          : '',
      );
      if (isclose) {
        this.messageService.updateActive(false);
      }
      this.messageService.registryAction({
        delete: () => {
          const check = _.find(
            this.listChoice,
            (o) => o.title.trim().indexOf('USER_') === 0,
          );
          if (check) {
            const option: any = this.layoutService.optionModal() || {
              width: '380px',
            };
            this.dialog.open(MyfileAlertDialogComponent, { ...option });
          } else {
            const ids = _.map(this.listChoice, 'id');
            this.doDelete(ids);
          }
        },
        close: () => {
          this.onSelectChange([], true);
        },
      });
    }
    if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
  }

  ngAfterViewInit() {
    if (this.page === null) {
      this.messageService.compConfig(FilesListHeaderbar);
    }
  }

  createHeader() {
    let check = 0;
    if (this.parent === null) {
      if (this.dataService.checkRole('PermAllCollections')) {
        check = 1;
      }
    } else {
      if (this.parent.title.trim().indexOf('ROOT') === 0) {
        this.parent.title = this.parent.title.replace(/^\ROOT/, '').trim();
      }
      if (
        this.parent.title.trim() === 'USER_' + this.userlogin.userId &&
        this.dataService.checkRole('PermMyCollection')
      ) {
        check = 2;
      } else if (this.dataService.checkRole('PermFileWrite')) {
        check = 3;
      }
    }
    if (this.page === null && check > 0) {
      this.messageService.setPageName('UserList');
    }
  }

  ngOnDestroy(): void {
    if (this.page === null) {
      this.messageService.reset();
    }
  }
  changeViewType(_type) {
    this.viewType = _type;
  }
  onChangeSearch(e) {
    this.params.searchText = e.searchText.trim();
    this.params.page = 1;
    this.connectApi();
  }

  onSizeChange(e) {
    this.params.pageSize = e;
    this.connectApi();
  }

  onPageChange(e) {
    this.params.page = e;
    this.connectApi();
  }

  onOrderChange(e) {
    this.params.orderBy = e.name;
    this.params.orderDir = e.type;
    this.connectApi();
  }

  connectApi() {
    this.createHeader();
    if (this.parent) {
      this.nameres = 'files';
      this.apiname = this.filesService.apiGetFiles({
        ...this.params,
        pageSize: 0,
        parentId: this.parent.id,
      });
    } else {
      this.nameres = 'client_fileCols';
      this.apiname = this.filesService.apiGetClientFileCols(this.params);
    }

    if (this.page === null) {
      if (!this.parentId) {
        this.location.go('/files/collection?s=' + JSURL.stringify(this.params));
      } else {
        this.location.go(
          '/files/collection/' +
          this.parentId +
          '?s=' +
          JSURL.stringify(this.params),
        );
      }
    }
  }

  onGetData(data) {
    this.breadcrumbs = data.breadcrumbs;
    if (this.page !== null) {
      const bcpage = [];
      this.breadcrumbs.map((item) => {
        if (bcpage.length) {
          bcpage.push({ ...item, active: true });
        } else if (this.page.downloadDirId === item.id) {
          bcpage.push({ ...item, active: false });
        }
        return item;
      });
      this.onBreadcrumbs.emit(bcpage);
    }

    if (data.entities && data.entities.length === 0) {
      this.isCenter = true;
    } else {
      this.isCenter = false;
    }
  }

  checkName(title) {
    if (title.trim().indexOf('ROOT') === 0) {
      title = title.replace(/^\ROOT/, '').trim();
    }

    if (title.trim() === 'USER_' + this.userlogin.userId) {
      return this.translateService.instant('My Files');
    }

    return title.lastIndexOf('.') === -1 ? title : title.slice(0, title.lastIndexOf('.'));
  }

  checkPemAction(row: any = null, notdelrn: boolean = true) {
    if (this.parent !== null) {
      row = this.parent;
    }
    if (row) {
      // My file - collection of system
      if (
        row.title.trim() === 'USER_' + this.userlogin.userId
      ) {
        if (notdelrn === false && this.parent === null) { return false; }
        if (this.dataService.checkRole('PermMyCollection')) {
          return true;
        }
      } else if (row.title.trim().startsWith('My files')) {
        return false;
      } else {
        // collection
        if (row.__typename && row.__typename.includes('FileCol')) {
          if (this.dataService.checkRole('PermAllCollections')) { return true; } else { return false; }
        } else if (row.isDir) {
          if (this.dataService.checkRole('PermFileWrite')) {
            return true;
          }
        } else {
          if (this.dataService.checkRole('PermFileWrite')) {
            return true;
          }
        }
      }
    } else {
      if (this.dataService.checkRole('PermAllCollections')) {
        return true;
      }
    }
    return false;
  }

  onRowActive(row) {
    this.viewType = 0;
    if (this.parent === null) {
      this.params = {
        orderBy: 'updatedAt',
        orderDir: 'DESC',
        page: 1,
        pageSize: 25,
        searchText: '',
        filter: '',
      };
      this.router.navigate(['/files/collection/' + row.rootFileId], {
        queryParams: { wrp: row.userHasWritePerm },
      });
    } else {
      if (row.isDir) {
        if (this.page === null) {
          this.router.navigate(['/files/collection/' + row.id], {
            queryParams: { wrp: row.userHasWritePerm },
          });
        } else {
          this.router.navigate(['/items/detail/' + this.page.id], {
            queryParams: {
              s: this.route.snapshot.queryParamMap.get('s'),
              iddir: row.id,
            },
          });
        }
      } else {
        // Check ext file
        const fileExt = row.path.split('.').pop().toLowerCase();
        const listAllowFileExtension = ['pdf', 'mp4', 'jpg', 'jpeg', 'png', 'gif', 'jfif'];
        if (
          listAllowFileExtension.includes(fileExt)
        ) {
          this.showModal(
            FilesPopupComponent,
            {
              info: row,
              file: environment.mediaUrl + row.path,
              ext: fileExt,
            },
            { width: '70%' },
          ).subscribe(() => { });
        } else {
          this.downloadFile(environment.mediaUrl + row.path);
        }
      }
    }
  }

  downloadFile(filePath) {
    const link = document.createElement('a');
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    link.click();
  }

  // Xử lý xóa
  doDelete(ids) {
    const option: any = this.layoutService.optionModal() || { width: '400px' };
    const dialogRef = this.dialog.open(PopupConfirmComponent, {
      ...option,
      data: {
        title: !this.parent ? 'Delete Collection' : 'Delete File',
        description: !this.parent
          ? 'delete-collection-description'
          : 'DELETE_FILE_DESCRIPTION_FILE',
        danger: 'This step cannot be undone',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loading = true;
        if (!this.parent) {
          this.filesService.fileColDelete({ ids }).subscribe(
            () => {
              this.connectApi();
              this.loading = false;
              this.onSelectChange([], true);
              if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
              this.messageService.showSuccess(
                'COLLECTION_DELETED',
                'I_FILE_001',
              );
            },
            () => {
              this.loading = false;
              this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
              if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
            },
          );
        } else {
          this.filesService.apiFilesDelete({ ids }).subscribe(
            () => {
              this.connectApi();
              this.loading = false;
              this.onSelectChange([], true);
              if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
              this.messageService.showSuccess('FILE_DELETED', 'I_FILE_007');
            },
            () => {
              this.loading = false;
              this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
              if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
            },
          );
        }
      }
    });
  }
  // Bộ lọc dữ liệu
  rowFilter(rows) {
    rows = _.filter(
      rows,
      (e) =>
        e.title.toUpperCase() !== '_PORTAL_FE_' &&
        e.title.toLowerCase().indexOf(this.params.searchText) > -1,
    );
    rows = _.orderBy(
      rows,
      this.params.orderBy,
      this.params.orderDir.toLowerCase(),
    );
    const begin = (this.params.page - 1) * this.params.pageSize;
    const end = begin + this.params.pageSize;
    const newRow = _.slice(rows, begin, end);
    return { list: newRow, total: rows.length };
  }
  // onDelete
  onDelete(row) {
    this.doDelete([row.id]);
  }
  // onRename
  onRename(row) {
    this.showModal(
      CollectionAddDialogComponent,
      { parent: this.parent, detail: row },
      null,
    ).subscribe((result) => {
      if (result) {
        this.connectApi();
        if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
      }
    });
  }
  // Thêm mới Collection và thư mục
  onCreateFolder() {
    this.showModal(
      CollectionAddDialogComponent,
      { parent: this.parent },
      null,
    ).subscribe((result) => {
      if (result) {
        this.connectApi();
        if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
      }
    });
  }
  // Upload file
  public dropped(files: NgxFileDropEntry[]) {
    if (files.length === 1 || this.allowMulti) {
      for (const droppedFile of files) {
        this.uploading = true;
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            if (file.size > Constants.MAX_FILE_SIZE) {
              this.uploading = false;
              this.messageService.showError('FILE_MAX_SIZE_UPLOAD', 'E_FILE_005');
            } else {
              this.filesService.uploadTmpWName(file.name).subscribe(
                (res: any) => {
                  const { data } = res;
                  if (data.upload) {
                    try {
                      this.http.put<any>(data.upload.url, file)
                        .subscribe((a) => {////////////////////////////////////////////////////s
                          const body = {
                            title: file.name,
                            isDir: false,
                            size: file.size,
                            mimetype: file.type,
                            parentId: this.parent.id,
                            fileUpload: data.upload.filePath
                          };
                          this.filesService.apiFileCreate(body).subscribe(
                            ({ data }) => {
                              this.uploading = false;
                              if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
                              this.connectApi();
                              this.messageService.showSuccess('UPLOADING_COMPLETED', 'I_FILE_008');
                            },
                            (err) => {
                              this.uploading = false;
                              this.cdr.detectChanges();
                              this.messageService.showError('FAILURE_OCCURED');
                            },
                          );
                          ////////////////////////////////////////////////////////////////////////
                        });
                    } catch (e) {
                      this.uploading = false;
                      this.cdr.detectChanges();
                      this.messageService.showError('FAILURE_OCCURED');
                    }
                  } else {
                    this.uploading = false;
                    this.cdr.detectChanges();
                    this.messageService.showError('FAILURE_OCCURED');
                  }
                },
                () => {
                  this.uploading = false;
                  this.cdr.detectChanges();
                  this.messageService.showError('FAILURE_OCCURED');
                },
              );

            }
          });
        }
      }
    } else {
      const option: any = this.layoutService.optionModal() || { width: '400px' };
      this.dialog.open(PopupConfirmComponent, {
        ...option,
        data: {
          title: 'Notification',
          description: 'Multiple file uploads are not allowed',
          label: 'OK',
          icon: 'assets/icons/import.svg',
        },
      });
    }
  }

  onBulkActionClick(e) {
    if (e && e.selectedList && e.selectedList.length) {
      const ids = e.selectedList.map(col => col.id);
      this.doDelete(ids);
    }
  }
}
