import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
const _ = require('lodash');

@Component({
  selector: 'app-myfile-alert-dialog',
  templateUrl: 'myfile-alert-dialog.component.html',
  styleUrls: ['./myfile-alert-dialog.component.scss'],
})
export class MyfileAlertDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MyfileAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }

  ngOnInit() { }

  onClickCancel() {
    this.dialogRef.close();
  }

  ngAfterViewInit() { }

}
