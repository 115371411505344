import { environment } from './../../../../environments/environment';
import { MessageService } from './../../services/MessageService';
import {
  Component,
  OnInit,
  Inject,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { GroupService } from 'app/views/group/group.service';
const _ = require('lodash');

@Component({
  selector: 'partner-groups',
  templateUrl: 'partner-groups.component.html',
  styleUrls: ['./partner-groups.component.scss'],
})
export class PartnerGroupsComponent implements OnInit {
  params: any = {
    orderBy: 'createdAt', orderDir: 'DESC', searchText: '',
    filter: 'enableAssignFromPartnerView', page: 1, pageSize: 10
  };
  link: string = environment.mediaUrl + 'fs/files/appdata/';
  choice: any = {};
  list: any = [];
  loading = false;
  multi = true;
  total = 0; // Tổng số bản ghi
  constructor(
    public dialogRef: MatDialogRef<PartnerGroupsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translateService: TranslateService,
    private groupService: GroupService,
    public snackBar: MatSnackBar,
    public messageService: MessageService,
  ) {
    this.choice = _.keyBy(this.data.groups, 'id') || [];
  }

  ngOnInit() {
    this.apiSearch();
  }

  onChangeSearch(e) {
    this.params.searchText = e.searchText.trim();
    this.apiSearch();
  }

  apiSearch() {
    this.loading = true;
    this.groupService.adGroupsForAddInPartnerScreen({...this.params}).subscribe(
      ({ data }) => {
        const apiRes: any = data;
        if (apiRes.adGroupsForAddInPartnerScreen.entities) {
          this.list = apiRes.adGroupsForAddInPartnerScreen.entities;
          this.total = apiRes.adGroupsForAddInPartnerScreen.total;
        } else {
          this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
        }
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
      },
    );
  }


  // Sự kiệm bấm nút sang trang
  onPage(page) {
    this.params.page = page;
    this.apiSearch();
  }
  // Tính ra số trang
  totalPage() {
    const totalPage = Math.ceil(this.total / this.params.pageSize);
    return new Array(totalPage).fill(1);
  }

  onChoice(row) {
    if (this.multi) {
      if (this.choice && this.choice[row.id]) {
        delete this.choice[row.id];
      } else {
        this.choice[row.id] = row;
      }
    } else {
      this.dialogRef.close(row);
    }
  }

  checked() {
    if (_.values(this.choice).length > 0) return true;
    return false;

  }

  onClickCancel() {
    this.dialogRef.close();
  }

  onBtnDialogOk() {
    this.dialogRef.close(_.values(this.choice));
  }

  convertArray() {
    return _.values(this.choice);
  }
}
