import { map } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
const _ = require('lodash');

@Component({
  selector: 'app-view-profile',
  templateUrl: 'view-profile.component.html',
  styleUrls: ['./view-profile.component.scss']
})
export class ViewProfileComponent implements OnInit, OnChanges {
  isLda: boolean =  environment.groupType === 'lda' ? true : false;
  isPtm: boolean =  environment.groupType === 'ptm' ? true : false;
  @Input() type = 'lead';
  @Input() consultations: any = {};
  @Input() detail: any = {};
  @Input() partner: any = {};
  @Input() required = false;

  @Output() onOpen = new EventEmitter();
  PERM_MANAGE_CONTACT = 'PermManageContact';
  PermManageContact = false;
  questions: any = [];
  useSideAgreement = false;
  constructor(
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
  ) {
  }
  ngOnInit() {
    console.log('this.partner', this.partner);
    const permission = JSON.parse(localStorage.getItem('permission'));
    this.PermManageContact = permission.includes(this.PERM_MANAGE_CONTACT);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.consultations) {
        this.createQues(changes.consultations.currentValue);
      }

    }
  }

  viewAnswer(answers) {
    return answers.filter(a => a.checked).map(b => b.text).join(', ');
  }

  countQuestion()
  {
    const total = this.questions.length;
    let didanswer = 0;
    this.questions.map(ques => {
      if (ques.type === 'text') {
        if (ques.answers) {
          didanswer ++;
        }
      } else {
        if (_.find(ques.answers, ['checked', true])) {
          didanswer ++;
        }
      }
    });
    return didanswer + '/' + total;
  }

  checkStatus() {
    if (this.type === 'lead') {
      if (this.detail.status === 'freigegeben' || this.detail.status === 'export') {
        return false;
      }
    } else {
      if (this.PermManageContact) {
        return true;
      } else {
        if (this.detail.status === 'export') {
          return false;
        } else {
          return true;
        }
      }

    }
    return true;
  }

  createQues(_consultations) {
    const consultations = JSON.parse(JSON.stringify(_consultations));
    if (consultations[0].profiling && _.isString(consultations[0].profiling)) {

      const profiling = JSON.parse(consultations[0].profiling.toString());
      this.questions = profiling.content.questions;
      this.useSideAgreement = profiling.useSideAgreement;
    }
  }

  showProfile() {
    this.onOpen.emit();
  }


  ngAfterViewInit() {

  }


  onSubmit() {
  }

  viewClassification() {
    let classfc = 'C';
    let Alead = true;
    let Blead = true;
    this.questions.map(ques => {
      let checkAns = false;
      if (ques.type === 'text') {
        if (ques.answers) {
          checkAns = true;
        }
      } else {
        if (_.find(ques.answers, ['checked', true])) {
          checkAns = true;
        }
      }
      if (!checkAns) {
        if (ques.classification === 'a-lead') {
          Alead = false;
        } else if (ques.classification === 'b-lead') {
          Blead = false;
        }
      }
    });
    classfc = 'C';
    if (Blead === true && classfc === 'C') { classfc = 'B'; }
    if (Alead === true && classfc === 'B') { classfc = 'A'; }
    if (this.useSideAgreement && classfc === 'A' ) {
      classfc = 'A+';
    }
    return classfc;
  }

  getAnswer(item) {
    if (item.type === 'text' && item.answers) {
      return true;
    }
    if (item.type !== 'text' && item.answers && item.answers.filter(o => o.checked).length > 0) { return true; }
    return false;
  }

}
