import { AbstractControl } from '@angular/forms';
import { Injectable, NgZone } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { StorageService } from '../services/storage.service';
import { BroadcastService } from '../services/broadcast.service';
import 'rxjs/add/operator/toPromise';

@Injectable()
export class DataService {
  broadcastServiceSubs = {};
  _isInited = false;

  public sData: any = null;
  public actions: any = null;
  public permission: any = null;
  public setting: any = null;

  public filter: any = { group: null, pages: null, customer: null, file: null, order: null, team: null, partner: null, user: null };
  public params: any = { group: null, pages: null, customer: null, file: null, order: null, team: null, partner: null, user: null };

  constructor(
    private storageService: StorageService,
  ) {
    // console.log('[DataService] constructor');
    this.initSData();
  }

  init(): void {
    if (!this._isInited) {
      this.initSData();
      this._isInited = true;
    }
  }

  initSData(): void {

    this.sData = this.retrieveSData();
    this.setting = this.retrieveSData('setting');
    this.actions = [
      {
        name: 'Auswahlen',
        callback: () => console.log('callback function'),
      },
    ];
    if (!this.sData) {
      // if sData not set, i.e. first app run -> init sData
      this.sData = {
        appVersion: '0.2.0',
        group: {},
        role: '',
        company: '',
        user: {},
        conf: {},
        platform: {},
        contact: {},
        uiContact: {},
        ui: {
          userAgreementAccepted: false,
          useTableView: false,
          suggestedProducts: [],
        },
      };
    }
  }

  getLastUser(key: string) {
    const { userId } = this.sData.user;
    if (userId) {
      try {
        const lastUser = JSON.parse(localStorage.getItem('lastUser'));
        if (lastUser && lastUser[userId] && lastUser[userId][key]) {
          return lastUser[userId][key];
        }
      } catch (e) {

      }
    }
    return null;
  }

  setLastUser(key: string, value: string) {
    const { userId } = this.sData.user;
    if (userId) {
      let lastUser: any = null;
      try {
        lastUser = JSON.parse(localStorage.getItem('lastUser'));
      } catch (e) {

      }
      if (!lastUser) {
        lastUser = {};
      }
      if (!lastUser[userId]) {
        lastUser[userId] = {};
      }
      lastUser[userId][key] = value;
      localStorage.setItem('lastUser', JSON.stringify(lastUser));
    }
  }

  setFilter(name, value): void {
    this.filter[name] = value;
  }

  setParams(name, value): void {
    this.params[name] = value;
  }

  updateSData(newData) {
    this.sData = newData;
    this.storeSData();
  }

  storeSData(): void {
    this.storageService.store('sData', this.sData);
    this.initSData();
  }

  retrieveSData(name = 'sData'): void {
    return this.storageService.retrieve(name);
  }

  getSData(): any {
    return this.sData;
  }

  setUserFromApiResData(apiResData) {
    this.sData.user = apiResData;
  }
  setUserFromApiResDataV2Data(apiResData) {
    if (apiResData.v2dataEnhanced) {
      this.sData.user.v2dataEnhanced = apiResData.v2dataEnhanced;
    }
  }
  setGroupAndRole(group, role, company, setting, orgs, groups) {
    this.sData.group = group;
    this.sData.role = role;
    this.sData.company = company;
    this.permission = [];
    this.setting = [];
    role.map(item => this.permission = [...this.permission, ...item.perms]);
    localStorage.setItem('role', JSON.stringify(role));
    localStorage.setItem('permission', JSON.stringify(this.permission));
    localStorage.setItem('groups', JSON.stringify(groups));
    localStorage.setItem('company', company);
    localStorage.setItem('setting', JSON.stringify(setting));

    let org: any = {};
    if (orgs && Array.isArray(orgs) && orgs.length > 0) {
      // Chỉ lấy ra org đầu tiên trong danh sách
      org = orgs[0];
      org.groups = org.groups.map((e) => {
        e.orgId = org.id;
        return e;
      });
    }
    localStorage.setItem('partner', JSON.stringify(org));
  }

  setSetting(setting) {
    this.setting = [];
    localStorage.setItem('setting', JSON.stringify(setting));
  }

  isUserQM() {
    if (this.sData.role === 'Quality Manager' || this.sData.role === 'Admin') {
      return true;
    }
    return false;
  }
  isUserAdmin() {
    return this.sData.role === 'Admin';
  }
  isUserTelekomPartner() {
    return this.sData.user.isTelekomPartner;
  }

  removeSpaces(control: AbstractControl) {
    if (control && control.value) {
      if (!control.value.replace(/\s/g, '').length) {
        control.setValue('');
      }
    }
    return null;
  }

  getPartner(isAdmin = false) {
    const partners = JSON.parse(localStorage.getItem('partner'));

    if (partners && partners.id) {
      if (isAdmin === true) {
        if (partners.isAdmin === true) {
          return partners;
        }
      } else {
        return partners;
      }
    }
    return false;
  }

  getGroups() {
    const partners = JSON.parse(localStorage.getItem('groups'));
    if (!partners || !Array.isArray(partners)) {
      return [];
    }
    return partners;
  }

  getNameRole() {
    const strrole = [];
    if (Array.isArray(this.sData.role)) {
      this.sData.role.map(item => strrole.push(item.titleDE));
    }
    return strrole.join(',');
  }

  checkRole(_perm) {
    const permission = JSON.parse(localStorage.getItem('permission'));
    if (!permission) {
      return false;
    }
    if (!Array.isArray(permission)) {
      return false;
    }
    if (permission.includes(_perm)) {
      return true;
    }
    return false;
  }

  checkRoleV2(_perm) {
    const permission = JSON.parse(localStorage.getItem('permission'));
    if (!permission) {
      return false;
    }
    if (!Array.isArray(permission)) {
      return false;
    }
    if (Array.isArray(_perm)) {
      let check = false;
      _perm.map(p => {
        if (permission.includes(p)) {
          check = true;
        }
        return p;
      });
      return check;
    }
    if (permission.includes(_perm)) {
      return true;
    }
    return false;
  }
}
