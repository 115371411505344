import { MessageService } from 'app/shared/services/MessageService';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LeadService } from '../lead.service';
import { BarcodeFormat } from '@zxing/library';

@Component({
  selector: 'app-scan-qr',
  templateUrl: 'scan-qr-dialog.component.html',
  styleUrls: ['./scan-qr-dialog.component.scss']
})
export class ScanQrDialogComponent implements OnInit {
  contact: any = null;
  screen = 'scanQr';
  loading = false;
  inputQrStr = '';
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.QR_CODE,
  ];

  constructor(
    public dialogRef: MatDialogRef<ScanQrDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private leadService: LeadService,
    private messageService: MessageService,
    public snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.resetVars();
  }
  resetVars() {
    this.screen = 'scanQr';
    this.loading = false;
  }

  onSubmitInputQrStr() {
    this.scanQrDone(this.inputQrStr);
  }
  scanQrDone(qrStr) {
    console.log('scanQrDone qrStr:', qrStr);
    this.screen = 'checkQr';
    this.loading = true;
    let apiParams = {
      qrStr: qrStr,
      groupId: this.data.groupId,
    }
    this.leadService.contactFindByQrStr(apiParams).subscribe(({ data }) => {
      let apiRes: any = data;
      if (apiRes.contactFindByQrStr.data) {
        if (apiRes.contactFindByQrStr.data.contact.id) {
          this.contact = apiRes.contactFindByQrStr.data.contact;
        } else {
          this.contact = false
        }
      } else {
        this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
      }
      this.loading = false;
    }, () => {
      this.loading = false;
      this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
      
    });
  }
  onTorchCompatible(event) {
    console.log('onTorchCompatible event:', event);
  }
  camerasFoundHandler(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
   // console.log('this.availableDevices:', this.availableDevices);
    this.currentDevice = this.availableDevices[this.availableDevices.length - 1];
  }
  camerasNotFoundHandler(event) {
    //console.log('camerasNotFoundHandler event:', event);
    this.screen = 'noCamera';
  }
  scanSuccessHandler(event) {
    //console.log('scanSuccessHandler event:', event);
    // alert(event);
    this.scanQrDone(event);
  }
  scanErrorHandler(event) {
   // console.log('scanErrorHandler event:', event);
  }
  scanFailureHandler(event) {
    // console.log('scanFailureHandler event:', event);
  }
  scanCompleteHandler(event) {
    // console.log('scanCompleteHandler event:', event);
  }
 

  onBtnUseData() {
    if(this.contact)this.dialogRef.close(this.contact);
  }

  onClickCancel() {
    this.dialogRef.close();
  }

 
}
