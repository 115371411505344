import { DataService } from 'app/shared/services/data.service';
import {
  Component,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupConfirmComponent } from '../../../shared/components/popup-confirm/popup-confirm.component';
import { MessageService } from 'app/shared/services/MessageService';
import { LayoutService } from 'app/shared/services/layout.service';

@Component({
  selector: 'setting-headbar',
  templateUrl: './setting-headerbar.template.html',
  styleUrls: ['./setting-headerbar.component.scss']
})
export class SettingHeaderbar implements OnInit {
  constructor(
    public dataService: DataService,
    public messageService: MessageService,
    public layoutService: LayoutService,
    public dialog: MatDialog,
  ) {
  }
  ngOnInit(): void {
    this.messageService.registryButton([]);
  }

  onReviewApprovedNews(): void {
    const option: any = this.layoutService.optionModal() || { width: '500px' };
    const dialogRef = this.dialog.open(PopupConfirmComponent, {
      ...option,
      data: {
        title: 'Confirm approve',
        okButtonTitle: 'PUBLISH',
        description:
          'CONFIRM_APPROVE_DESCRIPTION',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && this.messageService.actions) {
        this.messageService.actions.publish();
      }
    });
  }

  onReviewRejectedNews(): void {
    const option: any = this.layoutService.optionModal() || { width: '500px' };
    const dialogRef = this.dialog.open(PopupConfirmComponent, {
      ...option,
      data: {
        title: 'Confirm reject',
        okButtonTitle: 'REJECT',
        description:
          'CONFIRM_REJECT_DESCRIPTION',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && this.messageService.actions) {
        this.messageService.actions.reject();
      }
    });
  }

}
