import { DataService } from 'app/shared/services/data.service';
import {
  Component,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupConfirmComponent } from '../../../shared/components/popup-confirm/popup-confirm.component';
import { MessageService } from 'app/shared/services/MessageService';
import { LayoutService } from 'app/shared/services/layout.service';
import { Router } from '@angular/router';

@Component({
  selector: 'news-headbar',
  templateUrl: './headerbar.template.html',
  styleUrls: ['./headerbar.component.scss']
})
export class NewsHeaderbar implements OnInit {
  
  constructor(  
    public dataService: DataService,
    public messageService: MessageService,
    public layoutService: LayoutService,
    public dialog: MatDialog,
    public router : Router
    ) {
  }
  ngOnInit(): void {
  }

  onReviewApprovedNews(): void {
    let option: any = this.layoutService.optionModal() || { width: '500px' };
    const dialogRef = this.dialog.open(PopupConfirmComponent, {
      ...option,
      data: {
        title: 'Confirm approve',
        okButtonTitle: 'PUBLISH',
        description:
          'CONFIRM_APPROVE_DESCRIPTION',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && this.messageService.actions) {
        this.messageService.actions.publish()
      }
    });
  }

  onReviewRejectedNews(): void {
    let option: any = this.layoutService.optionModal() || { width: '500px' };
    const dialogRef = this.dialog.open(PopupConfirmComponent, {
      ...option,
      data: {
        title: 'REJECTED_REVIEW',
        okButtonTitle: 'REJECT',
        iscomment: true,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && this.messageService.actions) {
        const user = this.dataService.sData.user;
        const metaData = {
          fullname: user.firstName + ' ' + user.lastName,
          reason: result,
          datereject: Date.now(),
        };
        this.messageService.actions.reject(metaData)
      }
    })
  }

  hasReviewPerm() {
    return (this.dataService.checkRole('PermAdminNews')
    || this.messageService.isAuthorOrEditor
    || this.messageService.isPresentative)
    && this.messageService.isReviewActive 
    && !this.messageService.isBypassReview 
    && !this.messageService.selectActive
  }

  hasPermPublish() {
    return this.dataService.checkRole('PermPublishNews') 
    && this.messageService.isInReviewActive
  }

  hasPermDirectlyPublish() {
    return this.dataService.checkRole('PermPublishNews')  
      && this.messageService.isBypassReview
      && this.messageService.isAuthorOrEditor
      && !this.messageService.isPublished
  }
}