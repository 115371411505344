import { Injectable, Inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';
import { DataService } from './data.service';
import Utils from '../utils/utils';

@Injectable()
export class UserService {
  constructor(private apollo: Apollo, private dataService: DataService) {}
  users(params) {
    return this.apollo.query({
      query: gql`query {
    users(${Utils.params2GqlStr(params)}) {
      entities { id salutation firstName lastName email mobileNumber status docusignRevocationEmail customPermissionTextEN customPermissionTextDE customDocusignTemplateId partnerCompanyName isTelekomPartner tags roles createdAt updatedAt avatarImg }
      total
    }}`,
      fetchPolicy: 'no-cache',
    });
  }
  user(userId) {
    return this.apollo.query({
      query: gql`query {
    user(id: "${userId}") {
      id salutation firstName lastName email mobileNumber status docusignRevocationEmail customPermissionTextEN customPermissionTextDE customDocusignTemplateId partnerCompanyName isTelekomPartner tags roles createdAt updatedAt
    }}`,
      fetchPolicy: 'no-cache',
    });
  }
  createUser(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    createUser(${Utils.params2GqlStr(params)}) {
      id
    }}`,
    });
  }
  updateUser(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    updateUser(${Utils.params2GqlStr(params)}) {
      data
    }}`,
    });
  }
  userSendWelcomeMail(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    userSendWelcomeMail(${Utils.params2GqlStr(params)}) {
      data
    }}`,
    });
  }
  deleteUser(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    deleteUser(ids: ${JSON.stringify(params.ids)}) {
      data
    }}`,
    });
  }

  adOrgListUserForAdding(params) {
    return this.apollo.query({
      query: gql`query {
        adOrgListUserForAdding(${Utils.params2GqlStr(params)}) {
          entities {
            id
            firstName
            lastName
            email
            status
              avatarImg
          }
          total
        }}`,
      fetchPolicy: 'no-cache',
    });
  }
}
