import { EventEmitter } from '@angular/core';
import {
  Component,
  OnInit,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'app-save-button',
  templateUrl: 'index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class SaveButtonComponent implements OnInit {
  @Input() isActive = false;
  @Output() onClickSave = new EventEmitter();
  constructor() {}

  ngOnInit() {
  }

  handleClickSave() {
    this.onClickSave.emit(true);
  }

}
