import { DataService } from 'app/shared/services/data.service';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
const _ = require('lodash');

@Component({
  selector: 'app-team-choice-dialog',
  templateUrl: 'team-choice-dialog.component.html',
  styleUrls: ['./team-choice-dialog.component.scss'],
})
export class TeamChoiceialogComponent implements OnInit {
  team: any = {
    email: '',
  };
  loading: any = false;
  disabled: boolean = true;
  listPartner: any = []
  constructor(
    public dialogRef: MatDialogRef<TeamChoiceialogComponent>,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.getPartner();
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  ngAfterViewInit() {}

  getPartner() {
    this.loading = true
    const data = this.dataService.getPartner(true)
    if(data && Array.isArray(data) && data.length>0)
    {
      this.listPartner = data;
      this.loading = false;
       if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
    }
  }

  onSubmit(row) {
    this.dialogRef.close(row);
  }
}
