import { Component, OnInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { PrivacyComponent } from '../../privacy/privacy.component';
import { DialogService } from '../../scale-dialog/dialog.service';
import { TermOfUseComponent } from '../../term-of-use/term-of-use.component';
@Component({
  selector: 'app-auth-layout',
  styleUrls: ['./auth-layout.component.scss'],
  templateUrl: './auth-layout.component.html'
})
export class AuthLayoutComponent implements OnInit {
  linkPrivacy = environment.linkPrivacy;
  isTc: boolean = environment.groupType === 'tc' ? true : false;
  isLda: boolean = environment.groupType === 'lda' ? true : false;
  isCollapsed = false;
  isMobile = false;
  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.isMobile = window.innerWidth < 768;
  }
  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (document.documentElement.scrollTop > 0) {
      this.isCollapsed = true;
    } else {
      this.isCollapsed = false;
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (document.documentElement.clientWidth > 768) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  showTerm() {
    this.dialogService.open(TermOfUseComponent, {
      data: { heading: this.translateService.instant('TERMS_AND_USE'), disableAction: true  },
    });
  }

  showPrivacy() {
    this.dialogService.open(PrivacyComponent, {
      data: { heading: this.translateService.instant('DATA_PRIVACY_FOOTER_TITLE'), disableAction: true },
    });
  }
}
