import { ActivatedRoute } from '@angular/router';
import { DataService } from '../../services/data.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
const JSURL = require("jsurl2");

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  @Input('params') params: any = null;
  @Input('isNews') isNews: boolean = false;
  oldkeyword: string = '';
  filter: any = { searchText: '' }
  isFocus: boolean = false;
  @Output() onChange = new EventEmitter();//Sự kiện thay đổi dữ liệu tìm kiếm
  constructor(
    public dataService: DataService,
  ) {

  }

  onFocus(type) {
    this.isFocus = type;
  }

  ngOnInit() {


    if (this.params) {
      this.filter.searchText = this.params.searchText;
      this.oldkeyword = '' + this.params.searchText;
    }

  }

  closeSearch() {
    this.filter.searchText = '';
    this.handleSearch();
  }

  handleSearch() {
    if (this.oldkeyword != this.filter.searchText) {
      this.oldkeyword = '' + this.filter.searchText;
      this.onChange.emit({...this.filter, searchText: this.filter.searchText.toLowerCase()})
    }
  }
}
