import { Injectable, Inject } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable()
export class ValidatorsService {
  public number(prms: any): any {
    return (control: FormControl): any => {
      // console.log('number prms:', prms);
      let val: any = control.value;
      if (isNaN(val) || /\D/.test(val.toString())) {
        return { number: true };
      } else if (!isNaN(prms.min) && !isNaN(prms.max)) {
        return val < prms.min || val > prms.max ? { number: true } : null;
      } else if (!isNaN(prms.min)) {
        return val < prms.min ? { number: true } : null;
      } else if (!isNaN(prms.max)) {
        return val > prms.max ? { number: true } : null;
      } else {
        return null;
      }
    };
  }


  public phone(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const re = /^([0-9\-+.() \s]{8,})?$/i
      const forbidden = re.test(String(control.value).toLowerCase())
      return !forbidden ? {forbiddenName: {value: control.value}} : null;
    };
  }


}
