import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Inject, } from '@angular/core';
import Utils from 'app/shared/utils/utils';


@Component({
  selector: 'app-popup-label',
  templateUrl: './popup-label.component.html',
  styleUrls: ['./popup-label.component.scss']
})
export class PopupLabelComponent implements OnInit {
  Utils = Utils;
  constructor(
    public dialogRef: MatDialogRef<PopupLabelComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }
  ngOnInit() {
  }

  onClickCancel() {
    this.dialogRef.close();
  }


  

}
