import { DataService } from 'app/shared/services/data.service';
import {
  Component,
  OnInit,
} from '@angular/core';
import { MessageService } from 'app/shared/services/MessageService';


@Component({
  selector: 'partner-detail-headbar',
  templateUrl: './partner-detail-headerbar.template.html',
  styleUrls: ["./partner-detail-headbar.component.scss"]
})
export class PartnerDetailHeaderbar implements OnInit {

  constructor(public dataService: DataService, public messageService: MessageService) {


  }
  ngOnInit(): void {
  }



  run() {
    if (this.messageService.actions && this.messageService.selectActive) {
      this.messageService.actions.save();
    }

  }
}
