import {
  Injectable,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  EmbeddedViewRef,
  ComponentRef,
  Type,
} from '@angular/core';
import { DialogModule } from './dialog.module';
import { DialogComponent } from './dialog-modal/dialog.component';
import { DialogConfig, DialogConfirm } from './dialog-config';
import { DialogInjector } from './dialog-injector';
import { DialogRef } from './dialog-ref';
import { DialogConfirmComponent } from './dialog-confirm/dialog.component';
import { ConfirmRef } from './confirm-ref';

export interface Action {
  onSubmit?:any
}
@Injectable({
  providedIn: DialogModule,
})
export class DialogService {
  dialogComponentRef: ComponentRef<DialogComponent>;
  dialogConfirmRef: ComponentRef<DialogConfirmComponent>;
  disabledSubmitDialog:boolean = true
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}

  public setDisabledSubmitDialog(isDisabled:boolean){
    this.disabledSubmitDialog = isDisabled
  }

  public registActions(action:Action){
    this.dialogComponentRef.instance.actions = action
  }
  
  public open(componentType: Type<any>, config: DialogConfig): DialogRef {
    const dialogRef = this.appendDialogComponentToBody(config);

    this.dialogComponentRef.instance.childComponentType = componentType;
    return dialogRef;
  }

  private appendDialogComponentToBody(config: DialogConfig): DialogRef {
    const map = new WeakMap();
    map.set(DialogConfig, config);

    const dialogRef = new DialogRef();
    map.set(DialogRef, dialogRef);

    const sub = dialogRef.afterClosed.subscribe(() => {
      // close the dialog
      this.removeDialogComponentFromBody();
      sub.unsubscribe();
    });

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(DialogComponent);
    const componentRef = componentFactory.create(
      new DialogInjector(this.injector, map),
    );
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.dialogComponentRef = componentRef;

    this.dialogComponentRef.instance.onClose.subscribe(() => {
      this.removeDialogComponentFromBody();
    });

    return dialogRef;
  }

  private removeDialogComponentFromBody(): void {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }

  // dialog-confirm

  public confirm( config: DialogConfirm): ConfirmRef {
    const confirmRef = this.appendConfirmComponentToBody(config);
    return confirmRef;
  }

  private appendConfirmComponentToBody(config: DialogConfirm): ConfirmRef {
    const map = new WeakMap();
    map.set(DialogConfirm, config);

    const confirmRef = new ConfirmRef();
    map.set(ConfirmRef, confirmRef);

    const sub = confirmRef.afterClosed.subscribe(() => {
      // close the dialog
      this.removeConfirmComponentFromBody();
      sub.unsubscribe();
    });

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(DialogConfirmComponent);
    const componentRef = componentFactory.create(
      new DialogInjector(this.injector, map),
    );
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.dialogConfirmRef = componentRef;

    this.dialogConfirmRef.instance.onClose.subscribe(() => {
      this.removeConfirmComponentFromBody();
    });

    return confirmRef;
  }

  private removeConfirmComponentFromBody(): void {
    this.appRef.detachView(this.dialogConfirmRef.hostView);
    this.dialogConfirmRef.destroy();
  }
}
