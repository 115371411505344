import { LayoutService } from 'app/shared/services/layout.service';
import { DataService } from 'app/shared/services/data.service';
import { PopupConfirmComponent } from './../../../shared/components/popup-confirm/popup-confirm.component';
import { TeamService } from '../team.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SettingHeaderbar } from './../../setting/setting-headerbar/setting-headerbar.component';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppConfirmService } from '../../../shared/services/app-confirm/app-confirm.service';
import { MessageService } from './../../../shared/services/MessageService';
import isEqual from 'lodash/isEqual';
import { environment } from 'environments/environment';
import moment from 'moment';
import Constants from '../../../shared/utils/constants'

@Component({
  selector: 'app-team-detail',
  templateUrl: './team-detail.component.html',
  styleUrls: ['./team-detail.component.scss'],
  providers: [],
})
export class TeamDetailComponent implements OnInit {
  isLda:boolean =  environment.groupType === 'lda'?true:false
  isPtm:boolean =  environment.groupType === 'ptm'?true:false
  moment: any = moment;
  link: string = environment.mediaUrl + 'fs/files/uploads/';
  org: any = null;
  user: any = null;
  formGroup: FormGroup;
  datakeep: any = {};
  loading = false;
  type: string = '';
  isDisable: boolean = false;
  maxLengthEmail : number;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private teamService: TeamService,
    private dataService: DataService,
    public translateService: TranslateService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private layoutService: LayoutService,
    public appConfirmService: AppConfirmService,
  ) {

  }
  ngOnInit() {
    this.route.data.subscribe(data => {
      this.type = data.type;
      const { id, userId } = this.route.snapshot.params;
      this.doApiGetteamDetail(id, userId);
    })
    this.maxLengthEmail = Constants.MAX_LENGTH_INPUT_EMAIL

  }

  ngAfterViewInit() {
    this.messageService.compConfig(SettingHeaderbar);
  }

  ngOnDestroy(): void {
    this.messageService.reset();
  }

  initForm() {
    if (this.user) {
      const { salutation, firstName, lastName, email, mobileNumber } = this.user;
      this.formGroup = this.fb.group({
        salutation: [salutation ? salutation : 'Herr', [Validators.required]],
        firstName: [firstName, [Validators.required, this.dataService.removeSpaces]],
        lastName: [lastName, [Validators.required, this.dataService.removeSpaces]],
        email: [email, [Validators.required, this.dataService.removeSpaces]],
        mobileNumber: [mobileNumber, [Validators.required, this.dataService.removeSpaces]],
      });
      if (this.type === 'partner' && !this.dataService.checkRole('PermManageSquads')) {
        this.formGroup.disable();
        this.isDisable = true;
      } else {
        this.datakeep = JSON.parse(JSON.stringify(this.formGroup.value));
        this.formGroup.valueChanges.subscribe(() => {
          this.formValueChanged();
        });
      }
    }
  }

  onBackTeamList() {
    if (this.type==='team') {
      this.router.navigate(['/team/' + this.org.id]);
    } else {
      if(this.isPtm)
      this.router.navigate(['/partner/detail/general/' + this.org.id]);
      else if(this.isLda)
      this.router.navigate(['/squads/detail/general/' + this.org.id]);
    }
  }

  doApiGetteamDetail(id, userId) {
    this.loading = true;
    this.teamService.apiDetail({ id }).subscribe(
      ({ data }) => {
        let apiRes: any = data;
        this.org = apiRes.adOrg;
        this.teamService.apiGetUserPartner(id, userId).subscribe(
          ({ data }) => {
            this.loading = false;
            let apiRes: any = data;
            this.user = apiRes.adOrgUser;
            console.log(' this.user :',  this.user);
            this.initForm();
             if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
          },
          () => {
            this.initForm();
            this.loading = false;
             if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
            this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002')
          },
        );
      },
      () => {
        this.initForm();
        this.loading = false;
         if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
        this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002')
      },
    );
  }



  onBtnDeleteUser(): void {
    let option: any = this.layoutService.optionModal() || { width: '300px' }
    const dialogRef = this.dialog.open(PopupConfirmComponent, {
      ...option,
      data: {
        title: 'remove-user',
        description: 'Are you sure you want to remove this User?',
        label: 'Remove',
        disableX: true,
        icon: 'assets/icons/remove-blue.svg'
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let userId = [this.user.id];
        let id = this.org.id;
        this.teamService.removeUser({ id, userId }).subscribe(
          ({ data }) => {
            let apiRes: any = data;
            if (apiRes.adOrgRemoveUsers) {
              this.messageService.showSuccess('USER_REMOVED', 'I_USER_001')
              this.onBackTeamList();
            } else {
              this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002')
            }
          },
          () => {
            this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002')
          },
        );
      }
    });
  }

  formValueChanged() {
    if (!isEqual(JSON.stringify(this.formGroup.value), JSON.stringify(this.datakeep)) && this.formGroup.valid) {
      this.messageService.updateActive(true);
      this.messageService.updateCount(this.translateService.instant('UNSAVED_CHANGES'));
    } else {
      this.messageService.updateActive(false);
      this.messageService.updateCount('');
    }
    this.messageService.registryAction({
      save: () => {
        this.loading = true;
        const id = this.org.id;
        const userId = this.user.id;
        const { salutation, firstName, lastName, email, mobileNumber } =
          this.formGroup.value;
        this.teamService
          .apiUpdateUser({ id, userId, salutation, firstName, lastName, email, mobileNumber, })
          .subscribe(
            ({ data }) => {
              this.loading = false;
              let apiRes: any = data;
              if (apiRes.adOrgUpdateUser) {
                this.datakeep = JSON.parse(JSON.stringify(this.formGroup.value));
                this.user = JSON.parse(JSON.stringify(Object.assign(this.user, this.formGroup.value)));
                this.messageService.updateActive(false);
                this.messageService.updateCount('');
                 if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
                this.messageService.showSuccess('USER_UPDATED', 'I_USER_002')
              } else {
                this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002')
              }
            },
            () => {
              this.loading = false;
              this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002')
            },
          );
      },
      close: () => {
        this.formGroup.setValue(this.datakeep)
         if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
        this.messageService.updateActive(false);
        this.messageService.updateCount('');
      },
    });
  }
}
