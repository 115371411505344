import { DataService } from 'app/shared/services/data.service';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'pop-up-edit-template',
  templateUrl: 'pop-up-edit-template.component.html',
  styleUrls: ['./pop-up-edit-template.component.scss'],
})
export class TemplateEditDialogComponent implements OnInit {
  form: FormGroup = null;
  constructor(
    public dialogRef: MatDialogRef<TemplateEditDialogComponent>,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.form = new FormGroup({
      templateTitle: new FormControl(data ? data.template.title : ""),
    });
  }

  ngOnInit() {
    
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  ngAfterViewInit() {}

 

  onSubmit() {
    this.dialogRef.close({saveTemplate: this.form.value.templateTitle});
  }

  onDeleteTemplate(){
    this.dialogRef.close({deleteTemplate: this.data.template.id});
  }

}
