import { LeadService } from 'app/views/lead/lead.service';

import { AccountPasswordComponent } from './../views/account/popup-password/account-password.component';
import { AccountEditComponent } from './../views/account/poup-edit/account-edit.component';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

// SERVICES
import { ThemeService } from './services/theme.service';
import { NavigationService } from "./services/navigation.service";
import { RoutePartsService } from './services/route-parts.service';
import { AuthGuard } from './services/auth/auth.guard';
import { AppConfirmService } from './services/app-confirm/app-confirm.service';
import { AppLoaderService } from './services/app-loader/app-loader.service';
import { UserService } from './services/user.service';

import { DataService } from './services/data.service';
import { StorageService } from './services/storage.service';
import { ValidatorsService } from './services/validators.service';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';

import { SharedComponentsModule } from './components/shared-components.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { ExcelService } from './services/excel.service';
import { TimeagoModule, TimeagoIntl } from 'ngx-timeago';
import { CookieService } from 'ng2-cookies';

@NgModule({
  imports: [
    CommonModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    TimeagoModule.forRoot(),
  ],
  providers: [
    ThemeService,
    NavigationService,
    RoutePartsService,
    AuthGuard,
    AppConfirmService,
    AppLoaderService,
    UserService,
    DataService,
    StorageService,
    ValidatorsService,
    CanDeactivateGuard,
    ExcelService,
    TimeagoIntl,
    CookieService
  ],
  exports: [
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    TimeagoModule,
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
