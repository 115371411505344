import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LeadService } from 'app/views/lead/lead.service';
import { MessageService } from 'app/shared/services/MessageService';
@Component({
  selector: 'app-digital-signature',
  templateUrl: 'digital-signature.component.html',
  styleUrls: ['./digital-signature.component.scss']
})
export class DigitalSignatureDialogComponent implements OnInit {
  step:string = 'step1';
  group:any=null;
  dataSend:any=null;
  disabled: boolean = true;
  lead:any=null;
  link:string ='';
  browserRef:any=null;
  constructor(
    public dialogRef: MatDialogRef<DigitalSignatureDialogComponent>,
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    private translateService: TranslateService,
    public leadService: LeadService,
    public messageService: MessageService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.group = data.group;
    this.lead = data.lead;
  }
  ngOnInit() {
  }

  onClickCancel() {
    this.dialogRef.close();
  }



  runMe()
  {
    this.openDocusignLink(this.link)
  }

  onNext()
  {
    if(this.step ==='step1' && this.dataSend && this.dataSend.docusignTemplateType=='permKek4')
    {
      this.step = 'step2' ;
      this.disabled = true;
    }else{
      this.step = 'loading' ;
      this.dataSend.permPhone = this.dataSend.permPhone?'x':''
      this.dataSend.permEmail = this.dataSend.permEmail?'x':''
      this.dataSend.permSms = this.dataSend.permSms?'x':''
      this.leadService.client_docusign_createDoc(this.dataSend).subscribe((apiRes:any)=>{
        console.log('client_docusign_createDoc',apiRes);
        if (apiRes.data.client_docusign_createDoc.data) {
          if (apiRes.data.client_docusign_createDoc.data.docusignLink) {
            this.link = apiRes.data.client_docusign_createDoc.data.docusignLink;
            if (this.dataSend.docusignMethod === 'self') 
            {
              //Khi mở ký tại app luôn
              this.openDocusignLink(apiRes.data.client_docusign_createDoc.data.docusignLink);
              setTimeout(()=>{
                if(this.browserRef===null){
                  this.step = 'manual' ;
                }
              },2000)
            }else{
              //Khi mở ký từ link trong email hoặc SMS
              this.startPollingDocusignStatus();
            }
          } else {
            this.step = 'error' ;
          }
        }
      },()=>{
        this.step = 'error' ;
      })
    }
  }

  onValidStep1(e)
  {
    if(this.step ==='step1')
    {
      if(e.status)
      {
        this.disabled = false;
        this.dataSend = e.value;
      }else{
        this.disabled = true;
        this.dataSend = null;
      }
    }
  }

  onValidStep2(e)
  {
    if(this.step === 'step2')
    {
      if(e.status)
      {
        if(!e.value.permPhone && !e.value.permEmail && !e.value.permSms)
        {
          this.disabled = true;
          this.dataSend = null;
        }else{
          this.disabled = false;
          this.dataSend = e.value;
        }
      }else{
        this.disabled = true;
        this.dataSend = null;
      }
    }
  }

  openDocusignLink(link) {
    this.browserRef = window.open(link, '_blank ', 'location=yes,width=1000');
    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
    eventer(messageEvent, (e) => {
      //console.log('eventer',e);
      if (e.data && typeof  e.data == 'string' && e.data.indexOf('event=decline') >= 0) {
        this.messageService.showError('DOCUSIGN_WURDE_ABGELEHNT', 'I_DIGSIGNA_001')
      }
      if (this.browserRef) this.browserRef.close();
      this.browserRef = null;
      if (e.data  && typeof  e.data == 'string' && e.data.indexOf('&event=') >= 0) {
        this.getDocusignLogsForContact().then(res => {
          console.log('getDocusignLogsForContact',res);
          this.dialogRef.close(res);
        });
      }
    }, false);
  }

  startPollingDocusignStatus()
  {
    //Call Polling hoặc Socket
    this.leadService.client_sub_docusign_checkRequestStatus({ contactId:this.lead.id }).subscribe(({ data }: { data: any }) => {
      if(data && 
        data.client_sub_docusign_checkRequestStatus &&
        data.client_sub_docusign_checkRequestStatus.data &&
        data.client_sub_docusign_checkRequestStatus.data.docusignLogs
        ){
        console.log('Socket success',data.client_sub_docusign_checkRequestStatus.data.docusignLogs);
        this.dialogRef.close(data.client_sub_docusign_checkRequestStatus.data.docusignLogs);
      }
    }, (error) => {
      console.log(error);
    });
  }

  getDocusignLogsForContact()
  {
    return new Promise((resolve, reject) => {
      let apiParams =  {contactId: this.dataSend.contactId}
      this.leadService.client_docusign_getDocusignLogsForContact(apiParams).subscribe(({data}) => {
        let apiRes: any = data;
        console.log('client_docusign_getDocusignLogsForContact apiRes:', apiRes);
        if(apiRes && 
          apiRes.client_docusign_getDocusignLogsForContact &&
          apiRes.client_docusign_getDocusignLogsForContact.data &&
          apiRes.client_docusign_getDocusignLogsForContact.data.contacts &&
          apiRes.client_docusign_getDocusignLogsForContact.data.contacts.length>0 &&
          apiRes.client_docusign_getDocusignLogsForContact.data.contacts[0] &&
          apiRes.client_docusign_getDocusignLogsForContact.data.contacts[0].docusignLogs
          ){
          return resolve(apiRes.client_docusign_getDocusignLogsForContact.data.contacts[0].docusignLogs);
        }else return reject(null)
      }, error => {
        console.log('client_docusign_getDocusignLogsForContact error:', error);
        this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002')
        return reject();
      });
    });
  }
}
