import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'app/shared/services/data.service';
import { ValidatorsService } from 'app/shared/services/validators.service';
import Constants from '../../../../../../shared/utils/constants'
@Component({
  selector: 'app-ds-step1',
  templateUrl: 'ds-step1.component.html',
  styleUrls: ['./ds-step1.component.scss']
})
export class DigitalSignatureStep1Component implements OnInit {
  @Input('group') group: any = null;
  @Input('lead') lead: any = null;
  @Input('dataDefault') dataDefault: any = null;
  controls:any={};
  viewChoice:boolean = false;
  myForm: FormGroup = null;
  maxLengthEmail: number;
  @Output() onValid = new EventEmitter();
  validDynamic:any={}
  constructor(
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    private dataService: DataService,
    private fb: FormBuilder,
    private validatorsService: ValidatorsService,
  ) {
    this.maxLengthEmail = Constants.MAX_LENGTH_INPUT_EMAIL
    this.validDynamic={
      email:[Validators.required, Validators.email],
      mobile:[Validators.required,this.validatorsService.phone()],
    }
  }
  ngOnInit() {
    let docusignTemplateType='';
    const user= this.dataService.sData.user;
    if(!this.group.type && this.group.settingLead.docusign.allowedTemplates ) {
        if(this.group.settingLead.docusign.allowedTemplates.length > 1)
        {
          this.viewChoice = true;
          docusignTemplateType=''
        }else if(this.group.settingLead.docusign.allowedTemplates.length > 0)
        {
          docusignTemplateType = this.group.settingLead.docusign.allowedTemplates[0];
        }else docusignTemplateType='permTelekomInterested';
    }else docusignTemplateType='permTelekomInterested';
    this.controls = {
      contactId: new FormControl(this.lead?this.lead.id:'',Validators.required),
      salutation: new FormControl(this.lead?this.lead.salutation:'',[]),
      firstName: new FormControl(this.lead?this.lead.firstName:'',[]),
      lastName: new FormControl(this.lead?this.lead.lastName:'',[]),
      company: new FormControl(this.lead?this.lead.company:'',[]),
      street: new FormControl(this.lead?this.lead.street:'',[]),
      houseNumber: new FormControl(this.lead?this.lead.houseNumber:'',[]),
      zip: new FormControl(this.lead?this.lead.zip:'',[]),
      city: new FormControl(this.lead?this.lead.city:'',[]),
      email: new FormControl(this.lead?this.lead.email:'', []),
      landline:  new FormControl(this.lead?this.lead.landline:'', []),
      mobile: new FormControl(this.lead?this.lead.mobile:'', []),
      customerNumber: new FormControl('',[]),
      dob: new FormControl('',[]),
      permPhone: new FormControl('',[]),
      permEmail: new FormControl('',[]),
      permSms: new FormControl('',[]),
      isTelekomPartner: new FormControl(false, []),
      partnerCompanyName: new FormControl('', []),
      customDocusignTemplateId: new FormControl(user?user.customDocusignTemplateId:'', []),
      docusignRevocationEmail: new FormControl('', []),
      docusignTemplateType: new FormControl(docusignTemplateType, [Validators.required]),
      docusignMethod: new FormControl('', [Validators.required]),
      doPartnerTelekomRelevant: new FormControl(false, []),
    }
    this.initForm();
  }

  initForm() {
    this.myForm = new FormGroup(this.controls);
    if(this.dataDefault)this.myForm.setValue(this.dataDefault);
    this.myForm.valueChanges.subscribe((data)=>{
      this.onValid.emit({status:this.myForm.valid,value:this.myForm.value});
    })
  }

  validMore(name: string = ''): void {
    if(name) {
      this.controls[name] = new FormControl(this.lead?this.lead[name]:'', this.validDynamic[name]);
      if(name==='mobile'){
        this.controls.email = new FormControl(this.lead?this.lead.email:'', []);
      }else{
        this.controls.mobile = new FormControl(this.lead?this.lead.mobile:'', []);
      }
    }else{
      this.controls.mobile = new FormControl(this.lead?this.lead.mobile:'', []);
      this.controls.email = new FormControl(this.lead?this.lead.email:'', []);
    }
    this.initForm();
  }

  
}
