import { DataService } from 'app/shared/services/data.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
const _ = require('lodash');

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator TYPE
  state?: string; // Router state
  icon?: string; // Material icon NAME
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  role?: string;
}
interface IChildItem {
  type?: string;
  name: string; // Display TEXT
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  isLda: boolean = environment.groupType === 'lda' ? true : false;
  isPtm: boolean = environment.groupType === 'ptm' ? true : false;
  isTc: boolean = environment.groupType === 'tc' ? true : false;

  constructor(
    private dataService: DataService,
  ) { }

  plainMenu: IMenuItem[] = [
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Events',
      icon: 'assets/icons/MN-Cockpit.svg',
      state: 'dashboard',
      role: 'PermDashboardBasic',
    },
    {
      name: 'MY_LEADS',
      type: 'link',
      tooltip: 'My Leads',
      icon: 'assets/icons/MN-MyLeads.svg',
      state: 'lead',
      role: 'PermLeadBasic',
    },
    {
      name: 'ALL_LEADS',
      type: 'link',
      tooltip: 'All Leads',
      icon: 'assets/icons/MN-All-Leads.svg',
      state: 'contacts',
      role: 'PermContactsBasic'
    },
    {
      name: 'CHANNELS',
      type: 'link',
      tooltip: 'Events',
      icon: 'assets/icons/MN-Groups.svg',
      state: 'channel',
      role: 'PermChannelsBasic',
    },
    {
      name: 'TEAM',
      type: 'link',
      tooltip: 'team',
      icon: 'assets/icons/MN-Team.svg',
      state: 'team',
      role: '',
    },
    {
      name: this.isLda ? 'SQUADS' : 'PARTNER',
      type: 'link',
      tooltip: this.isLda ? 'squads' : 'partner',
      icon: 'assets/icons/MN-Partner.svg',
      state: this.isLda ? 'squads' : 'partner',
      role: 'PermSquadsBasic',
    },
    {
      name: 'FILES',
      type: 'link',
      tooltip: 'Files',
      icon: 'assets/icons/MN-Files.svg',
      state: 'files',
      role: 'PermFilesBasic',
    },

    {
      name: 'ORDERS',
      type: 'link',
      tooltip: 'Orders',
      icon: 'assets/icons/tele-lead.svg',
      state: 'orders',
      role: 'PermOrderBasic'
    },
    {
      name: 'CUSTOMERS',
      type: 'link',
      tooltip: 'Customers',
      icon: 'assets/icons/tele-customer.svg',
      state: 'customers',
      role: 'PermCustomerBasic'
    },
    {
      name: 'NUTZER',
      type: 'link',
      tooltip: 'Users',
      icon: 'assets/icons/MN-User.svg',
      state: 'users',
      role: 'PermUserBasic'
    },
    {
      name: 'PORTFOLIO',
      type: 'link',
      tooltip: 'portfolio',
      icon: 'assets/icons/tele-comunication.svg',
      state: 'portfolio',
      role: 'PermPortfolioBasic'
    },
    {
      name: 'PAGES',
      type: 'link',
      tooltip: 'Pages',
      icon: 'assets/icons/MN-Page.svg',
      state: 'page',
      role: 'PermPagesBasic',
    },
    {
      name: 'SOLUTIONS',
      type: 'link',
      tooltip: 'Solutions',
      icon: 'assets/icons/MN-solution.svg',
      state: 'solution',
      role: 'PermSolutionBasic',
    },
    {
      name: 'SUPPORT',
      type: 'link',
      tooltip: 'Support',
      icon: 'assets/icons/MN-support.svg',
      state: 'support',
      role: 'PermSupportBasic'
    },
    {
      name: 'NEWS',
      type: 'link',
      tooltip: 'News',
      icon: 'assets/icons/MN-News.svg',
      state: 'news',
      role: 'PermNewsBasic'
    },
    {
      name: 'TASK_LIST',
      type: 'link',
      tooltip: 'TASK_LIST',
      icon: 'assets/icons/MN-Task.svg',
      state: 'tasklist',
      role: ''
    },
    {
      name: 'SETTINGS',
      type: 'link',
      tooltip: 'Settings',
      icon: 'assets/icons/MN-Settings.svg',
      state: 'setting',
      role: ''
    }
  ];

  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.plainMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  publishNavigationChange(menuType: string, menu) {
    if (menu) {
      this.menuItems.next(menu);
    }
  }

  setupForUserRole(params) {
    // HACK
    const partner = this.dataService.getPartner(true);
    let menu = this.plainMenu;
    menu = _.filter(menu, (o) => {
      if ((o.role === 'PermContactsBasic' || o.role === 'PermDashboardBasic') && partner.isAdmin) {
        return true;
      }
      return o.role === '' || this.dataService.checkRole(o.role);
    });
    // Show hide menu in others project
    let toRemoveMenus = [];
    if (this.isPtm || this.isLda) {
      toRemoveMenus = ['TASK_LIST', 'NEWS', 'PAGES', 'ORDERS', 'CUSTOMERS', 'PORTFOLIO', 'SUPPORT', 'SOLUTIONS'];
    } else if (this.isTc) {
      toRemoveMenus = ['DASHBOARD', 'MY_LEADS', 'ALL_LEADS', 'PARTNER', 'TEAM'];
    }

    if (!this.dataService.checkRole('PermPermissions') && !this.dataService.checkRole('PermPortfolio')) {
      toRemoveMenus.push('SETTINGS');
    }

    if (!partner.id) {
      toRemoveMenus.push('TEAM');
    }
    if (toRemoveMenus.length > 0) {
      for (let i = menu.length - 1; i >= 0; i--) {
        if (toRemoveMenus.indexOf(menu[i].name) >= 0) {
          menu.splice(i, 1);
        }
      }
    }

    this.publishNavigationChange('plainMenu', menu);
  }
}
