import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DataService } from 'app/shared/services/data.service';
import jwt_decode from 'jwt-decode';
import { AuthService } from 'app/shared/services/auth.service';
import { environment } from 'environments/environment';
import { CookieService } from 'ng2-cookies';

@Component({
  selector: 'app-jwt-load',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class JWTComponent implements OnInit {
  constructor(
    public dataService: DataService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    public router: Router,
  ) {

  }

  ngOnInit() {
    const token = this.route.snapshot.queryParamMap.get('token');
    if (token) {
      const decoded: any = jwt_decode(token);
      const now = Date.now() / 1000;
      if (!decoded || decoded.exp < now) {
        this.goto();
      }
      this.cookieService.set('refreshToken', token, undefined, '/', environment.domain);
      this.authService.getNewToken().subscribe((res) => {
        if (res && res.data) {
          const data: any = res.data;
          if (data.refreshToken) {
            this.authService.setAuthToken(data.refreshToken);
            this.getMySelf();
          } else {
            this.goto();
          }
        } else {
          this.goto();
        }
      }, (e) => {
        this.goto();
      });
    } else {
      this.goto();
    }
  }

  goto(router = '/auth/sign-in') {
    this.router.navigate([router]);
  }

  getMySelf() {
    setTimeout(() => {
      this.authService.me().subscribe(({ data }: { data: any }) => {
        if (data && data.myself && data.myself.data) {
          const info = data.myself.data;
          this.dataService.setUserFromApiResData(info);
          this.dataService.setGroupAndRole({}, info.permRoles || [], '', {}, info.orgs, info.groups);
          this.dataService.storeSData();
          const router = this.route.snapshot.queryParamMap.get('router');
          if (router) {
            this.goto(router);
          } else {
            this.goto('/');
          }
        } else {
          this.getMySelf();
        }
      }, (e) => {
        this.getMySelf();
      });
    }, 1000);
  }
}


