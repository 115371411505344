import { PartnerService } from './../../../../partner/partner.service';
import { MessageService } from 'app/shared/services/MessageService';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import Utils from 'app/shared/utils/utils';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GroupService } from 'app/views/group/group.service';
import { environment } from 'environments/environment';
const _ = require('lodash');

@Component({
  selector: 'app-dialog-choice',
  templateUrl: 'dialog-choice.component.html',
  styleUrls: ['./dialog-choice.component.scss']
})
export class DialogChoicePartnerComponent implements OnInit {
  isLda: boolean = environment.groupType === 'lda' ? true : false;
  isPtm: boolean = environment.groupType === 'ptm' ? true : false;
  loading: any = false;
  disabled = true;
  listArray: any = []; // Mảng dữ liệu lấy từ API
  total = 0; // Tổng số bản ghi
  objChoice: any = {};
  params: any = {
    parentId: null, orderBy: 'createdAt', orderDir: 'DESC',
    page: 1, pageSize: 10, searchText: '', filter: 'IS_LDA', fromGroup: true
  };
  constructor(
    public dialogRef: MatDialogRef<DialogChoicePartnerComponent>,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public groupService: GroupService,
    private translateService: TranslateService,
    public snackBar: MatSnackBar,
    public messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }

  ngOnInit() {
    this.doGetData();
  }

  doGetData() {
    const { router, snackBar, translateService } = this;
    this.loading = true;
    this.groupService.apiGetParner(this.params).subscribe(({ data }) => {
      this.loading = false;
      const apiRes: any = data;
      if (apiRes.adOrgs && apiRes.adOrgs.entities) {
        this.listArray = apiRes.adOrgs.entities;
        this.total = apiRes.adOrgs.total;
      } else {
        Utils.handleApiError(null, { router, snackBar, translateService });
      }
      if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }

    }, error => {
      Utils.handleApiError(error, { router, snackBar, translateService });
      this.loading = false;
      if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
    });
  }

  // Sự kiệm bấm nút sang trang
  onPage(page) {
    this.params.page = page;
    this.doGetData();
  }
  // Tính ra số trang
  totalPage() {
    const totalPage = Math.ceil(this.total / this.params.pageSize);
    return new Array(totalPage).fill(1);
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  getArr(obj) {
    return _.values(obj);
  }

  choicePartner(row) {
    if (this.objChoice[row.id]) {
      delete this.objChoice[row.id];
    } else {
      this.objChoice[row.id] = row;
    }
  }

  removePartner(row) {
    delete this.objChoice[row.id];
  }

  onSubmit() {
    const ids = _.map(_.values(this.objChoice), 'id');
    this.dialogRef.close(ids);
  }

  onChangeSearch(e) {
    this.params.searchText = e.searchText.trim();
    this.doGetData();
  }

  onChangeRegion() {
    this.doGetData();
  }
}
