import { environment } from './../../../../environments/environment';
import { MessageService } from './../../services/MessageService';
import {
  Component,
  OnInit,
  Inject,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
const _ = require('lodash');

@Component({
  selector: 'user-add-item',
  templateUrl: 'representative.component.html',
  styleUrls: ['./representative.component.scss'],
})
export class RepresentativeComponent implements OnInit {
  title = '';
  headtitle = '';
  params: any = {
    orderBy: 'createdAt',
    orderDir: 'DESC',
    searchText: '',
    page: 1,
    pageSize: 10,
  };
  link: string = environment.mediaUrl;
  choice: any = {};
  list: any = [];
  loading = false;
  multi = true;
  total = 0; // Tổng số bản ghi
  totalPage = 0;
  type = '';
  moreparam: any = {};
  disableId = '';
  constructor(
    public dialogRef: MatDialogRef<RepresentativeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translateService: TranslateService,
    private userService: UserService,
    public snackBar: MatSnackBar,
    public messageService: MessageService,
  ) {
    this.type = data.type ? data.type : '';
    this.title = data.title ? data.title : '';
    this.headtitle = data.headtitle ? data.headtitle : '';
    this.moreparam = data.moreparam ? data.moreparam : {};
    this.choice = _.keyBy(this.data.admins, 'id') || [];
    this.disableId = this.data.ownerId;
  }

  ngOnInit() {
    this.apiSearch();
  }

  onChangeSearch(e) {
    this.params.searchText = e.searchText.trim();
    this.apiSearch();
  }

  apiSearch() {
    this.loading = true;
    if (this.type === '') {
      this.userService.users({ ...this.params, ...this.moreparam }).subscribe(
        ({ data }) => {
          const apiRes: any = data;
          if (apiRes.users.entities) {
            this.list = apiRes.users.entities;
            this.total = apiRes.users.total;
            this.totalPage = Math.ceil(this.total / this.params.pageSize);
          } else {
            this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
          this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
        },
      );
    } else {
      this.userService.adOrgListUserForAdding({ ...this.params, ...this.moreparam }).subscribe(
        ({ data }) => {
          const apiRes: any = data;
          if (apiRes.adOrgListUserForAdding.entities) {
            this.list = apiRes.adOrgListUserForAdding.entities;
            this.total = apiRes.adOrgListUserForAdding.total;
            this.totalPage = Math.ceil(this.total / this.params.pageSize);
          } else {
            this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
          this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
        },
      );
    }
  }


  // Sự kiệm bấm nút sang trang
  onPage(page) {
    this.params.page = page;
    this.apiSearch();
  }


  onChoice(row) {
    if (this.multi) {
      if (this.choice && this.choice[row.id]) {
        delete this.choice[row.id];
      } else {
        this.choice[row.id] = row;
      }
    } else {
      this.dialogRef.close(row);
    }
  }

  checked() {
    if (_.values(this.choice).length > 0) { return true; }
    return false;
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  onBtnDialogOk() {
    this.dialogRef.close(_.values(this.choice));
  }

  convertArray() {
    return _.values(this.choice);
  }

  createPageView() {
    // console.log('totalPage', this.totalPage);
    if (this.totalPage < 6) {
      return new Array(this.totalPage).fill(1);
    } else {
      if (this.params.page === 1 || this.params.page === 2 || this.params.page === 3) {
        return [1, 2, 3, 4, '...', this.totalPage];
      } else if (this.params.page === this.totalPage ||
        this.params.page === this.totalPage - 1 ||
        this.params.page === this.totalPage - 2) {
        return [1, '...', this.totalPage - 3, this.totalPage - 2, this.totalPage - 1, this.totalPage];
      } else {
        return [1, '...', this.params.page - 1, this.params.page, this.params.page + 1, '...', this.totalPage];
      }
    }
  }
}
