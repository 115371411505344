import { CropperComponent } from 'angular-cropperjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, Inject, ViewChild, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-popup-avatar',
  templateUrl: './popup-avatar.component.html',
  styleUrls: ['./popup-avatar.component.scss']
})
export class PopupAvatarComponent implements OnInit {
  @ViewChild('angularCropper', { static: false }) public angularCropper: CropperComponent;
  imageUrl: string = "";
  mode: string = 'move'
  config: any = {
    dragMode: 'move',
    aspectRatio:  1.5,
    movable: true,
    scalable: true,
    zoomable: true,
    background: false,
    zoomOnWheel: false
  }
  css:string='';
  info: any = null;
  constructor(
    public dialogRef: MatDialogRef<PopupAvatarComponent>,
    public snackBar: MatSnackBar,
    private santinizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.imageUrl = data.imageUrl || this.santinizer.bypassSecurityTrustUrl(data.url)
    this.info = data.info
    this.css = data.css
    if(data.ratio)
    {
      this.config.aspectRatio = data.ratio;
    }
  }

  ngOnInit() {
  }
 
  onClickCancel() {
    this.dialogRef.close();
  }

  onSliderChange(e) {
    this.angularCropper.cropper.zoomTo(e.value);
  }

  onSubmit() {
    const data = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg')
    const file = this.DataURIToBlob(data);
    this.dialogRef.close({ file, src: data });
  }
  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

    return new File([ia], (new Date()).getTime() + '.jpg', { type: mimeString })
  }
}
