import { DataService } from 'app/shared/services/data.service';
import {
  Component,
  OnInit,
} from '@angular/core';
import { MessageService } from 'app/shared/services/MessageService';


@Component({
  selector: 'order-list-headbar',
  templateUrl: './order-list-headerbar.template.html',
  styleUrls: ['./order-list-headerbar.component.scss']
})
export class OrderListHeaderbar implements OnInit {
  
  constructor(  public dataService: DataService,public messageService: MessageService) {

   
  }
  ngOnInit(): void {
    /*const a =  ['PermUserBasic', 'PermUserAdd', 'PermUserSelect', 'PermUserDelete', 'PermUserActivate', 'PermUserBlock', 'PermUserImport', 'PermUserExport', 'PermUserData', 'PermUserInfo', 'PermUserPassword', 'PermCustomerBasic', 'PermUserBasic', 'PermUserAdd', 'PermUserSelect', 'PermUserDelete', 'PermUserActivate', 'PermUserBlock', 'PermUserImport', 'PermUserExport', 'PermUserData', 'PermUserInfo', 'PermUserPassword', 'PermManageCustomer', 'PermEditCustomer', 'PermFilesBasic', 'PermMyCollection', 'PermGroupCollections', 'PermAllCollections', 'PermManageCollection', 'PermManageData', 'PermAdminData', 'PermChannelsBasic', 'PermAllGroups', 'PermPublishGroups', 'PermManageChannels', 'PermOrderBasic', 'PermAllOrders', 'PermManageOrders', 'PermEditOrders']*/
  }

}
