import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';
const _ = require('lodash');

@Component({
  selector: 'app-customer-consent',
  templateUrl: 'customer-consent.component.html',
  styleUrls: ['./customer-consent.component.scss'],
})
export class CustomerConsentDialogComponent implements OnInit {
  isLda:boolean =  environment.groupType === 'lda'?true:false
  isPtm:boolean =  environment.groupType === 'ptm'?true:false
  consent: any = { customerConsent: false };
  loading: any = false;
  disabled: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CustomerConsentDialogComponent>,
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
  ngOnInit() {
    this.scrollToBottom();
    
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  ngAfterViewInit() {}

  scrollToBottom() {
    
  }
  onSubmit() {
    this.dialogRef.close({ customerConsent: true });
  }
}
