import { FormGroup } from '@angular/forms';
export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config = {
      'required': 'Bitte füllen Sie dieses Feld aus.',
      'invalidCreditCard': 'Diese Kreditkartennummer ist ungültig.',
      'invalidEmail Address': 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      'invalidmobileNumber': 'Bitte geben Sie eine gültige Telefonnummer ein.',
      'invalidPin': 'Bitte geben Sie eine gültige Pin ein.',
      'invalidPassword': 'Das Passwort ist ungültig. Es muss 8-32 Zeichen lang sein, eine Zahl, ' +
        'ein Sonderzeichen sowie Groß- udn Kleinschreibung umfassen.',
      'minlength': `Diese Eingabe ist zu kurz. Bitte mindestens ${validatorValue.requiredLength} Zeichen verwenden.`,
      'maxlength': `Diese Eingabe ist zu lang. Bitte maximal ${validatorValue.requiredLength} Zeichen verwenden.`,
      'invalidMinOneSelection': `Es muss mindestens ein Eintrag ausgewählt werden.`,
      'invalidURL': `Bitte geben Sie eine gültige URL ein, die mit "http" oder "https" beginnt.`,
      'invalidZip': `Bitte geben Sie eine gültige Postleitzahl.`,
      'passwordEqual': `Die beiden Passwörter stimmen nicht überein.`
    };

    return config[validatorName];
  }

  static creditCardValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    let creditCardRe = '^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|'
      + '3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})?$'

    if (control.value.match(creditCardRe)) {
      return null;
    } else {
      return { 'invalidCreditCard': true };
    }
  }

  static emailValidator(control) {
    if (!control.value) return null; // If no value is set, we do not validate. If this is wanted, combine with Validators.required
    // RFC 2822 compliant regex
    let emailRe = '[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+'
      + '[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?'

    if (control.value.match(emailRe)) {
      return null;
    } else {
      return { 'invalidEmail Address': true };
    }
  }

  static phoneValidator(control) {
    // RFC 2822 compliant regex
    if (control.value.match(/^([0-9\-+\s]{8,})?$/i)) {
      return null;
    } else {
      return { 'invalidmobileNumber': true };
    }
  }

  static urlValidator(control) {
    if (!control.value) return null; // If no value is set, we do not validate. If this is wanted, combine with Validators.required

    if (control.value.match(/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/)) {
      return null;
    } else {
      return { 'invalidURL': true };
    }
  }

  static zipValidator(control) {
    if (!control.value) return null; // If no value is set, we do not validate. If this is wanted, combine with Validators.required

    if (control.value.match(/\d{4,5}/)) {
      return null;
    } else {
      return { 'invalidZip': true };
    }
  }

  static passwordValidator(control) {
    if (!control.value) return null; // If no value is set, we do not validate. If this is wanted, combine with Validators.required

    // Regular expression to validate password strength using positive lookahead
    // Warning: The password is also validated server-side. If you change this validator,
    // you also need to change the validator in data/api/core/authenticate.js

    /*
    ^                         Start anchor
    (?=.*[A-Z])               Ensure string has one uppercase letter.
    (?=.*[0-9])               Ensure string has one number.
    (?=.*["§/()=+~'_:.,;$@!%*#?&-])          Ensure string has one special case letter.
    (?=.*[a-z])               Ensure string has one lower case letter.
    .{8,32}                   Ensure string is of length 8 to 32.
    $                         End anchor.
    !@#$%^&*()_+
    !, @, #, $, %, ^, &, *, (, ), _, +!@#$%^&*()_+
     */
    const passwordSecurity = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])(?=.*[a-z]).{12,32}$/;

    if (control.value.match(passwordSecurity)) {
      return null;
    } else {
      return { 'invalidPassword': true };
    }
  }

  static folderValidator(control) {
    if (!control.value) {
      return null;
    } // If no value is set, we do not validate. If this is wanted, combine with Validators.required
    const folderSecurity = /^([a-zA-Z0-9\-_öäüß. ]*.)$/;
    if (folderSecurity.test(control.value + ' ')) {
      return null;
    } else {
      return { 'invalidFolder': true };
    }
  }

  static minOneSelectionValidator(control) {
    if (control.value.length === 0) {
      return { 'invalidMinOneSelection': true };
    } else {
      return null;
    }
  }

  static passwordEqualValidator(group: FormGroup) {
    let valid = true;
    let firstValue;

    for (let name in group.controls) {
      if (group.controls.hasOwnProperty(name)) {
        let control = group.controls[name];
        if (!firstValue) firstValue = control.value;
        if (control.value !== firstValue) valid = false;
      }
    }

    return valid ? null : { 'passwordEqual': true };
  }

  static pathValidator(root, props) {
    let path = [], prop;

    if (!root) {
      return false
    }

    if (typeof props === 'string') {
      path = props ? props.split('.') : []
    } else {
      return false
    }

    while (prop = path.shift()) {
      try {
        if (prop in root) {
          root = root[prop];
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    }
    return true;
  }

  static pinValidator(control) {
    if (control.value.match(/(^[0-9]{4}$|^$)/i)) {
      return null;
    } else {
      return { 'invalidPin': true };
    }
  }
}
