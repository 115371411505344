import {
  Component,
  Type,
  OnDestroy,
  AfterViewInit,
  ComponentRef,
  ViewChild,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { InsertionDirective } from '../insertion.directive';
import { DialogRef } from '../dialog-ref';
import { DialogConfig } from '../dialog-config';
import { Action } from '../dialog.service';

@Component({
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements AfterViewInit, OnDestroy {
  componentRef: ComponentRef<any>;
  childComponentType: Type<any>;
  actions:Action = null
  @ViewChild(InsertionDirective, { static: false })
  insertionPoint: InsertionDirective;

  private readonly _onClose = new Subject<any>();
  public onClose = this._onClose.asObservable();
  opened = false;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private dialogRef: DialogRef,
    public dialogConfig: DialogConfig,
  ) {}

  ngAfterViewInit(): void {
    this.loadChildComponent(this.childComponentType);
    this.cd.detectChanges();
    const style = document.createElement('style');
    style.textContent = `
    .modal__window{
      animation: fadeIn .3s;
      max-height:70% !important;
    }
    .modal__body-wrapper{
      padding-left: 23px !important;
      padding-right: 13px !important;
    }

    .modal__body-wrapper::-webkit-scrollbar{
      width: 8px;
      background-color: #efefef;
    }
    .modal__body-wrapper::-webkit-scrollbar-thumb{
      border-radius: 8px;
      background-color: #ccc;
    }
    @keyframes fadeIn {
      0%{
        opacity: 0;
        transform: scale(.5);
      }
      100%{
        opacity: 1;
        transform: scale(1);
      }
    }
    `;
    document.getElementById('modal').shadowRoot.appendChild(style); // ghi đè style của modal
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  onOverlayClicked(evt: MouseEvent): void {
    this.dialogRef.close();
    this.close();
  }

  loadChildComponent(componentType: Type<any>): void {
    let componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(componentType);

    let viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
  }

  onSubmit() {
    if (this.actions) {
      this.actions.onSubmit()
    }
  }
  onCancel() {
    this.dialogRef.close()
  }

  close(): void {
    this._onClose.next();
  }
}
