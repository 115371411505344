import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Event, NavigationCancel } from '@angular/router';
import { environment } from 'environments/environment';
import { CookieService } from 'ng2-cookies';

@Injectable()
export class AuthGuard implements CanActivate {
  mktUrl = environment.mktUrl;
  public authToken;
  private isAuthenticated = false; // Set this value dynamically
  private saveUrl = '';
  constructor(private router: Router, public cookieService: CookieService) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationCancel) {
        if (event.url.indexOf('/outs') === -1) {
          this.saveUrl = event.url;
          if (this.saveUrl) {
            localStorage.setItem('saveUrl', this.saveUrl);
          }
        }
      }
    });
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // HACK
    const token = this.cookieService.get('token');
    const refreshToken = this.cookieService.get('refreshToken');
    if (token || refreshToken) {
      this.isAuthenticated = true;
    }
    if (this.isAuthenticated) {
      return true;
    }
    window.location.href = this.mktUrl + 'auth/sign-in';
    return false;
  }
}
