import { MatDialog } from '@angular/material/dialog';
import { LayoutService } from './shared/services/layout.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';

export class ParentComponent {
  public isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
  constructor(
    public breakpointObserver: BreakpointObserver,
    public layoutService: LayoutService,
    public dialog: MatDialog,
  ) {

  }

  public showModal(Component: any, data: any = {}, option: any = {}, responsive = true): any {
    const locations = new Observable((observer) => {
      const width = '500px';
      const curentOption = { width, ...option };
      const dialogRef = this.dialog.open(Component, { ...curentOption, data, autoFocus: false });
      let smallDialogSubscription = null;
      if (responsive) {
        smallDialogSubscription = this.isExtraSmall.subscribe((size) => {
          if (size.matches) {
            dialogRef.updateSize('100%', '100%');
          } else {
            dialogRef.updateSize(curentOption.width || width, curentOption.height || '75%');
          }
        });
      }
      dialogRef.afterClosed().subscribe((dialogRes) => {
        if (responsive) { smallDialogSubscription.unsubscribe(); }
        observer.next(dialogRes);
      }, (err) => {
        observer.error(err);
      });
    });
    return locations;
  }


}
