import { Component, OnInit, NgZone, Inject, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA }  from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import Utils from '../../../shared/utils/utils';
import { FileService } from '../file.service';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../../../shared/services/data.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MessageService } from 'app/shared/services/MessageService';

@Component({
  selector: 'app-file-rename-dialog',
  templateUrl: 'file-rename-dialog.component.html',
  styleUrls: ['./file-rename-dialog.component.css']
})
export class FileRenameDialogComponent implements OnInit {
  params: any = {
    success: false,
  };
  formData: FormGroup;
  fileUpload = null;
  constructor(
    public dialogRef: MatDialogRef<FileRenameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    myElement: ElementRef,
    public translateService: TranslateService,
    private fileService: FileService,
    private dataService: DataService,
    public snackBar: MatSnackBar,
    private fb: FormBuilder,
    public messageService : MessageService
  ) {
    console.log('FileRenameDialogComponent this.data.file:', this.data.file);
    this.formData = this.fb.group({
      title: [ '', [Validators.required] ],
    });
  }
  ngOnInit() {
    setTimeout(() => {
      let title = Utils.baseName(this.data.file.title);
      this.formData.controls['title'].setValue(title);
    });
    
  }
  onClickCancel() {
    console.log('onClickCancel');
    this.closeDialog(false);
  }
  onBtnDialogOk() {
    console.log('onBtnDialogOk this.formData.value:', this.formData.value);
    let params = {
      ...this.formData.value,
      fileId: this.data.file.id,
    };
    this.fileService.fileRename(params).subscribe(({ data }) => {
      let apiRes: any = data;
      console.log('fileRename apiRes: ', apiRes);
      if (apiRes.fileRename.data) {
        this.messageService.showSuccess('UPDATED_', 'I_GENERAL_009')
        this.closeDialog(true);
      } else {
        this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002')
      }
    }, error => {
      console.warn('fileRename error:', error);
      this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002')
    });
  }
  closeDialog(success) {
    this.params.success = success;
    this.dialogRef.close(this.params);
  }
}
