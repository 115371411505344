import {
  Component,
  Type,
  OnDestroy,
  AfterViewInit,
  ComponentRef,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { InsertionDirective } from '../insertion.directive';
import { DialogConfirm } from '../dialog-config';
import { ConfirmRef } from '../confirm-ref';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogConfirmComponent implements AfterViewInit, OnDestroy {
  componentRef: ComponentRef<any>;

  @ViewChild(InsertionDirective, { static: false })
  insertionPoint: InsertionDirective;

  private readonly _onClose = new Subject<any>();
  public onClose = this._onClose.asObservable();
  opened = false;
  constructor(
    private cd: ChangeDetectorRef,
    private confirmRef: ConfirmRef,
    public dialogConfig: DialogConfirm,
    public translateService: TranslateService,
  ) {}

  ngAfterViewInit(): void {
    this.cd.detectChanges();
    const style = document.createElement('style');
    style.textContent = `
    .modal__window{
      animation: fadeIn .3s;
    }
    @keyframes fadeIn {
      0%{
        opacity: 0;
        transform: scale(.5);
      }
      100%{
        opacity: 1;
        transform: scale(1);
      }
    }
    `;
    document.getElementById('modal_confirm').shadowRoot.appendChild(style);
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  onOverlayClicked(evt: MouseEvent): void {
    this.confirmRef.close();
  }
  onConfirm(){
    this.confirmRef.close(true)
  }
  onDiscard(){
    this.confirmRef.close(false)
  }
}
