import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog-modal/dialog.component';
import { InsertionDirective } from './insertion.directive';
import { DialogConfirmComponent } from './dialog-confirm/dialog.component';
import { SharedPipesModule } from 'app/shared/pipes/shared-pipes.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [DialogComponent, DialogConfirmComponent, InsertionDirective],
  entryComponents: [DialogComponent, DialogConfirmComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class DialogModule { }
