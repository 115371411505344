import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadService } from '../../../../lead.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingService } from '../../../../../setting/setting.service';
import * as uuid from 'uuid';
import { environment } from 'environments/environment';
import { DataService } from 'app/shared/services/data.service';
import { MessageService } from 'app/shared/services/MessageService';
const _ = require('lodash');

@Component({
  selector: 'app-select-product-dialog',
  templateUrl: 'select-product-dialog.component.html',
  styleUrls: ['./select-product-dialog.component.scss'],
})
export class SelectProductDialogComponent implements OnInit {
  isLda: boolean = environment.groupType === 'lda' ? true : false;
  isPtm: boolean = environment.groupType === 'ptm' ? true : false;
  products: any = { title: '' };
  productsLastUse: any = [];
  loading: any = false;
  disabled = true;
  step = 1;
  keywordSearch: String = '';
  topicsFilter: any = [];
  topics: any = [];
  consultationLead: any = {};
  setting: any = {};
  topic: any = null;
  params: any = {};
  detail: any = {};
  constructor(
    public dialogRef: MatDialogRef<SelectProductDialogComponent>,
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private settingService: SettingService,
    private dataService: DataService,
    public leadService: LeadService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public messageService: MessageService
  ) {
    this.consultationLead = this.data.consultationLead;
    this.setting = this.data.setting;
    this.detail = this.data.detail;
    this.getProductLocalStorage();
    if (this.data.type === 'add') {
      this.step = 1;
      this.callApiGetTopics();
    } else {
      this.step = 2;
    }
  }

  callApiGetTopics() {
    if (this.data.idGroup) {
      this.loading = true;
      this.leadService.apiGetGroupDetail(this.data.idGroup).subscribe(
        (data) => {
          this.loading = false;
          const resApi: any = data;
          this.topics = resApi.data.group.topics;
          this.topicsFilter = resApi.data.group.topics;
          console.log('this.topics', this.topics);

        },
        (error) => {
          this.loading = false;
          this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
        },
      );
    }
  }

  ngOnInit() { }

  onClickBack() {
    this.step = 1;
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  checkUse(product) {
    return this.data.consultationLeads.map((cl) => cl.product ? cl.product.id : null).includes(product.id);
  }



  onClickDelete() {
    this.dialogRef.close({
      type: 'delete',
    });
  }

  handleSelectProduct(product, topic) {
    console.log('topic', topic);
    if (product) {
      this.step = 2;
      this.consultationLead.product = product;
      this.setProductLocalStorage(product, topic);
    }

  }

  handleSelectOwnProduct() {
    this.step = 3;
    this.consultationLead = {
      id: uuid.v4(),
      note: '',
      budget: '',
      leadType: '',
      partnerProductName: '',
    };
  }

  ngAfterViewInit() { }



  onChangeSearch(e) {
    this.keywordSearch = e.searchText.trim();
    const newTopics = this.topics.map((item) => {
      const countProduct = item.products.filter(
        (product) =>
          product.title
            .toLowerCase()
            .indexOf(this.keywordSearch.toLowerCase()) >= 0,
      ).length;
      if (countProduct > 0) {
        return {
          ...item,
          products: item.products.filter(
            (product) =>
              product.title
                .toLowerCase()
                .indexOf(this.keywordSearch.toLowerCase()) >= 0,
          ),
        };
      }
    });
    this.topicsFilter = newTopics.filter((item) => item !== undefined);
    this.getProductLocalStorage();
    if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
  }

  setProductLocalStorage(product, topic) {
    if (topic) {
      product.topic = topic.title;
      this.topic = topic;
    } else if (product.topic) {
      this.topic = { title: product.topic };
    }
    const dataJson = localStorage.getItem('PRODUCT_LEAD');
    let groups;
    if (dataJson) {
      const groups = JSON.parse(dataJson);
      const groupCurrentIndex = groups.findIndex(
        (item) => item.id === this.data.idGroup,
      );
      if (groupCurrentIndex >= 0) {
        const productIndex = groups[groupCurrentIndex].products.findIndex(
          (item) => item.id === product.id,
        );
        if (productIndex >= 0) {
          groups[groupCurrentIndex].products.splice(productIndex, 1);
          groups[groupCurrentIndex].products.unshift(product);
        } else if (productIndex < 0) {
          groups[groupCurrentIndex].products.unshift(product);
          return localStorage.setItem('PRODUCT_LEAD', JSON.stringify(groups));
        }
      } else {
        groups.push({ id: this.data.idGroup, products: [product] });
        return localStorage.setItem('PRODUCT_LEAD', JSON.stringify(groups));
      }
    } else {
      groups = [{ id: this.data.idGroup, products: [product] }];
      return localStorage.setItem('PRODUCT_LEAD', JSON.stringify(groups));
    }
  }

  getProductLocalStorage() {
    const dataJson = localStorage.getItem('PRODUCT_LEAD');
    if (dataJson) {
      const groups = JSON.parse(dataJson);
      const groupCurrent = groups.find((item) => item.id === this.data.idGroup);
      if (groupCurrent) {
        this.productsLastUse = groupCurrent.products;
      }
    }
    console.log('productsLastUse', this.productsLastUse);
  }

  onClickSave() {
    if (this.isLda) {
      this.dialogRef.close({
        type: this.data.type,
        data: { ...this.consultationLead, budget: 0 },
      });
    } else {
      this.dialogRef.close({
        type: this.data.type,
        data: this.consultationLead,
      });
    }
  }

  isDisableSave() {
    if (this.isPtm) {
      if (this.consultationLead.product) {
        return (
          !this.consultationLead.product ||
          !this.consultationLead.product.title ||
          this.consultationLead.product.title.trim() === '' ||
          !this.consultationLead.note ||
          this.consultationLead.note.trim() === '' ||
          this.consultationLead.budget === '' ||
          +this.consultationLead.budget < 0 ||
          this.consultationLead.budget === undefined ||
          this.consultationLead.budget === null
        );
      } else {
        return (
          !this.consultationLead.partnerProductName ||
          this.consultationLead.partnerProductName.trim() === '' ||
          !this.consultationLead.note ||
          this.consultationLead.note.trim() === '' ||
          this.consultationLead.budget === '' ||
          +this.consultationLead.budget < 0 ||
          this.consultationLead.budget === undefined ||
          this.consultationLead.budget === null
        );
      }
    } else {
      if (this.consultationLead.product) {
        return (
          !this.consultationLead.product ||
          !this.consultationLead.product.title ||
          this.consultationLead.product.title.trim() === '' ||
          !this.consultationLead.note ||
          this.consultationLead.note.trim() === ''
        );
      } else {
        return (
          !this.consultationLead.partnerProductName ||
          this.consultationLead.partnerProductName.trim() === '' ||
          !this.consultationLead.note ||
          this.consultationLead.note.trim() === ''
        );
      }
    }
  }

  replaceTemplate(temp) {
    const user = this.dataService.sData.user;
    const { firstName, lastName, mobile, email } = this.detail;
    temp = temp.replace('<Topic>', this.topic ? this.topic.title : '');
    temp = temp.replace('<Product>', this.consultationLead.product.title);
    temp = temp.replace('<Created by>', user.firstName + ' ' + user.lastName);
    temp = temp.replace('<eMail>', email || '');
    temp = temp.replace('<Firstname>', firstName || '');
    temp = temp.replace('<Lastname>', lastName || '');
    temp = temp.replace('<Phone>', mobile || '');
    return temp;
  }

  choiceTemplate(temp) {
    const user = this.dataService.sData.user;
    if (!this.consultationLead.note) {
      this.consultationLead.note = '';
    }
    const { firstName, lastName, mobile, email } = this.detail;
    temp = temp.replace('<Topic>', this.topic ? this.topic.title : '');
    temp = temp.replace('<Product>', this.consultationLead.product.title);
    temp = temp.replace('<Created by>', user.firstName + ' ' + user.lastName);
    temp = temp.replace('<eMail>', email || '');
    temp = temp.replace('<Firstname>', firstName || '');
    temp = temp.replace('<Lastname>', lastName || '');
    temp = temp.replace('<Phone>', mobile || '');
    if (this.consultationLead.note) this.consultationLead.note += ' ';
    this.consultationLead.note += temp;
    if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
  }

  onSubmit() {
    this.dialogRef.close(this.products);
  }

  validateOnlyNumbers(evt) {
    const theEvent = evt || window.event;
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  updateAmount($event) {
    this.consultationLead.budget = parseFloat($event.replace(/\D/g, ''));
  }
}


