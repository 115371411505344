import { Component, OnInit, ChangeDetectorRef, ViewChild, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { ParentComponent } from 'app/parent.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LayoutService } from 'app/shared/services/layout.service';
import { MessageService } from 'app/shared/services/MessageService';
import { LeadService } from 'app/views/lead/lead.service';
import { Observable, Subject } from 'rxjs';
import { WebcamComponent, WebcamImage, WebcamInitError } from 'ngx-webcam';
import { CropperComponent } from 'angular-cropperjs';
import { environment } from 'environments/environment';
import { OcrScanDialogComponent } from '../dialog/ocr-scan.component';
const _ = require('lodash');

@Component({
  selector: 'app-vcard-scan-content',
  templateUrl: 'vcard-scan.component.html',
  styleUrls: ['./vcard-scan.component.scss']
})
export class VcardScanDetailComponent extends ParentComponent implements OnInit {
  @Input() lead: any;
  @Input() name: string;
  @Output() onSave = new EventEmitter();
  @Output() onClose = new EventEmitter();
  link: string = environment.mediaUrl + 'fs/files/uploads/';
  @ViewChild('angularCropper', { static: false }) public angularCropper: CropperComponent;
  @ViewChild('webcam', { static: false }) webcam: ElementRef;
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  public webcamImage: WebcamImage = null;
  public errors: WebcamInitError[] = [];
  public videoOptions: MediaTrackConstraints = {
    facingMode: { ideal: 'environment' },
    width: { ideal: 1024 },
    height: { ideal: 786 }
  };
  public deviceId: string;
  public allowCameraSwitch = true;
  step = 'CAMERA'; // CAMERA CROP SCAN
  bCard: any = null;
  bCardFileName: any = null;
  loading = false;
  widthfocus = 1024;
  heightfocus = 1024;
  config: any = { aspectRatio: 92 / 56 };
  constructor(
    public breakpointObserver: BreakpointObserver,
    public layoutService: LayoutService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    private router: Router,
    public messageService: MessageService,
    public route: ActivatedRoute,
    public leadService: LeadService,
  ) {
    super(breakpointObserver, layoutService, dialog);

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      this.widthfocus = this.webcam.nativeElement.offsetWidth;
      console.log('this.widthfocus', this.widthfocus);
      console.log('width', this.webcam.nativeElement.offsetWidth);
    }, 100);
    //this.layoutService.adjustLayout(event);
  }

  ngOnInit() {
  }

  onBtnCapture() {
    this.trigger.next();
  }


  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }
  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.bCard = webcamImage['_imageAsDataUrl'];
    const image: any = new Image();
    image.src = this.bCard;
    image.onload = async () => {
      const height = image.naturalHeight;
      this.step = 'CROP';
      const w = this.widthfocus;
      const aspectRatio = 92 / 56;
      const h = w / aspectRatio;
      const y = (height - h) / 2;
      console.log(height, h, y);
      this.config = {
        aspectRatio, data: {
          x: 15,
          y,
          width: w - 30,
        }
      };
      setTimeout(() => {
        this.onSubmit();
      }, 200);
      return true;
    };
  }

  onRetake() {
    this.bCard = null;
    this.step = 'CAMERA';
  }

  onSliderChange(e) {
    this.angularCropper.cropper.zoomTo(e.value);
  }

  onChooiceFile(e) {
    const fileUpload = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileUpload);
    reader.onload = (event: any) => {
      this.bCard = event.target.result;
      this.step = 'CROP';
      // if (this.layoutService.isSm()) {
      //   this.uploadFile(fileUpload);
      // } else {
      //   this.step = 'CROP';
      // }
    };
  }

  onClickCancel() {
    this.onClose.emit();
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ia], (new Date()).getTime() + '.jpg', { type: mimeString });
  }

  onSubmit() {
    const data = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    const file = this.DataURIToBlob(data);
    this.uploadFile(file);
  }

  uploadFile(file) {
    this.loading = true;
    this.leadService.client_uploadBCard(this.lead.id, file).subscribe((data) => {
      this.loading = false;
      this.bCardFileName = data.data['client_uploadBCard'].data.filename;
      this.bCard = this.link + data.data['client_uploadBCard'].data.filename;
      this.cdr.detectChanges();
      this.step = 'SCAN';
      if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
    }, () => {
      this.loading = false;
      if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
      this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002');
    });
  }


  OCRScan() {
    this.showModal(OcrScanDialogComponent, { file: this.bCardFileName }, {}).subscribe((result) => {
      if (result) {
        const scaned = result.data;
        const dataVcard = {
          firstName: scaned.n ? scaned.n.first : (this.lead ? this.lead.firstName : ''),
          lastName: scaned.n ? scaned.n.last : (this.lead ? this.lead.lastName : ''),
          street: scaned.adr ? scaned.adr[0].value.street : (this.lead ? this.lead.street : ''),
          city: scaned.adr ? scaned.adr[0].value.city : (this.lead ? this.lead.city : ''),
          country: scaned.adr ? scaned.adr[0].value.country : (this.lead ? this.lead.country : ''),
          web: scaned.url ? scaned.url[0].value : (this.lead ? this.lead.web : ''),
          email: scaned.email ? scaned.email[0].value : (this.lead ? this.lead.email : ''),
          fax: scaned.fax ? scaned.fax : (this.lead ? this.lead.fax : ''),
          landline: scaned.tel && scaned.tel[0] ? scaned.tel[0].value : (this.lead ? this.lead.landline : ''),
          mobile: scaned.tel && scaned.tel[1] ? scaned.tel[1].value : (this.lead ? this.lead.mobile : ''),
          company: scaned.org ? scaned.org.name : (this.lead ? this.lead.company : '')
        };
        dataVcard[this.name] = this.bCardFileName;
        this.onSave.emit(dataVcard);
      }
    });
  }
}
