import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { DataService } from '../../shared/services/data.service';
import Utils from '../../shared/utils/utils';

@Injectable()
export class FilesService {
  constructor(private apollo: Apollo, private dataService: DataService) { }
  apiGetFiles(params) {
    return this.apollo.query({
      query: gql`query {
      files(${Utils.params2GqlStr(params)}) {
        entities {
          id title filename path isDir isHidden cache fsPerm type mimetype pageCount lang previewImg size status meta updatedAt createdAt
        }
        total
        breadcrumbs
      }}`,
      variables: {},
      fetchPolicy: 'no-cache',
    });
  }
  apiGetFile(fileId) {
    return this.apollo.query({
      query: gql`query {
      file(id: "${fileId}") {
        id title filename path isDir isHidden cache fsPerm type mimetype pageCount lang previewImg size status meta updatedAt createdAt
      }}`,
      variables: {},
      fetchPolicy: 'no-cache',
    });
  }
  apiFileCreate(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    fileCreate(${Utils.params2GqlStr(params)}) {
      id filename
    }}`, variables: {}, context: {}
    });
  }
  apiFilesUpdate(fileId, params) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        fileUpdate(${Utils.params2GqlStr(params)}) {
          data
        }
      }
    `});
  }

  apiFilesDelete(params) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        fileDelete(ids: ${JSON.stringify(params.ids)}) {
          data
        }
      }
    `});
  }

  apiGetClientFileCols(params) {
    return this.apollo.query({
      query: gql`
        query {
          client_fileCols {
            entities {id title rootFileId groupTitlesStr img userHasWritePerm updatedAt createdAt groups {id title} allowUserUpload}
            total
          }
        }
      `,
      variables: {
      },
      fetchPolicy: 'no-cache',
    });
  }

  apiGetFileCols(params) {
    return this.apollo.query({
      query: gql`
        query {
          fileCols(
            page: ${params.page},
            pageSize: ${params.pageSize},
            orderBy: "${params.orderBy}",
            orderDir: "${params.orderDir}",
            searchText: "${params.searchText}",
            filter: "${params.filter}",
            hideUserFileCols: ${(params.hideUserFileCols ? true : false)}
          ) {
            entities {
              id
              title
              rootFileId
              groups {id title}
              cache
              createdAt
              allowUserUpload
              updatedAt
            }
            total
          }
        }
      `,
      variables: {
      },
      fetchPolicy: 'no-cache',
    });
  }

  apiGetFileColDetail(params) {
    return this.apollo.query({
      query: gql`
        query {
          fileCols(input: {
            page: ${params.page},
            pageSize: ${params.pageSize},
            orderBy: "${params.orderBy}",
            orderDir: "${params.orderDir}",
            searchText: "${params.searchText}",
            filter: "${params.filter}"
          }) {
            entities {
              id
              title
              groups {id title}
              status
              updatedAt
            }
            total
          }
        }
      `,
      variables: {
      },
      fetchPolicy: 'no-cache',
    });
  }
  apiFileColCreate(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    fileColCreate(${Utils.params2GqlStr(params)}) {
      id
    }}`});
  }
  fileColUpdate(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    fileColUpdate(${Utils.params2GqlStr(params)}) {
      data
    }}`});
  }
  fileColDelete(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    fileColDelete(ids: ${JSON.stringify(params.ids)}) {
      data
    }}`});
  }
  fileColEnableUserUpload(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    fileColEnableUserUpload(${Utils.params2GqlStr(params)}) {
      data
    }}`});
  }
  fileRename(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    fileRename(${Utils.params2GqlStr(params)}) {
      data
    }}`});
  }


  item(params) {
    return this.apollo.query({
      query: gql`
        query adItem($id: ID!, $version: Int) {
          adItem(id: $id, version: $version) {
            id
            parentId
            baseLayout
            title
            menuTitle
            slug
            status
            parentId
          }
        }
      `,
      variables: params,
    });
  }

  uploadTmpWName(fileName: string = '') {
    const data = { fileName, section: 'files' };
    return this.apollo.mutate({
      mutation: gql`mutation($section: String, $fileName: String) {
       upload(section: $section, fileName: $fileName)
       }`, variables: { ...data }
    });
  }
}


