import { DataService } from 'app/shared/services/data.service';
import {
  Component,
  OnInit,
} from '@angular/core';
import { MessageService } from 'app/shared/services/MessageService';


@Component({
  selector: 'tasklist-headbar',
  templateUrl: './headerbar.template.html',
  styleUrls: ['./headerbar.template.scss'],
})
export class TaskListHeaderbar implements OnInit {
  
  constructor(  public dataService: DataService,public messageService: MessageService) {

   
  }
  ngOnInit(): void {
  }

  
}
