import { DataService } from 'app/shared/services/data.service';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'pop-up-new-template',
  templateUrl: 'pop-up-new-template.component.html',
  styleUrls: ['./pop-up-new-template.component.scss'],
})
export class NewTemplateDialogComponent implements OnInit {
  form: FormGroup = null;
  constructor(
    public dialogRef: MatDialogRef<NewTemplateDialogComponent>,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.form = new FormGroup({
        templateTitle: new FormControl("", Validators.required),
      });
  }

  ngOnInit() {
    
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  ngAfterViewInit() {}

 

  onSubmit() {
    this.dialogRef.close(this.form.value.templateTitle);
  }


}
