interface DialogConfigType {
  heading?: any;
  disableAction?: boolean;
  size?: 'small' | 'default' | 'large';
  action?:{
    cancelLabel?:string,
    submitLabel?:string
  }
  extraData?:any
}
interface DialogConfirmType {
  heading?: any;
  disableAction?: boolean;
  size?: 'small' | 'default' | 'large';
  confirm?: {
    message?: string;
    okLabel?: string;
    typeMessage?: 'error' | 'warning' | 'success' | 'informational';
    discard?:boolean
  };
}
export class DialogConfig {
  data: DialogConfigType;
}

export class DialogConfirm {
  data: DialogConfirmType;
}
