import { DataService } from 'app/shared/services/data.service';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'app/shared/services/MessageService';

@Component({
  selector: 'files-list-headbar',
  templateUrl: './files-list-headerbar.template.html',
})
export class FilesListHeaderbar implements OnInit {
  constructor(
    public dataService: DataService,
    public messageService: MessageService,
  ) {}
  ngOnInit(): void {
    this.checkPermDelete();
  }

  public checkPermDelete() {

    return (
      this.dataService.checkRole('PermAllCollections') ||
      this.dataService.checkRole('PermFileWrite') ||
      this.dataService.checkRole('PermMyCollection')
    );
  }
}
