import { Component, OnInit, AfterViewInit, OnDestroy, Inject, HostListener } from '@angular/core';
@Component({
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.css']
})
export class ScrollTopComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      //document.querySelector('.main-content-wrap').addEventListener('scroll', this.screenScroll, false);
    }, 1000);
  }

  ngOnDestroy(): void {
    //document.querySelector('.main-content-wrap').removeEventListener('scroll', this.screenScroll, false);
  }

  screenScroll(ev) {
    let elScrollToTop: any = document.querySelector('.scroll-to-top');
    let elTopTitleActions: any = document.querySelector('.top-title-actions');
    if (ev.target.scrollTop > 0) {
      if (elScrollToTop) elScrollToTop.style.opacity = 1;
      if (elTopTitleActions) elTopTitleActions.style.top = '10px';
    } else {
      if (elScrollToTop) elScrollToTop.style.opacity = 0;
      if (elTopTitleActions) elTopTitleActions.style.top = '74px';
    }
  }

  scrollToTop() {
    document.querySelector('.main-content-wrap').scrollTo(0, 0);
  }

  ngOnInit() {}
}
