import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LeadService } from 'app/views/lead/lead.service';
import { MessageService } from 'app/shared/services/MessageService';
@Component({
  selector: 'app-ocr-scan',
  templateUrl: 'ocr-scan.component.html',
  styleUrls: ['./ocr-scan.component.scss']
})
export class OcrScanDialogComponent implements OnInit {
  disabled:boolean = false;
  dataScaned: any
  constructor(
    public dialogRef: MatDialogRef<OcrScanDialogComponent>,
    private cdr: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    private translateService: TranslateService,
    public leadService: LeadService,
    public messageService: MessageService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
   
  }
  ngOnInit() {
    this.leadService.client_ocrBCard(this.data.file).subscribe((data) => {
      console.log('data scan', data);
      this.dataScaned = data.data['client_ocrBCard'].data.card;
     
    }, (e) => {
      console.log('Err scan', e);
       if (!this.cdr['destroyed']) { this.cdr.detectChanges(); }
      this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002')
    })
  }

  onClickCancel() {
    this.dialogRef.close();
  }



  onNext()
  {
    this.dialogRef.close({ type: 'save', data: this.dataScaned });
  }

  onEdit() {
    this.dialogRef.close({ type: 'edit', data: this.dataScaned});
  }
  
 
}
