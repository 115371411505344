import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'user-detail-item',
  templateUrl: 'detail-user.component.html',
  styleUrls: ['./detail-user.component.css'],
})
export class DetailUserComponent implements OnInit {
  link: string = environment.mediaUrl + 'fs/files/uploads/';
  @ViewChild('searchTextInput', { static: false }) searchTextInput: any;
  constructor(
    public dialogRef: MatDialogRef<DetailUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    myElement: ElementRef,
    public translateService: TranslateService,
    public snackBar: MatSnackBar,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {}

  onClickCancel() {
    this.dialogRef.close();
  }
}
