import { environment } from './../../../../environments/environment';
import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import Utils from 'app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../scale-dialog/dialog.service';
import { TermOfUseComponent } from '../term-of-use/term-of-use.component';
import { PrivacyComponent } from '../privacy/privacy.component';
@Component({
  selector: 'app-sidenav',
  styleUrls: ['./sidenav.component.scss'],
  templateUrl: './sidenav.template.html',
})
export class SidenavComponent implements OnInit {
  mktUrl = environment.mktUrl;
  link: string = environment.mediaUrl + 'fs/files/uploads/';
  isTc: boolean = environment.groupType === 'tc' ? true : false;
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;
  Utils = Utils;
  public userSettings = [
    // { icon: 'assets/icons/MN-solution.svg', name: 'TERMS_AND_USE', click: () => { this.showTerm(); }, },
    { icon: 'assets/icons/MN-Imprint.svg', name: 'Impressum', url: 'https://geschaeftskunden.telekom.de/impressum', target: 'blank', },
    {
      icon: 'assets/icons/MN-Data-Protection.svg',
      name: 'DATA_PRIVACY_FOOTER_TITLE',
      url: environment.linkPrivacy, target: 'blank',
    },
    {
      icon: 'assets/icons/MN-LogOut.svg',
      name: 'Abmelden',
      url: this.mktUrl + 'auth/sign-in',
    },
  ];

  constructor(public dataService: DataService,
    private dialogService: DialogService,
    private translateService: TranslateService) { }
  ngOnInit() { }

  // Only for demo purpose
  addMenuItem() {
    this.menuItems.push({
      name: 'ITEM',
      type: 'dropDown',
      tooltip: 'Item',
      icon: 'done',
      state: 'material',
      sub: [
        { name: 'SUBITEM', state: 'cards' },
        { name: 'SUBITEM', state: 'buttons' },
      ],
    });
  }

  showTerm() {
    this.dialogService.open(TermOfUseComponent, {
      data: { heading: this.translateService.instant('TERMS_AND_USE'), disableAction: true },
    });
  }

  showPrivacy() {
    this.dialogService.open(PrivacyComponent, {
      data: { heading: this.translateService.instant('DATA_PRIVACY_FOOTER_TITLE'), disableAction: true },
    });
  }
}
