// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// groupType: tc, ptm

export const environment = {
  title: 'Lead Dialog',
  production: false,
  webPortalUrl: '',
  linkPrivacy: 'https://www.telekom.de/datenschutzhinweise/download/250.pdf',
  defaultIdParent: '5018dfd0-e0d7-11eb-aecd-6b7accb0abbd',
  groupType: 'lda',
  warterMarkExportChart: 'LDA',
  language: 'de',
  availableLangs: {
    de: 'English',
    en: 'Deutsch'
  },
  mediaUrl: '/fs/files/',
  domain: '.messe-dialog.de',
  mktUrl: 'https://web.messe-dialog.de/mpt/',
  rolePartner: {
    basic: 'PermSquadsBasic',
    all: 'PermAllSquads',
    edit: 'PermEditSquads',
    manager: 'PermManageSquads'
  }
};

