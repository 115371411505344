import { DataService } from 'app/shared/services/data.service';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'pop-up-save-filter',
  templateUrl: 'pop-up-save-filter.component.html',
  styleUrls: ['./pop-up-save-filter.component.scss'],
})
export class SaveFilterDialogComponent implements OnInit {
  form: FormGroup = null;
  constructor(
    public dialogRef: MatDialogRef<SaveFilterDialogComponent>,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.form = new FormGroup({
      isSaveFilter: new FormControl(false),
    });
  }

  ngOnInit() {
    
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  ngAfterViewInit() {}

 

  onSubmit() {
    this.dialogRef.close(this.form.value.isSaveFilter);
  }

}
