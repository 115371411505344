import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import Utils from '../../shared/utils/utils';

@Injectable()
export class LeadService {
  constructor(private apollo: Apollo) { }

  

  client_sub_docusign_checkRequestStatus(params) {
      return this.apollo.subscribe({
        query: gql`
        subscription client_sub_docusign_checkRequestStatus($contactId: ID!){
          client_sub_docusign_checkRequestStatus(contactId: $contactId){
          data
          }
        }` , variables: params
    })
  }

  apiGets(params) {
    // console.log('[ContactService] apiGetContacts params:', params);
    return this.apollo.query({
      query: gql`query {
        contacts(input: {${Utils.params2GqlStr(params)}}) {
        entities {
          id salutation title firstName lastName email gender street houseNumber zip city country
          company department position landline mobile fax web ipAddress language dob customerNumber

          bCardFrontFilename bCardBackFilename

          groupId
          partnerCompany

          isNewCustomer crmCompany crmTdgCustomerId crmTdgSegment crmTsiContactIdMac crmTsiContactIdSalsa
          crmTsiCustomerId crmTsiAccountId

          docusignLogs eventAppQrStr v2company
          infoMailSentAt type status mfStatus
          meta createdAt updatedAt
          createdByUserId
          budget leadType
          consultations {
            id startTime endTime fileIds type status meta createdByUserId
            consultationLeads { id productId
              product { id title }
              note
              partnerProductName
              useSideAgreement
              getInfos
            }
            profiling
          }
        }
        total
      }}`,
      variables: {},
      fetchPolicy: 'no-cache',
    });
  }

  apiSendInfomail(contactId){
    return this.apollo.mutate({
      mutation: gql`
      mutation($contactId: ID!){
        contactSendInfoMail(contactId: $contactId){
          data
        }
      }
      `,
      variables: {contactId: contactId}
    })
  }

  apicontactExportMcl(contactId){
    return this.apollo.mutate({
      mutation: gql`
      mutation($contactId: ID!){
        contactExportMcl(contactId: $contactId){
          data
        }
      }
      `,
      variables: {contactId: contactId}
    })
  }

  // apicontactExportMclfail(contactId, debug){
  //   return this.apollo.mutate({
  //     mutation: gql`
  //     mutation($contactId: ID!, $debug: String){
  //       contactExportMcl(contactId: $contactId, debug: $debug){
  //         data
  //       }
  //     }
  //     `,
  //     variables: {
  //       contactId: contactId,
  //       debug: debug
  //     }
  //   })
  // }

  apiDetail(contactId) {
    return this.apollo.query({
      query: gql`query {
      contact(id: "${contactId}") {
        id salutation title firstName lastName email gender street houseNumber zip city country
        company department position landline mobile fax web ipAddress language dob customerNumber
        appointment customerConsent
        bCardFrontFilename bCardBackFilename
        groupId
        group {id company}
        partnerCompany

        isNewCustomer crmCompany crmTdgCustomerId crmTdgSegment crmTsiContactIdMac crmTsiContactIdSalsa
        crmTsiCustomerId crmTsiAccountId
        export
        docusignLogs eventAppQrStr v2company

        infoMailSentAt type status mfStatus
        meta createdAt updatedAt
        createdByUser {
          id
          firstName lastName avatarImg email mobileNumber
        }
        createdByOrg {
          id
          companyName
          parentOrg {id companyName}
        }
        createdByUserId
        budget leadType
        consultations {
          id startTime endTime type status meta createdByUserId
          files { id title filename mimetype path parentId }
          consultationLeads {
            id
            budget
            productId
            isTelekomFollowUp
            product { id title }
            note
            partnerProductName
            getInfos
            partnerProductName
            isTelekomRelevant
          }
          profiling
        }
      }}`,
      variables: {},
      fetchPolicy: 'no-cache',
    });
  }
  apiDetailPartner(params) {
    return this.apollo.query({
      query: gql`
        query adOrg($id: ID!) {
          adOrg(id: $id) {
            id
            companyName
            useSideAgreement
            isNeutral
            subPartnerNo
            bpvNo
            vpnrNo
            voNo
            status
            updatedAt
            region
            parentId
            repUsers {
              id
              firstName
              lastName
            }
          }
        }
      `,
      variables: params,
    });
  }
  apiGetGroups(params) {
    return this.apollo.query({
      query: gql`
      query {
        groups(input: {
          page: ${params.page},
          pageSize: ${params.pageSize},
          orderBy: "${params.orderBy}",
          orderDir: "${params.orderDir}",
          searchText: "${params.searchText}",
          filter: "${params.filter}"
        }) {
          entities {
            id
            title
            lang
            enableLeadCapturing 
            admins {id firstName lastName}
            company
            items
            eventImg
            status
            updatedAt
            createdAt
          }
          total
        }
      }
    `,
      fetchPolicy: 'no-cache',
    });
  }

  apiGetGroupDetail(groupId) {
    return this.apollo.query({
      query: gql`query {
    group(id: "${groupId}") {
      id
      title
      settingLead
      lang
      admins {id firstName lastName}
      items
      eventImg
      status
      updatedAt
      createdAt
      settingLead
      fileColId
      landingpageImg
      enableLeadCapturing
      fileCol {id title rootFileId allowUserUpload}
      topics {
        id title
        products {id title}
      }
      settingExport
      profiling {id title content}
      aggAdminOverview
      settingLandingpage
      settingInfomail
    }}`,
      fetchPolicy: 'no-cache',
    });
  }

  client_upsertContact(apiParams) {
    let contactCloned = this.convertContactToPushToBackend(apiParams);

    let jsonStr = JSON.stringify({ tmp: JSON.stringify(contactCloned) }).substring(8).slice(0, -2);
    return this.apollo.mutate({
      mutation: gql`mutation {
    client_upsertContact(jsonStr: "${jsonStr}") {
      data
    }
  }`});
  }

  client_docusign_createDoc(params) {
    return this.apollo.query({query: gql`query {
      client_docusign_createDoc(${Utils.params2GqlStr(params)}) {
        data
      }}`, variables: {}, fetchPolicy: 'no-cache',
    });
  }

  client_docusign_getDocusignLogsForContact(params) {
    return this.apollo.query({
      query: gql`query {
      client_docusign_getDocusignLogsForContact(${Utils.params2GqlStr(params)}) {
        data
      }}`, variables: {}, fetchPolicy: 'no-cache',
    });
  }

  client_docusign_checkRequestStatus(params) {
    return this.apollo.query({
      query: gql`query {
      client_docusign_checkRequestStatus(${Utils.params2GqlStr(params)}) {
        data
      }}`, variables: {}, fetchPolicy: 'no-cache',
    });
  }
  

  client_uploadBCard(contactId, fileUpload){
    return this.apollo.mutate({
      mutation: gql`
      mutation($contactId: String ,$fileUpload: Upload){
        client_uploadBCard(contactId: $contactId, fileUpload: $fileUpload){
          data
        }
      }
      `,
      variables : {contactId, fileUpload},
      context: { useMultipart: true },
    })
  }

  client_ocrBCard(filename){
    return this.apollo.mutate({
      mutation: gql`
      mutation($filename: String!){
        client_ocrBCard(filename: $filename){
          data
        }
      }
      `,
      variables: {filename}
    })
  }

  convertContactToPushToBackend(contact) {
    let contactCloned = JSON.parse(JSON.stringify(contact));
    for (let consultation of contactCloned.consultations) {
      consultation.contactId = contactCloned.id;
      if (consultation.files) {
        consultation.fileIds = consultation.files.map(el => el.id);
        delete consultation.files;
      }
      for (let consultationLead of consultation.consultationLeads) {
        consultationLead.consultationId = consultation.id;
        if (consultationLead.product) {
          consultationLead.productId = consultationLead.product.id;
          //delete consultationLead.product;
        }
      }
    }
    return contactCloned;
  }

  contactFindByQrStr(params) {
    return this.apollo.query({query: gql`query {
      contactFindByQrStr(${Utils.params2GqlStr(params)}) {
        data
      }}`, variables: {}, fetchPolicy: 'no-cache',
    });
  }
  
  findBySearchText(params) {
    return this.apollo.query({query: gql`query {
      contactsFindBySearchText(${Utils.params2GqlStr(params)}) {
        data
      }}`, variables: {}, fetchPolicy: 'no-cache',
    });
  }

   apiDelete(params) {
    return this.apollo.mutate({
      mutation: gql`mutation { client_deleteContacts(ids: ${JSON.stringify(params.ids)}) {
        data
        }}`,
    }
    );
  }
}
