import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import Utils from 'app/shared/utils/utils';

import { PipeTransform, Pipe } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
  name: "sanitizeUrl"
})
export class SanitizeUrlPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) { }

  transform(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustResourceUrl(v);
  }
}

@Component({
  selector: 'app-files-popup',
  templateUrl: './files-popup.component.html',
  styleUrls: ['./files-popup.component.css']
})
export class FilesPopupComponent implements OnInit {
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  url: string = ''
  fullscreen: boolean = false
  Utils = Utils;
  constructor(
    public dialogRef: MatDialogRef<FilesPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    this.url =  data.file
  }
  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  download() {
    window.open(this.url, '_blank');
  }

  openfullscreen() {
    this.fullscreen = !this.fullscreen
  }
  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }
}
