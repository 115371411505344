import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import Constants from '../utils/constants';

@Injectable()
export class StorageService {

  constructor() { }
  store(key: string, content: Object) {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(content), Constants.secretKey).toString();
    // console.log('encryptedData:', encryptedData);
    // localStorage.setItem(key, encryptedData);
    localStorage.setItem(key, JSON.stringify(content));
  }

  retrieve(key: string) {
    const encryptedData = localStorage.getItem(key);
    if (!encryptedData) { return null; }
    // const content = JSON.parse(CryptoJS.AES.decrypt(encryptedData, Constants.secretKey).toString(CryptoJS.enc.Utf8));
    let content = null;
    try {
      content = JSON.parse(encryptedData);
    } catch (error) {
      console.log('retrieve error:', error);
    }
    // const content = JSON.parse(encryptedData);
    return content;
  }

  sessionStore(key: string, content: Object) {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(content), Constants.secretKey).toString();
    // console.log('encryptedData:', encryptedData);
    sessionStorage.setItem(key, encryptedData);
  }

  sessionRetrieve(key: string) {
    const encryptedData = sessionStorage.getItem(key);
    if (!encryptedData) { return null; }
    const content = JSON.parse(CryptoJS.AES.decrypt(encryptedData, Constants.secretKey).toString(CryptoJS.enc.Utf8));
    // console.log('content:', content);
    return content;
  }
}
