import { PartnerService } from './../../views/partner/partner.service';
import { AddNewUserComponent } from './../../views/partner/partner-detail/dialog/add-user.component';
import { HeaderCalender } from './../../views/lead/lead-detail/dialog/make-appointment/header-calendar';
import { CollectionAddDialogComponent } from './../../views/files/collection-add/collection-add-dialog.component';
import { ConfirmCsvComponent } from './../../views/contact/popup-csv/confirm-csv.component';
import { FilesPopupComponent, SanitizeUrlPipe } from './../../views/files/files-popup/files-popup.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { FilesListHeaderbar } from './../../views/files/files-list-headerbar/files-list-headerbar.component';
import { TeamDetailComponent } from './../../views/team/team-detail/team-detail.component';
import { PartnerDetailHeaderbar } from './../../views/partner/partner-detail/partner-detail-headerbar/partner-detail-headerbar.component';
import { PopupLabelComponent } from './popup-label/popup-label.component';
import { DashboardHeaderbar } from './../../views/dashboard/default-dashboard/dashboard-headerbar/dashboard-headerbar.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { SearchCustomerDialogComponent } from './../../views/lead/search-customer/search-customer-dialog.component';
import { ScanQrDialogComponent } from './../../views/lead/scan-qr/scan-qr-dialog.component';
import { ViewInfomaterialComponent } from './../../views/lead/lead-detail/dialog/infomaterial/view-infomaterial/view-infomaterial.component';
import { ViewProfileComponent } from './../../views/lead/lead-detail/dialog/create-profile/view-profile/view-profile.component';
import { SelecProductComponent } from './../../views/lead/lead-detail/dialog/select-product/select-product.component';
import { VcardScanDialogComponent } from './../../views/lead/lead-detail/dialog/vcard-scan/vcard-scan.component';
import { MakeAppointmentDialogComponent } from './../../views/lead/lead-detail/dialog/make-appointment/make-appointment.component';
import { InfomaterialDialogComponent } from './../../views/lead/lead-detail/dialog/infomaterial/infomaterial.component';
import { CustomerNumberDialogComponent } from './../../views/lead/lead-detail/dialog/customer-number/customer-number.component';
import { CustomerConsentDialogComponent } from './../../views/lead/lead-detail/dialog/customer-consent/customer-consent.component';
import { CreateProfileDialogComponent } from './../../views/lead/lead-detail/dialog/create-profile/create-profile.component';
import { SelectProductDialogComponent } from './../../views/lead/lead-detail/dialog/select-product/dialog/select-product-dialog.component';
import { ContactDetailDialogComponent } from './../../views/lead/lead-detail/dialog/contact-detail/contact-detail.component';
import { LeadDetailComponent } from './../../views/lead/lead-detail/lead-detail.component';
import { GroupChoiceialogComponent } from './../../views/lead/group-choice/group-choice-dialog.component';
import { ContactListHeaderbar } from './../../views/contact/contact-list-headerbar/contact-list-headerbar.component';
import { LeadHeaderbar } from './../../views/lead/lead-detail/headerbar/lead-headerbar.component';
import { TeamChoiceialogComponent } from './../../views/team/team-choice/team-choice-dialog.component';
import { ProfileListHeaderbar } from './../../views/profile/profile-list-headerbar/profile-list-headerbar.component';
import { PopupAvatarComponent } from './popup-avatar/popup-avatar.component';
import { DialogChoicePartnerComponent } from '../../views/group/group-detail-partner/partner/choice/dialog-choice.component';
import { DeleteHeaderbar } from './delete-headerbar/delete-headerbar.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { LeadListHeaderbar } from './../../views/lead/lead-list-headerbar/lead-list-headerbar.component';
import { FileRenameDialogComponent } from './../../views/file/file-list/file-rename-dialog.component';
import { RepresentativeComponent } from './representative/representative.component';
import { DetailUserComponent } from './detail-user/detail-user.component';
import { AddUserComponent } from './add-user/add-user.component';
import { PopupConfirmAddUserComponent } from './add-user/popup-confirm.component';
import { TeamListHeaderbar } from './../../views/team/team-list-headerbar/team-list-headerbar.component';
import { PartnerListHeaderbar } from './../../views/partner/partner-list-headerbar/partner-list-headerbar.component';
import { PopupConfirmComponent } from './popup-confirm/popup-confirm.component';
import { ItemListHeaderbar } from './../../views/item/item-list-headerbar/item-list-headerbar.component';
import { CustomerListHeaderbar } from './../../views/customers/customers-list-headerbar/customers-list-headerbar.component';
import { OrderListHeaderbar } from './../../views/orders/order-list-headerbar/order-list-headerbar.component';
import { SettingHeaderbar } from '../../views/setting/setting-headerbar/setting-headerbar.component';
import { PartnerFilterItemComponent } from './partner-filter-item/partner-filter-item.component'

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedMaterialModule } from '../shared-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SearchModule } from '../search/search.module';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { PopupNextTab } from '../../views/group/group-detail-partner/overview/dialog/popup-next-tab.component'
// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from './header-side/header-side.component';
import { SidebarSideComponent } from './sidebar-side/sidebar-side.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';

// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import { HeaderTopComponent } from './header-top/header-top.component';
import { SidebarTopComponent } from './sidebar-top/sidebar-top.component';

// ONLY FOR DEMO
import { CustomizerComponent } from './customizer/customizer.component';

// ALWAYS REQUIRED
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { AppComfirmComponent } from '../services/app-confirm/app-confirm.component';
import { AppLoaderComponent } from '../services/app-loader/app-loader.component';
import { ButtonLoadingComponent } from './button-loading/button-loading.component';
import {
  MdaSidebarComponent,
  MdaSidebarTogglerDirective,
} from './mda-sidebar/mda-sidebar.component';
import { BottomSheetShareComponent } from './bottom-sheet-share/bottom-sheet-share.component';
import { MdaExampleViewerComponent } from './example-viewer/example-viewer.component';
import { MdaExampleViewerTemplateComponent } from './example-viewer-template/example-viewer-template.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { UserListHeaderbar } from 'app/views/user/user-list-headerbar/user-list-headerbar.component';
import { FileListHeaderbar } from 'app/views/file/file-list-headerbar/file-list-headerbar.component';
import { ListComponent } from '../list/list.component';
import { GroupListHeaderbar } from 'app/views/group/group-list-headerbar/group-list-headerbar.component';
import { FileAddDialogComponent } from 'app/views/file/file-list/file-add-dialog.component';
import { AngularCropperjsModule } from 'angular-cropperjs';

// CHART LAYOUT
import { TemplateChoiceDialogComponent } from '../../views/dashboard/default-dashboard/pop-up-choice-template/pop-up-choice-template.component'
import { TemplateEditDialogComponent } from '../../views/dashboard/default-dashboard/pop-up-edit-template/pop-up-edit-template.component'
import { NewTemplateDialogComponent } from '../../views/dashboard/default-dashboard/pop-up-new-template/pop-up-new-template.component'
import { SaveFilterDialogComponent } from '../../views/dashboard/default-dashboard/pop-up-save-filter/pop-up-save-filter.component'
import { WebcamModule } from 'ngx-webcam';
import { FilesComponent } from 'app/views/files/files.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FilesService } from 'app/views/files/files.service';
import { PartnerGroupsComponent } from './partner-groups/partner-groups.component';
import { ErrorComponent } from '../services/MessageService';
import { SaveTemplateDialogComponent } from 'app/views/lead/lead-detail/dialog/save-template/save-template.component';
import { ResetTemplateDialogComponent } from 'app/views/lead/lead-detail/dialog/reset-template/reset-template.component';
import { DigitalSignatureDialogComponent } from 'app/views/lead/lead-detail/dialog/digital-signature/digital-signature.component';
import { DigitalSignatureStep2Component } from 'app/views/lead/lead-detail/dialog/digital-signature/ds-step2/ds-step2.component';
import { DigitalSignatureStep1Component } from 'app/views/lead/lead-detail/dialog/digital-signature/ds-step1/ds-step1.component';
import { NewsHeaderbar } from 'app/views/news/headerbar/headerbar.component';
import { EmployeeListHeaderbar } from 'app/views/partner/partner-detail/employees/employee-list-headerbar/employee-list-headerbar.component';
import { OcrScanDialogComponent } from 'app/views/lead/lead-detail/dialog/vcard-scan/dialog/ocr-scan.component';
import { TaskListHeaderbar } from 'app/views/tasklist/detail/headerbar/headerbar.component';
import { RepresentativeControlComponent } from './representative-control/representative.component';
import { ItemHeaderbar } from 'app/views/support/item-headerbar/item-headerbar.component';
// scale dialog
import {DialogModule} from "./scale-dialog/dialog.module"
import { PrivacyComponent } from './privacy/privacy.component';
import { TermOfUseComponent } from './term-of-use/term-of-use.component';
import { VcardScanDetailComponent } from 'app/views/lead/lead-detail/dialog/vcard-scan/detail/vcard-scan.component';
import { MainComponent } from './layouts/main/main.component';
import { JWTComponent } from 'app/views/jwt/index.component';
import { SaveButtonComponent } from './save-button/index.component';
import { ImportPopupComponent } from './import-popup/import-popup.component';

const components = [
  HeaderTopComponent,
  SidebarTopComponent,
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  SubHeaderComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  MainComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  AppComfirmComponent,
  AppLoaderComponent,
  CustomizerComponent,
  ButtonLoadingComponent,
  MdaSidebarComponent,
  MdaSidebarTogglerDirective,
  BottomSheetShareComponent,
  MdaExampleViewerComponent,
  MdaExampleViewerTemplateComponent,
  ScrollTopComponent,
  UserListHeaderbar,
  FileListHeaderbar,
  GroupListHeaderbar,
  ItemListHeaderbar,
  SettingHeaderbar,
  PartnerFilterItemComponent,
  OrderListHeaderbar,
  CustomerListHeaderbar,
  PartnerListHeaderbar,
  EmployeeListHeaderbar,
  ListComponent,
  LeadListHeaderbar,
  TeamListHeaderbar,
  ProfileListHeaderbar,
  RepresentativeComponent,
  RepresentativeControlComponent,
  PartnerGroupsComponent,
  DetailUserComponent,
  PopupConfirmAddUserComponent,
  AddUserComponent,
  PopupConfirmComponent,
  PopupConfirmComponent,
  FileRenameDialogComponent,
  FileAddDialogComponent,
  SearchBarComponent,
  DeleteHeaderbar,
  DialogChoicePartnerComponent,
  PopupAvatarComponent,
  TeamChoiceialogComponent,
  LeadHeaderbar,
  ContactListHeaderbar,
  GroupChoiceialogComponent,
  LeadDetailComponent,
  ContactDetailDialogComponent,
  SelectProductDialogComponent,
  CreateProfileDialogComponent,
  CustomerConsentDialogComponent,
  CustomerNumberDialogComponent,
  InfomaterialDialogComponent,
  MakeAppointmentDialogComponent,
  VcardScanDialogComponent,
  VcardScanDetailComponent,
  SelecProductComponent,
  PopupNextTab,
  PopupLabelComponent,
  TemplateChoiceDialogComponent,
  TemplateEditDialogComponent,
  NewTemplateDialogComponent,
  SaveFilterDialogComponent,
  ViewProfileComponent,
  ViewInfomaterialComponent,
  ScanQrDialogComponent,
  SearchCustomerDialogComponent,
  DashboardHeaderbar,
  PartnerDetailHeaderbar,
  TeamDetailComponent,
  FilesListHeaderbar,
  FilesPopupComponent,
  SanitizeUrlPipe,
  ConfirmCsvComponent,
  FilesComponent,
  CollectionAddDialogComponent,
  HeaderCalender,
  AddNewUserComponent,
  ErrorComponent,
  SaveTemplateDialogComponent,
  ResetTemplateDialogComponent,
  DigitalSignatureDialogComponent,
  DigitalSignatureStep1Component,
  DigitalSignatureStep2Component,
  NewsHeaderbar,
  TaskListHeaderbar,
  ItemHeaderbar,
  OcrScanDialogComponent,
  PrivacyComponent,
  TermOfUseComponent,
  JWTComponent,
  SaveButtonComponent,
  ImportPopupComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    SearchModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SharedMaterialModule,
    ReactiveFormsModule,
    AngularCropperjsModule,
    ZXingScannerModule,
    WebcamModule,
    IvyCarouselModule,
    NgxExtendedPdfViewerModule,
    NgxFileDropModule,
    DialogModule
  ],
  declarations: components,
  entryComponents: [
    AppComfirmComponent,
    AppLoaderComponent,
    BottomSheetShareComponent,
    UserListHeaderbar,
    FileListHeaderbar,
    SettingHeaderbar,
    PartnerFilterItemComponent,
    OrderListHeaderbar,
    CustomerListHeaderbar,
    PartnerListHeaderbar,
    EmployeeListHeaderbar,
    LeadListHeaderbar,
    GroupListHeaderbar,
    ItemListHeaderbar,
    TeamListHeaderbar,
    RepresentativeComponent,
    PartnerGroupsComponent,
    DetailUserComponent,
    PopupConfirmAddUserComponent,
    AddUserComponent,
    PopupConfirmComponent,
    FileRenameDialogComponent,
    FileAddDialogComponent,
    PopupConfirmComponent,
    DeleteHeaderbar,
    PopupAvatarComponent,
    DialogChoicePartnerComponent,
    ProfileListHeaderbar,
    TeamChoiceialogComponent,
    ContactListHeaderbar,
    LeadHeaderbar,
    GroupChoiceialogComponent,
    ContactDetailDialogComponent,
    SelectProductDialogComponent,
    CreateProfileDialogComponent,
    CustomerConsentDialogComponent,
    CustomerNumberDialogComponent,
    InfomaterialDialogComponent,
    MakeAppointmentDialogComponent,
    VcardScanDialogComponent,
    VcardScanDetailComponent,
    SelecProductComponent,
    PopupNextTab,
    PopupLabelComponent,
    TemplateChoiceDialogComponent,
    TemplateEditDialogComponent,
    NewTemplateDialogComponent,
    SaveFilterDialogComponent,
    ScanQrDialogComponent,
    SearchCustomerDialogComponent,
    DashboardHeaderbar,
    PartnerDetailHeaderbar,
    TeamDetailComponent,
    FilesListHeaderbar,
    FilesPopupComponent,
    ConfirmCsvComponent,
    CollectionAddDialogComponent,
    AddNewUserComponent,
    ErrorComponent,
    SaveTemplateDialogComponent,
    ResetTemplateDialogComponent,
    DigitalSignatureDialogComponent,
    NewsHeaderbar,
    TaskListHeaderbar,
    ItemHeaderbar,
    OcrScanDialogComponent,
    PrivacyComponent,
    TermOfUseComponent
  ],
  exports: components,
  providers: [
    FilesService,
    PartnerService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedComponentsModule { }
