import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'app/shared/services/data.service';
import { LayoutService } from 'app/shared/services/layout.service';
import { environment } from 'environments/environment';
import { CookieService } from 'ng2-cookies';
const _ = require('lodash');
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  mktUrl = environment.mktUrl;
  sitename: string = environment.title;
  codeLang: string = environment.language;
  labelLang: string = environment.availableLangs[this.codeLang];
  link: string = environment.mediaUrl + '/appdata/';
  showMenu = false;
  info: any = {};
  arrMenu = [
    { id: 'campaigns', label: 'CAMPAIGNS' },
    { id: 'calendar', label: 'CALENDAR' },
    { id: 'report', label: 'REPORT' },
    { id: 'squads', label: 'SQUADS' },
    { id: 'users', label: 'USERS' },
    { id: 'settings', label: 'SETTINGS' }
  ];


  menus: any = [];
  myleads: any = [];

  constructor(
    @Inject(DOCUMENT) document: Document,
    public router: Router,
    public cdr: ChangeDetectorRef,
    public translateService: TranslateService,
    public layoutService: LayoutService,
    public dataService: DataService,
    public cookieService: CookieService,
    public activatedRoute: ActivatedRoute) {
    this.setLang();
    const url = this.activatedRoute.snapshot.url.join().split(',');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.layoutService.adjustLayout(event);
  }

  ngOnInit(): void {
    const groups = this.dataService.getGroups();
    this.info = this.dataService.sData.user;
    if (groups && groups.length > 0) {
      this.myleads = _.uniqBy(groups, 'id');
    }
    this.menus = [
      {
        'name': 'LEADS',
        'id': 'leads',
        'perm': this.dataService.checkRoleV2(['PermDashboardBasic', 'PermChannelsBasic',
          'PermLeadBasic', 'PermContactsBasic', 'PermFilesBasic']),
        'children': [
          {
            'name': 'DASHBOARD',
            'id': 'dashboard',
            'router': '/dashboard',
            'perm': this.dataService.checkRoleV2('PermDashboardBasic')
          },
          {
            'name': 'LEADS',
            'id': 'lead',
            'href': environment.mktUrl + 'lead',
            'perm': this.dataService.checkRoleV2('PermLeadBasic')
          },
          {
            'name': 'CHANNEL',
            'id': 'channel',
            'router': '/channel',
            'perm': this.dataService.checkRoleV2('PermChannelsBasic')
          },
          {
            'name': 'FILES',
            'id': 'files',
            'router': '/files',
            'perm': this.dataService.checkRoleV2('PermFilesBasic')
          },
        ]
      },
      {
        'name': 'MARKETING_PLANNING',
        'id': 'marketing-planning',
        'perm': this.dataService.checkRoleV2(['PermCampaignsBasic', 'PermCalendarCampaigns', 'PermReportsBasic']),
        'children': [
          {
            'name': 'CAMPAIGNS',
            'id': 'campaigns',
            'href': environment.mktUrl + 'campaigns',
            'perm': this.dataService.checkRoleV2('PermCampaignsBasic')
          },
          {
            'name': 'CALENDAR',
            'id': 'calendar',
            'href': environment.mktUrl + 'calendar',
            'perm': this.dataService.checkRoleV2('PermCalendarCampaigns')
          },
          {
            'name': 'REPORT',
            'id': 'report',
            'href': environment.mktUrl + 'report',
            'perm': this.dataService.checkRoleV2('PermReportsBasic')
          }
        ]
      },
      {
        'name': 'ADMINISTRATOR',
        'id': 'administration',
        'perm': this.dataService.checkRoleV2(['PermSquadsBasic', 'PermUserBasic', 'PermGeneralSetting']),
        'children': [
          {
            'name': 'SQUADS',
            'id': 'squads',
            'href': environment.mktUrl + 'squads',
            'perm': this.dataService.checkRoleV2('PermSquadsBasic')
          },
          {
            'name': 'USERS',
            'id': 'users',
            'href': environment.mktUrl + 'users',
            'perm': this.dataService.checkRoleV2('PermUserBasic')
          },
          {
            'name': 'SETTINGS',
            'id': 'settings',
            'href': environment.mktUrl + 'settings',
            'perm': this.dataService.checkRoleV2('PermGeneralSetting')
          }
        ]
      },
      {
        'name': 'ACCOUNT',
        'id': 'account',
        'perm': true,
        'children': [
          {
            'name': 'MY_ACCOUNT',
            'id': 'my-account',
            'href': environment.mktUrl + 'account',
            'perm': true,
          },
          {
            'name': 'LOGOUT',
            'id': 'my-logout',
            'perm': true,
            'onClick': () => {
              this.logout();
            }
          }
        ]
      }
    ];
    this.cdr.detectChanges();
  }

  setLang(lang: any = null, isset: boolean = false) {
    if (!lang) {
      if (!isset) {
        this.codeLang = this.cookieService.get('language') || environment.language;
        this.labelLang = environment.availableLangs[this.codeLang];
        this.translateService.use(this.codeLang);
      } else {
        if (this.codeLang === 'en') {
          this.codeLang = 'de';
        } else {
          this.codeLang = 'en';
        }
        this.labelLang = environment.availableLangs[this.codeLang];
        this.cookieService.set('language', this.codeLang, undefined, '/', environment.domain);
        this.translateService.use(this.codeLang);
      }
    } else {
      this.codeLang = lang;
      this.labelLang = environment.availableLangs[this.codeLang];
      this.cookieService.set('language', this.codeLang, undefined, '/', environment.domain);
      this.translateService.use(this.codeLang);
    }
  }

  logout(): void {
    localStorage.removeItem('info');
    localStorage.removeItem('ExpiresIn');
    localStorage.removeItem('lastUser');
    localStorage.removeItem('role');
    localStorage.removeItem('permission');
    localStorage.removeItem('groups');
    localStorage.removeItem('partner');
    localStorage.removeItem('setting');
    localStorage.removeItem('sData');
    this.cookieService.delete('token', '/', environment.domain);
    this.cookieService.delete('refreshToken', '/', environment.domain);
    window.location.href = this.mktUrl + 'auth/sign-in';
  }

  mouseover() {
    this.showMenu = true;
  }

  scaleOpen(e) {
    console.log(e);
  }

  openChannel(e) {
    window.location.href = this.mktUrl + 'lead/channel/' + e.id;
  }

  gotoAccount() {
    window.location.href = this.mktUrl + 'account';
  }
}
