import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { DataService } from '../../shared/services/data.service';
import Utils from '../../shared/utils/utils';

@Injectable()
export class TeamService {
  constructor(private apollo: Apollo, private dataService: DataService) { }
  apiGets(params) {
    this.dataService.setParams('team', params);
    return this.apollo.query({
      query: gql`query {
        adOrgs(${Utils.params2GqlStr(params)}) {
          entities {
            id
            companyName
            bpvNo
            status
            updatedAt
            childrenCount
            
          }
          total
    }}`, fetchPolicy: 'no-cache',
    });
  }
  apiGetUserPartner(id, userId) {
    return this.apollo.query({
      query: gql`query {
    adOrgUser(id: "${id}", userId: "${userId}") {
      id salutation firstName lastName email mobileNumber status avatarImg  updatedAt meta 
    }}`, fetchPolicy: 'no-cache',
    });
  }
  removeUser(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
      adOrgRemoveUsers(id: ${JSON.stringify(params.id)}, userIds: ${JSON.stringify(params.userId)})}`
    });
  }
  apiUpdateUser(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    adOrgUpdateUser(${Utils.params2GqlStr(params)})}`
    });
  }
  
  apiDetail(params) {
    return this.apollo.query({
      query: gql`
        query adOrg($id: ID!) {
          adOrg(id: $id) {
            id
            companyName
            subPartnerNo
            bpvNo
            vpnrNo
            type
            meta
            voNo
            status
            updatedAt
          }
        }
      `,
      variables: params,
    });
  }


  apiGetUsers(params) {
    return this.apollo.query({
      query: gql`query {
        adOrgUsers(${Utils.params2GqlStr(params)}) {
          entities {
            id
            firstName
            lastName
            email
            mobileNumber
            status
            avatarImg
          }
          total
          meta
    }}`, fetchPolicy: 'no-cache',
    });
  }

  adUpdate(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
      adOrgUpdate(${Utils.params2GqlStr(params)})
    }`,
    });
  }

  apiGetSubs(params) {
    return this.apollo.query({
      query: gql`query {
        adOrgSubOrgs(${Utils.params2GqlStr(params)}) {
          entities {
            id
            companyName
            bpvNo
            updatedAt
        }
        total
    }}`, fetchPolicy: 'no-cache',
    });
  }

  apiCreateSub(params) {
    return this.apollo.mutate({
      mutation: gql`
        mutation adOrgCreate(
          $companyName: String!
          $bpvNo: String!
          $vpnrNo: String!
          $voNo: String!
          $region: String!
          $parentId: ID
        ) {
          adOrgCreate(
            companyName: $companyName
            bpvNo: $bpvNo
            vpnrNo: $vpnrNo
            voNo: $voNo
            region: $region
            parentId: $parentId
          ) {
            id
            companyName
          }
        }
      `,
      variables: params,
    });
  }

  apiRemoveUser(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
        adOrgRemoveUsers(${Utils.params2GqlStr(params)})
    }`,
    });
  }

  apiDeleteSub(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
        adOrgDelete(${Utils.params2GqlStr(params)})
    }`,
    });
  }

  setAdmin(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
        adOrgSetAdminUser(${Utils.params2GqlStr(params)})
    }`,
    });
  }

  apiAddByEmail(params) {
    return this.apollo.mutate({
      mutation: gql`
        mutation adOrgAddUserByEmail(
          $step: String!
          $email: String!
          $id: ID!
        ) {
          adOrgAddUserByEmail(
            step: $step
            id: $id
            email: $email
          ) {
            data
          }
        }
      `,
      variables: params,
    });
  }

  adOrgAddUser(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
      adOrgAddUser(${Utils.params2GqlStr(params)})
    }`,
    });
  }

  apiCreateUser(params) {
    return this.apollo.mutate({
      mutation: gql`
        mutation adOrgAddUserByEmail(
          $step: String!
          $email: String!
          $id: ID!
          $mobileNumber: String!
        ) {
          adOrgAddUserByEmail(
            step: $step
            id: $id
            email: $email
            mobileNumber: $mobileNumber
          ) {
            data
          }
        }
      `,
      variables: params,
    });
  }


  apiAddById(params) {
    return this.apollo.mutate({
      mutation: gql`
        mutation adOrgAddUser(
          $userId: ID!
          $id: ID!
        ) {
          adOrgAddUser(
            id: $id
            userId: $userId
          )
        }
      `,
      variables: params,
    });
  }



  getPartner(params) {
    return this.apollo.query({
      query: gql`query {
        adOrgs(${Utils.params2GqlStr(params)}) {
          entities {
            id
            companyName
            region
            bpvNo
            status
            updatedAt
            childrenCount
          }
          total
    }}`, fetchPolicy: 'no-cache',
    });
  }

  adOrgUsersImportCsv(orgId, fileUpload) {
    return this.apollo.mutate({
      mutation: gql`mutation($orgId: ID!,$fileUpload: Upload) {
        adOrgUsersImportCsv(orgId: $orgId,fileUpload: $fileUpload) {
      data
    }}`,
      variables: { orgId, fileUpload },
      context: { useMultipart: true },
    });
  }
}
