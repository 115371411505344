import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { environment } from 'environments/environment';
import gql from 'graphql-tag';
import { DataService } from '../../shared/services/data.service';
import Utils from '../../shared/utils/utils';

@Injectable()
export class GroupService {
  constructor(private apollo: Apollo, private dataService: DataService) { }
  apiGetGroupsPartner(params) {
    return this.apollo.query({
      query: gql`
        query {
          groups(input: {
            page: ${params.page},
            pageSize: ${params.pageSize},
            orderBy: "${params.orderBy}",
            orderDir: "${params.orderDir}",
            searchText: "${params.searchText}",
            filter: "${params.filter}"
          }) {
            entities {
              id
              title
              lang
              type
              admins {id firstName lastName avatarImg}
              company
              items
              eventImg
              status
              updatedAt
              createdAt
            }
            total
          }
        }
      `,
      variables: {},
      fetchPolicy: 'no-cache',
    });
  }


  adGroupsForAddInPartnerScreen(params) {
    return this.apollo.query({
      query: gql`
        query {
          adGroupsForAddInPartnerScreen(input: {
            page: ${params.page},
            pageSize: ${params.pageSize},
            orderBy: "${params.orderBy}",
            orderDir: "${params.orderDir}",
            searchText: "${params.searchText}",
            filter: "${params.filter}"
          }) {
            entities {
              id
              title
              lang
              type
              admins {id firstName lastName avatarImg}
              company
              items
              eventImg
              status
              updatedAt
              createdAt
            }
            total
          }
        }
      `,
      variables: {},
      fetchPolicy: 'no-cache',
    });
  }


  apiGetGroups(params) {
    this.dataService.setParams('group', params);
    return this.apollo.query({
      query: gql`
        query {
          groups(input: {
            page: ${params.page},
            pageSize: ${params.pageSize},
            orderBy: "${params.orderBy}",
            orderDir: "${params.orderDir}",
            searchText: "${params.searchText}",
            filter: "${params.filter}"
          }) {
            entities {
              id
              title
              lang
              isDefault
              admins {id firstName lastName avatarImg}
              company
              items
              eventImg
              megamenu
              status
              updatedAt
              createdAt
            }
            total
          }
        }
      `,
      variables: {},
      fetchPolicy: 'no-cache',
    });
  }

  apiGetGroupDetail(groupId) {
    if (environment.groupType !== 'tc') return this.apiGetGroupDetailPartner(groupId);
    return this.apollo.query({
      query: gql`query {
    group(id: "${groupId}") {
      id
      title
      slug
      lang
      isDefault
      admins {id firstName lastName avatarImg}
      items
      eventImg
      status
      updatedAt
      createdAt
      settingLead
      fileColId
      landingpageImg
      enableLeadCapturing
      megamenu
      fileCol {id title allowUserUpload}
      topics {
        id title
        products {id title}
      }
      ownerUser
      {
        id firstName lastName
      }
      settingExport
      profiling {id title content}
      aggAdminOverview
      settingLandingpage
      settingInfomail
      meta
    }}`,
      fetchPolicy: 'no-cache',
    });
  }

  apiGetGroupDetailPartner(groupId) {
    return this.apollo.query({
      query: gql`query {
    group(id: "${groupId}") {
      id
      title
      lang
      isDefault
      admins {id firstName lastName avatarImg}
      items
      eventImg
      status
      type
      allowAllOrgs
      updatedAt
      createdAt
      settingLead
      fileColId
      eventStart
      eventEnd
      eventAddress
      eventInfotext
      landingpageImg
      enableLeadCapturing
      enableAssignFromPartnerView
      fileCol {id title allowUserUpload}
      topics {
        id title
        products {id title}
      }
      settingExport
      profiling {id title content}
      aggAdminOverview
      settingLandingpage
      settingInfomail
      meta
    }}`,
      fetchPolicy: 'no-cache',
    });
  }

  getGroupDetail(groupId) {
    return this.apollo.query({
      query: gql`query {
    group(id: "${groupId}") {
      id
      title
      lang
      isDefault
      admins {id firstName lastName}
      items
      eventImg
      status
      updatedAt
      createdAt
    }}`,
      fetchPolicy: 'no-cache',
    });
  }

  getCategories(params) {
    return this.apollo.query({
      query: gql`query {
    mptProductCats(input: {${Utils.params2GqlStr(params)}}) {
      entities {id title, products { id, title }}
    }}`,
      fetchPolicy: 'no-cache',
    });
  }

  getProductCat(company: string) {
    const paginate = { limit: null };
    const query = { sortBy: [['title', 'ASC']], filterBy: { company }};
    return this.apollo.query({
      query: gql`query($query: CommonRequestInput, $paginate: PaginateInput) {
        mptProductCats(paginate:$paginate,query: $query) {
          entities {
            id
            title
            company
            products {
              id
              title
            }
          }
        }
    }`, variables: { query, paginate }, fetchPolicy: 'no-cache',
    });
  }


  apiGroupCreate(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    groupCreate(${Utils.params2GqlStr(params)}) {
      id title
    }}`,
    });
  }

  apiGroupDuplicate(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
      groupDuplicate(${Utils.params2GqlStr(params)}) {
      id title
    }}`,
    });
  }

  // apiGroupsUpdate(params, fileUploadEventImage, fileUploadLandingpageImage) {
  //   return this.apollo.mutate({
  //     mutation: gql`mutation($fileUploadEventImage: Upload, $fileUploadLandingpageImage: Upload) {
  //       groupUpdate(input: {${Utils.params2GqlStr(
  //         params,
  //       )}}, fileUploadEventImage: $fileUploadEventImage, fileUploadLandingpageImage: $fileUploadLandingpageImage) {
  //     data
  //   }}`,
  //     variables: { fileUploadEventImage, fileUploadLandingpageImage },
  //     context: { useMultipart: true },
  //   });
  // }

  apiGroupsUpdate(input: any, fileUploadEventImage: string = '', fileUploadLandingpageImage: string = '') {
    return this.apollo.mutate({
      mutation: gql`mutation($input: groupUpdateInput!, $fileUploadEventImage: String, $fileUploadLandingpageImage: String) {
        groupUpdate(input: $input, fileUploadEventImage: $fileUploadEventImage, fileUploadLandingpageImage: $fileUploadLandingpageImage) {
      data
    }}`,
      variables: { input, fileUploadEventImage, fileUploadLandingpageImage }
    });
  }


  apiGroupsDelete(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
      groupDelete(ids: ${JSON.stringify(params.ids)}) {
      data
    }}`,
    });
  }

  groupSetTopics(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
      groupSetTopics(${Utils.params2GqlStr(params)})}`,
    });
  }

  apiUserSearch(params) {
    console.log('apiUserSearch params:', params);
    // return this.apollo.query({query: gql`query($searchText: String) {
    // userSearch(searchText: $searchText) {
    //   data
    // }}`, variables: params});
    return this.apollo.query({
      query: gql`query {
    userSearch(${Utils.params2GqlStr(params)}) {
      data
    }}`,
    });
  }
  apiTopicRemoveProduct(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    topicRemoveProduct(${Utils.params2GqlStr(params)}) {
      data
    }}`,
    });
  }
  apiTopicAddProduct(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    topicAddProduct(${Utils.params2GqlStr(params)}) {
      data
    }}`,
    });
  }
  apiGroupAddTopic(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    groupAddTopic(${Utils.params2GqlStr(params)}) {
      data
    }}`,
    });
  }
  apiTopicsDelete(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    topicDelete(ids: ${JSON.stringify(params.ids)}) {
      data
    }}`,
    });
  }
  apiTopicUpdate(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    topicUpdate(${Utils.params2GqlStr(params)}) {
      data
    }}`,
    });
  }
  apiGroupEditProfiling(params) {
    const jsonStr = JSON.stringify({ tmp: JSON.stringify(params) })
      .substring(8)
      .slice(0, -2);
    return this.apollo.mutate({
      mutation: gql`mutation {
    groupEditProfiling(jsonStr: "${jsonStr}") {
      data
    }}`,
    });
  }
  groupEditProfilingOrder(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    groupEditProfilingOrder(${Utils.params2GqlStr(params)}) {
      data
    }}`,
    });
  }
  apiGroupRemoveProfiling(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    groupRemoveProfiling(${Utils.params2GqlStr(params)}) {
      data
    }}`,
    });
  }

  client_group_users(params) {
    return this.apollo.query({
      query: gql`query {
    client_group_users(${Utils.params2GqlStr(params)}) {
      data
    }}`,
      fetchPolicy: 'no-cache',
    });
  }
  groupRequestApprove(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    groupRequestApprove(${Utils.params2GqlStr(params)}) {
      data
    }}`,
    });
  }
  client_groupAdminAddsUserAsRepresentative(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    client_groupAdminAddsUserAsRepresentative(${Utils.params2GqlStr(params)}) {
      data
    }}`,
    });
  }
  client_searchUsersForAddToGroup(params) {
    return this.apollo.query({
      query: gql`query {
    client_searchUsersForAddToGroup(${Utils.params2GqlStr(params)}) {
      data
    }}`,
      fetchPolicy: 'no-cache',
    });
  }
  client_addUsersToGroup(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    client_addUsersToGroup(${Utils.params2GqlStr(params)}) {
      data
    }}`,
    });
  }
  client_createUserToGroup(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    client_createUserToGroup(${Utils.params2GqlStr(params)}) {
      data
    }}`,
    });
  }
  userSendWelcomeMail(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    userSendWelcomeMail(${Utils.params2GqlStr(params)}) {
      data
    }}`,
    });
  }
  groupRemoveUsers(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    groupRemoveUsers(${Utils.params2GqlStr(params)}) {
      data
    }}`,
    });
  }

  apiGetImportedContacts(params) {
    return this.apollo.query({
      query: gql`query {
    importedContacts(input: {${Utils.params2GqlStr(params)}}) {
      entities {
        id salutation title firstName lastName email gender street houseNumber 
        zip city country landline mobile fax web company department position 
        language keks crmCompany crmTdgCustomerId crmTdgSegment crmTdgGpNumber 
        crmTdgAccountManager crmTsiContactIdMac meta crmTsiContactIdSalsa 
        crmTsiCustomerId crmTsiAccountId eventAppQrStr updatedAt
      }
      total
    }}`,
      fetchPolicy: 'no-cache',
    });
  }

  importedContactCreate(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
        importedContactCreate(${Utils.params2GqlStr(params)}) {
           id
        }}`,
    });
  }

  importedContactUpdate(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
        importedContactUpdate(${Utils.params2GqlStr(params)}) }`,
    });
  }

  uploadTmp(ext: string = '') {
    return this.apollo.mutate({
      mutation: gql`mutation {
        upload( ext:${JSON.stringify(ext)})}`
    });
  }

  import(groupId: string, url: string = '') {
    return this.apollo.mutate({
      mutation: gql`mutation($groupId: ID!, $url: String) {
        importedContactImport(groupId: $groupId, url: $url){data}}`
      , variables: { groupId, url },
    });
  }


  importedContactImportCsv(params, fileUpload) {
    return this.apollo.mutate({
      mutation: gql`mutation($fileUpload: Upload) {
    importedContactImportCsv(${Utils.params2GqlStr(
        params,
      )}, fileUpload: $fileUpload) {
      data
    }}`,
      variables: { fileUpload },
      context: { useMultipart: true },
    });
  }
  apiImportedContactsDelete(params) {
    return this.apollo.mutate({
      mutation: gql`mutation { importedContactDelete(ids: ${JSON.stringify(
        params.ids,
      )})}`,
    });
  }

  groupSettingsRequestTdgPerm(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
    groupSettingsRequestTdgPerm(${Utils.params2GqlStr(params)}) {
      data
    }}`,
    });
  }
  groupAnalytics(params) {
    return this.apollo.query({
      query: gql`query {
    groupAnalytics(${Utils.params2GqlStr(params)}) {
      data
    }}`,
      fetchPolicy: 'no-cache',
    });
  }

  uploadImage(fileUpload) {
    return this.apollo.mutate({
      mutation: gql`
        mutation ($fileUpload: Upload) {
          client_userUploadAvatar(fileUpload: $fileUpload) {
            data
          }
        }
      `,
      variables: { fileUpload },
      context: { useMultipart: true },
    });
  }

  apiGetItems(params) {
    return this.apollo.query({
      query: gql`query {
      adItems(${Utils.params2GqlStr(params)}) {
        entities {id title type status createdAt slug updatedAt lang childrenCount groups {id title}}
        total
      }}`,
      fetchPolicy: 'no-cache',
    });
  }

  setItemAsHomepage(params) {
    return this.apollo.mutate({
      mutation: gql`
        mutation adItemSetItemAsHomepage($id: ID!) {
          adItemSetItemAsHomepage(id: $id)
        }
      `,
      variables: params,
    });
  }

  apiGetParner(params) {
    return this.apollo.query({
      query: gql`query {
        adOrgs(${Utils.params2GqlStr(params)}) {
          entities {
            id
            companyName
            region
            bpvNo
            type
            status
            updatedAt
            childrenCount
          }
          total
    }}`,
      fetchPolicy: 'no-cache',
    });
  }

  adProfilings(params) {
    return this.apollo.query({
      query: gql`query {
    adProfilings(${Utils.params2GqlStr(params)}) {
      entities {id title}
      total
    }}`,
    });
  }

  client_fileCols() {
    return this.apollo.query({
      query: gql`
        query {
          client_fileCols {
            entities {
              id
              rootFileId
              title
              allowUserUpload
            }
          }
        }
      `,
      variables: {},
      fetchPolicy: 'no-cache',
    });
  }

  apiRemoveParner(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
        groupRemoveOrgs(${Utils.params2GqlStr(params)})
    }`,
    });
  }

  apiAddParner(params) {
    return this.apollo.mutate({
      mutation: gql`mutation {
        groupAddOrgs(${Utils.params2GqlStr(params)})
    }`,
    });
  }
}
