import { MessageService } from 'app/shared/services/MessageService';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LeadService } from '../lead.service';
const _ = require('lodash')

@Component({
  selector: 'app-search-customer',
  templateUrl: 'search-customer-dialog.component.html',
  styleUrls: ['./search-customer-dialog.component.scss']
})
export class SearchCustomerDialogComponent implements OnInit {
  choice: any = {};
  list: any = [];
  loading: boolean = false
  multi: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<SearchCustomerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public leadService: LeadService,
    public messageService: MessageService,
    public snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {

  }
  onChangeSearch(e) {
    if(e.searchText.trim().length>2)
    {
      this.loading = true;
      let apiParams = {
        method: 'findContacts',
        searchText: e.searchText.trim(),
        groupId: this.data.groupId,
      }
      this.leadService.findBySearchText(apiParams).subscribe((apiRes: any) => {
        this.loading = false;
        if (apiRes.data.contactsFindBySearchText.data) {
          const data = apiRes.data.contactsFindBySearchText.data;
          if (data.contacts && data.contacts.length) {
            this.list = data.contacts;
            console.log('contacts:', this.list);
          } else {
            this.list = false
          }
        } else {
          this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002')
        }
      }, () => {
        this.loading = false;
        this.messageService.showError('FAILURE_OCCURED', 'E_GENERAL_002')
      });
    }
  }

  onChoice(row) {
    if (this.multi) {
      if (this.choice && this.choice[row.id]) {
        delete this.choice[row.id];
      } else {
        this.choice[row.id] = row;
      }
    }else{
      this.choice = row;
    }
  }

  checked() {
    if (this.multi) {
      if (_.values(this.choice).length > 0) return true;
    }else{
      if (this.choice && this.choice.id) return true;
    }
    return false;
  }

  onClickCancel() {
    this.dialogRef.close();
  }

  onBtnDialogOk() {
    if (this.multi) {
      this.dialogRef.close(_.values(this.choice));
    }else{
      this.dialogRef.close(this.choice);
    }
  }
}
